import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import {
  getBehavioursByIdRequest,
  removeBehaviourByIdRequest,
  saveBehaviourByIdRequest,
} from '@api/behaviours';
import { redirectAction } from '@store/redirect/redirect.actions';
import { IBehavioursModel, ISaveBehaviourAction } from '@type/behaviours';
import { EditBehaviourTypes } from './editBehaviour.constants';
import {
  removeBehaviourByIdSuccess,
  removeBehaviourByIdFailure,
  fetchBehaviourByIdSuccess,
  fetchBehaviourByIdFailure,
  saveBehaviourSuccessAction,
  saveBehaviourFailureAction,
} from './editBehaviour.actions';

interface IFetchBehaviourByIdAction {
  type: string;
  payload: string;
}

const { removeBehaviourErrorMessage, saveBehaviourErrorMessage } = messages.alerts.error;
const { removeBehaviourSuccessMessage, saveBehaviourSuccessMessage } = messages.alerts.success;

function* removeBehaviourByIdSaga(action: IFetchBehaviourByIdAction) {
  const { payload } = action;

  try {
    yield call(removeBehaviourByIdRequest, payload);
    yield put(removeBehaviourByIdSuccess());
  } catch (e) {
    yield put(removeBehaviourByIdFailure(e));
  }

  yield;
}

function* removeBehaviourByIdSuccessSaga() {
  yield put(redirectAction('/settings/behaviours'));
  yield message.success({
    content: removeBehaviourSuccessMessage,
    key: 'remove-behaviour-success-message',
    duration: 3,
  });
}

function* removeBehaviourByIdFailureSaga() {
  message.error({
    content: removeBehaviourErrorMessage,
    key: 'remove-behaviour-error-message',
    duration: 3,
  });
  yield;
}

function* fetchBehaviourByIdSaga(action: IFetchBehaviourByIdAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IBehavioursModel>> = yield call(
      getBehavioursByIdRequest,
      payload,
    );
    yield put(fetchBehaviourByIdSuccess(response.data.data));
  } catch (e) {
    yield put(fetchBehaviourByIdFailure(e));
  }
}

function* saveBehaviourSaga(action: ISaveBehaviourAction) {
  const { id, data } = action.payload;

  try {
    yield call(saveBehaviourByIdRequest, id, data);
    yield put(saveBehaviourSuccessAction());
  } catch (e) {
    yield put(saveBehaviourFailureAction(e));
  }

  yield;
}

function* saveBehaviourSuccessSaga() {
  yield put(redirectAction('/settings/behaviours'));
  yield message.success({
    content: saveBehaviourSuccessMessage,
    key: 'save-behaviour-success-message',
    duration: 3,
  });

  yield;
}

function* saveBehaviourFailureSaga() {
  message.error({
    content: saveBehaviourErrorMessage,
    key: 'save-behaviour-error-message',
    duration: 3,
  });
  yield;
}

export function* editBehaviourSaga() {
  yield all([
    takeLatest(EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_REQUEST, fetchBehaviourByIdSaga),
    takeLatest(EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_REQUEST, removeBehaviourByIdSaga),
    takeLatest(EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_SUCCESS, removeBehaviourByIdSuccessSaga),
    takeLatest(EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_FAILURE, removeBehaviourByIdFailureSaga),
    takeLatest(EditBehaviourTypes.SAVE_BEHAVIOUR_REQUEST, saveBehaviourSaga),
    takeLatest(EditBehaviourTypes.SAVE_BEHAVIOUR_SUCCESS, saveBehaviourSuccessSaga),
    takeLatest(EditBehaviourTypes.SAVE_BEHAVIOUR_FAILURE, saveBehaviourFailureSaga),
  ]);
}
