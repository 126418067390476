import { all, fork } from 'redux-saga/effects';
import { authSaga } from './auth/auth.sagas';
import { coachingNotesSaga } from './plans/coachingNotes/coachingNotes.sagas';
import { editCoachingNoteSaga } from './plans/coachingNotes/editCoachingNote/editCoachingNote.sagas';
import { newCoachingNoteSaga } from './plans/coachingNotes/newCoachingNote/newCoachingNote.sagas';
import { debounceSelectSaga } from './components/debounce-select/debounceSelect.sagas';
import { ouRolesSaga } from './components/ou-role-select/ouRoleSelect.sagas';
import { forgotPasswordSaga } from './forgotPassword/forgotPassword/forgotPassword.sagas';
import { resetPasswordSaga } from './forgotPassword/resetPassword/resetPassword.sagas';
import { behavioursSaga } from './settings/behaviours/behaviours.sagas';
import { editBehaviourSaga } from './settings/behaviours/editBehaviour/editBehaviour.sagas';
import { newBehaviourSaga } from './settings/behaviours/newBehaviour/newBehaviour.sagas';
import { companiesSaga } from './settings/companies/companies.sagas';
import { editCompanySaga } from './settings/companies/editCompany/editCompany.sagas';
import { newCompanySaga } from './settings/companies/newCompany/newCompany.sagas';
import { editFaqSaga } from './settings/faq/editFaq/editFaq.sagas';
import { faqSaga } from './settings/faq/faq.sagas';
import { newFaqSaga } from './settings/faq/newFaq/newFaq.sagas';
import { editOuSaga } from './settings/ous/editOu/editOu.sagas';
import { newOuSaga } from './settings/ous/newOu/newOu.sagas';
import { ousSaga } from './settings/ous/ous.sagas';
import { editUserSaga } from './settings/users/editUser/editUser.sagas';
import { saveNewUserSaga } from './settings/users/newUser/newUser.sagas';
import { usersSaga } from './settings/users/users.sagas';
import { idpsSaga } from './plans/idps/idps.sagas';
import { newIdpSaga } from './plans/idps/newIdp/newIdp.sagas';
import { editIdpSaga } from './plans/idps/editIdp/editIdp.sagas';
import { analysisSaga } from './analysis/analysis.sagas';
import { newAssessmentSaga } from './analysis/newAssessment/newAssessment.sagas';
import { analysisBehavioursSaga } from './analysis/behaviours/behaviours.sagas';
import { analysisUserDetailSaga } from './analysis/userDetail/userDetail.sagas';
import { analysisPeopleSaga } from './analysis/people/people.sagas';
import { HelpCenterSaga } from './help-center/help-center.sagas';

export function* rootSaga() {
  yield all([
    fork(usersSaga),
    fork(authSaga),
    fork(editUserSaga),
    fork(saveNewUserSaga),
    fork(ousSaga),
    fork(editOuSaga),
    fork(newOuSaga),
    fork(behavioursSaga),
    fork(newBehaviourSaga),
    fork(editBehaviourSaga),
    fork(debounceSelectSaga),
    fork(faqSaga),
    fork(newFaqSaga),
    fork(editFaqSaga),
    fork(companiesSaga),
    fork(newCompanySaga),
    fork(editCompanySaga),
    fork(ouRolesSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(coachingNotesSaga),
    fork(newCoachingNoteSaga),
    fork(editCoachingNoteSaga),
    fork(idpsSaga),
    fork(newIdpSaga),
    fork(editIdpSaga),
    fork(analysisSaga),
    fork(newAssessmentSaga),
    fork(analysisBehavioursSaga),
    fork(analysisUserDetailSaga),
    fork(analysisPeopleSaga),
    fork(HelpCenterSaga),
  ]);
}
