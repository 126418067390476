export enum EditCompanyTypes {
  FETCH_COMPANY_BY_ID_REQUEST = 'FETCH_COMPANY_BY_ID_REQUEST',
  FETCH_COMPANY_BY_ID_SUCCESS = 'FETCH_COMPANY_BY_ID_SUCCESS',
  FETCH_COMPANY_BY_ID_FAILURE = 'FETCH_COMPANY_BY_ID_FAILURE',

  REMOVE_COMPANY_BY_ID_REQUEST = 'REMOVE_COMPANY_BY_ID_REQUEST',
  REMOVE_COMPANY_BY_ID_SUCCESS = 'REMOVE_COMPANY_BY_ID_SUCCESS',
  REMOVE_COMPANY_BY_ID_FAILURE = 'REMOVE_COMPANY_BY_ID_FAILURE',

  SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST',
  SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS',
  SAVE_COMPANY_FAILURE = 'SAVE_COMPANY_FAILURE',
}
