import { Dispatch, SetStateAction } from 'react';
import { getOptions } from '@utils/global/helpers';
import { IBehavioursModel } from '@type/behaviours';
import { ITimeFrameModel } from '@type/timeframes';
import { IOusModel } from '@type/ous';
import { ISubordinateModel, IUserModel } from '@type/users';
import { ICompanyModel } from '@type/companies';
import { getBehavioursRequest } from './behaviours';
import { getOusRequest } from './ous';
import { getOuUsersRequest, getSearchedUsersRequest, getSubordinatesRequest } from './users';
import { getTimeFramesRequest } from './timeframes';
import { getCompaniesRequest } from './companies';

type GetUsersOptionsType = Pick<IUserModel, 'firstName' | 'lastName'>;

const getUsersOptions = {
  getName: ({ firstName, lastName }: GetUsersOptionsType) => `${firstName} ${lastName}`,
  getValue: (model: IUserModel) => model.id.toString(),
};

export const getOus = async () => {
  const response = await getOusRequest();

  return getOptions<IOusModel>(response.data.data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};

export const onUsersSearch = async (query: string) => {
  const response = await getSearchedUsersRequest(query);

  return getOptions<IUserModel>(response.data.data, {
    getName: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    getValue: (model) => model.id.toString(),
  });
};

export const getBehaviours = async () => {
  const response = await getBehavioursRequest();

  return getOptions<IBehavioursModel>(response.data.data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};
export const getBehavioursForSpecificOU = async (ouId: number) => {
  const response = await getBehavioursRequest();

  const data = response.data.data.filter((behaviour) => {
    return behaviour.ous
      .map((ou) => {
        return ou.id;
      })
      .includes(ouId);
  });

  return getOptions<IBehavioursModel>(data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};

export const onSubordinatesSearch = async (
  query: string,
  setSubordinates?: Dispatch<SetStateAction<ISubordinateModel[]>>,
) => {
  const response = await getSubordinatesRequest(query);
  const subordinates = response.data.data;

  if (setSubordinates) {
    setSubordinates(subordinates);
  }

  return getOptions<ISubordinateModel>(subordinates, {
    getName: ({ fullName, ouName }) => `${fullName} - ${ouName}`,
    getValue: ({ userId, ouId }) => [userId, ouId].toString(),
  });
};

export const getTimeFrames = async () => {
  const response = await getTimeFramesRequest();

  return getOptions<ITimeFrameModel>(response.data.data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};

export const getOuUsers = async (query: string) => {
  if (!query) {
    return getOptions([], getUsersOptions);
  }

  const response = await getOuUsersRequest(query);

  return getOptions<IUserModel>(response.data.data, getUsersOptions);
};

export const getCompaniesOptions = async () => {
  const response = await getCompaniesRequest();

  return getOptions<ICompanyModel>(response.data.data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};
