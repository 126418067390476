import { IOuRoleSelectState, OuRoleSelectActionType } from '@type/ouRoleSelect';
import { OuRoleSelectEnum } from './ouRoleSelect.constants';

const initialState: IOuRoleSelectState = {
  selectedOptionsList: null,
};

const OuRoleReducer = (
  state = initialState,
  action: OuRoleSelectActionType,
): IOuRoleSelectState => {
  switch (action.type) {
    case OuRoleSelectEnum.OU_ROLE_SELECT_PUSH_ITEM:
      return {
        ...state,
        selectedOptionsList: {
          ...state.selectedOptionsList,
          [action.payload.ou!.value]: {
            ou: action.payload.ou,
            role: action.payload.role,
          },
        },
      };
    case OuRoleSelectEnum.OU_ROLE_SELECT_POP_ITEM:
      return {
        ...state,
        selectedOptionsList: action.payload,
      };
    case OuRoleSelectEnum.CLEAR_SELECTED_OPTION_LIST:
      return {
        ...state,
        selectedOptionsList: null,
      };
    default:
      return { ...state };
  }
};

export default OuRoleReducer;
