import { select, put, all, takeLatest, call } from 'redux-saga/effects';
import { StateType } from '@type/general';
import {
  OuRoleSelectedOptionsType,
  IOuRoleSelectPopItemRequestAction,
  ITypedObjectLiteral,
  OuRoleSelectOptionType,
} from '@type/ouRoleSelect';
import { ouRoleSelectPopItemAction } from './ouRoleSelect.actions';
import { OuRoleSelectEnum } from './ouRoleSelect.constants';

const getSelectedOusRolesState = (state: StateType): OuRoleSelectedOptionsType =>
  state?.components.ouRoleSelect.selectedOptionsList;

function* popItemSaga(action: IOuRoleSelectPopItemRequestAction) {
  const combinedSelectState: ITypedObjectLiteral<OuRoleSelectOptionType> = yield select(
    getSelectedOusRolesState,
  );
  const { payload: key } = action;
  const { [key]: _, ...rest } = combinedSelectState;

  yield put(ouRoleSelectPopItemAction(rest));

  yield;
}

function* popItemRequestSaga(action: IOuRoleSelectPopItemRequestAction) {
  const { type, payload } = action;

  yield call(popItemSaga, {
    type,
    payload,
  });

  yield;
}

export function* ouRolesSaga() {
  yield all([takeLatest(OuRoleSelectEnum.OU_ROLE_SELECT_POP_ITEM_REQUEST, popItemRequestSaga)]);
}
