import { AnalysisActionType, IAnalysisState } from '@type/analysis';
import { AnalysisTypes } from './analysis.constants';

const initialState: IAnalysisState = {
  currentQuarter: null,
  behavioursList: [],
};

const AnalysisReducer = (state = initialState, action: AnalysisActionType): IAnalysisState => {
  switch (action.type) {
    case AnalysisTypes.GET_CURRENT_QUARTER_SUCCESS:
      return { ...state, currentQuarter: action.payload };
    case AnalysisTypes.GET_CURRENT_QUARTER_FAILED:
      return { ...state, currentQuarter: null };
    case AnalysisTypes.GET_BEHAVIOURS_LIST_SUCCESS:
      return { ...state, behavioursList: action.payload };
    default:
      return { ...state };
  }
};

export default AnalysisReducer;
