import { message } from 'antd';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { saveNewIdpRequest } from '@api/idps';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import { ISaveNewIdpFailureAction, ISaveNewIdpRequestAction } from '@type/idps';
import { saveNewIdpFailureAction, saveNewIdpSuccessAction } from '@store/actions';
import { NewIdpTypes } from './newIdp.constants';

const { saveSuccessMessage } = messages.alerts.success;
const { saveErrorMessage } = messages.alerts.error;

function* saveNewIdpSaga(action: ISaveNewIdpRequestAction) {
  const { payload } = action;
  try {
    yield call(saveNewIdpRequest, payload);
    yield put(saveNewIdpSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(saveNewIdpFailureAction(e));
  }

  yield;
}

function* saveNewIdpSuccessSaga() {
  yield put(redirectAction('/plans/individual-development-plans'));
  yield message.success({
    content: saveSuccessMessage,
    key: 'save-new-idp-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewOuFailureSaga(action: ISaveNewIdpFailureAction) {
  message.error({
    content: `${saveErrorMessage}: ${action.payload.message}`,
    key: 'save-new-idp-success-message',
    duration: 3,
  });

  yield;
}

export function* newIdpSaga() {
  yield all([
    takeLatest(NewIdpTypes.SAVE_NEW_IDP_REQUEST, saveNewIdpSaga),
    takeLatest(NewIdpTypes.SAVE_NEW_IDP_SUCCESS, saveNewIdpSuccessSaga),
    takeLatest(NewIdpTypes.SAVE_NEW_IDP_FAILURE, saveNewOuFailureSaga),
  ]);
}
