export enum AnalysisBehavioursTypes {
  GET_ANALYSIS_BEHAVIOURS_USERS_REQUEST = 'GET_ANALYSIS_BEHAVIOURS_USERS_REQUEST',
  GET_ANALYSIS_BEHAVIOURS_USERS_SUCCESS = 'GET_ANALYSIS_BEHAVIOURS_USERS_SUCCESS',
  GET_ANALYSIS_BEHAVIOURS_USERS_ERROR = 'GET_ANALYSIS_BEHAVIOURS_USERS_ERROR',

  GET_LINE_CHART_DATA_REQUEST = 'GET_LINE_CHART_DATA_REQUEST',
  GET_LINE_CHART_DATA_SUCCESS = 'GET_LINE_CHART_DATA_SUCCESS',
  GET_LINE_CHART_DATA_ERROR = 'GET_LINE_CHART_DATA_ERROR',

  SET_BEHAVIOURS_LIST = 'SET_BEHAVIOURS_LIST',
  SET_BEHAVIOURS_SELECTED_OPTION = 'SET_BEHAVIOURS_SELECTED_OPTION',
  SET_ASPECT_SELECTED_OPTION = 'SET_ASPECT_SELECTED_OPTION',
  SET_FILTERED_USERS = 'SET_FILTERED_USERS',
  SET_FILTERED_LINE_CHART_DATA_REQUEST = 'SET_FILTERED_LINE_CHART_DATA_REQUEST',
  SET_FILTERED_LINE_CHART_DATA_SUCCESS = 'SET_FILTERED_LINE_CHART_DATA_SUCCESS',
}
