import { AuthActionType, IAuthState } from 'types/auth';
import { AuthTypes } from './auth.constants';

const initialState: IAuthState = {
  token: localStorage.getItem('token') || '',
  firstName: localStorage.getItem('firstName') || '',
  lastName: localStorage.getItem('lastName') || '',
  jobDescription: localStorage.getItem('jobDescription') || null,
  companyName: localStorage.getItem('companyName') || null,
  id: parseInt(localStorage.getItem('id') || '', 10) || null,
  login: {
    form: {
      email: 'amosciski@example.com',
      password: 'password',
    },
    loading: {
      pending: false,
      success: false,
      error: false,
    },
  },
};

const AuthReducer = (state = initialState, action: AuthActionType): IAuthState => {
  switch (action.type) {
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        jobDescription: action.payload.jobDescription,
        companyName: action.payload.companyName,
        id: action.payload.id,
      };
    case AuthTypes.LOGOUT:
      return {
        ...state,
        token: '',
      };
    default:
      return { ...state };
  }
};

export default AuthReducer;
