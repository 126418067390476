import {
  IRemoveFaqByIdRequestAction,
  IRemoveFaqByIdSuccessAction,
  IRemoveFaqByIdFailureAction,
  IFetchFaqByIdRequestAction,
  FaqModelType,
  IFetchFaqByIdSuccessAction,
  IFetchFaqByIdFailureAction,
  ISaveFaqModel,
  ISaveFaqAction,
  ISaveFaqSuccessAction,
  ISaveFaqFailureAction,
} from '@type/faq';
import { EditFaqTypes } from './editFaq.constants';

export const removeFaqByIdRequest = (id: string): IRemoveFaqByIdRequestAction => {
  return {
    type: EditFaqTypes.REMOVE_FAQ_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeFaqByIdSuccess = (): IRemoveFaqByIdSuccessAction => {
  return {
    type: EditFaqTypes.REMOVE_FAQ_BY_ID_SUCCESS,
  };
};

export const removeFaqByIdFailure = (error: Error): IRemoveFaqByIdFailureAction => {
  return {
    error,
    type: EditFaqTypes.REMOVE_FAQ_BY_ID_FAILURE,
  };
};

export const fetchFaqByIdRequest = (id: string): IFetchFaqByIdRequestAction => {
  return {
    type: EditFaqTypes.FETCH_FAQ_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchFaqByIdSuccess = (payload: FaqModelType): IFetchFaqByIdSuccessAction => {
  return {
    type: EditFaqTypes.FETCH_FAQ_BY_ID_SUCCESS,
    faq: payload,
  };
};

export const fetchFaqByIdFailure = (error: Error): IFetchFaqByIdFailureAction => {
  return {
    error,
    type: EditFaqTypes.FETCH_FAQ_BY_ID_FAILURE,
  };
};

export const saveFaqAction = (id: string, data: ISaveFaqModel): ISaveFaqAction => {
  return {
    type: EditFaqTypes.SAVE_FAQ_REQUEST,
    payload: {
      id,
      data,
    },
  };
};

export const saveFaqSuccessAction = (): ISaveFaqSuccessAction => {
  return {
    type: EditFaqTypes.SAVE_FAQ_SUCCESS,
  };
};

export const saveFaqFailureAction = (payload: Error): ISaveFaqFailureAction => {
  return {
    payload,
    type: EditFaqTypes.SAVE_FAQ_FAILURE,
  };
};
