import apiClient from '@utils/global/apiClient';
import {
  NewAssessmentGetCurrentActionPayloadType,
  INewAssessmentGetCurrentResponse,
  INewAssessmentPayload,
  INewAssessmentSetCurrentPayload,
} from '@type/newAssessment';

export const newAssessmentSaveChangesRequest = (data: INewAssessmentPayload) => {
  const { ouId, ...rest } = data;

  return apiClient.post(`/api/ou_management/${ouId}/analyses`, rest);
};

export const newAssessmentSubmitRequest = (data: INewAssessmentPayload) => {
  const { ouId, ...rest } = data;

  return apiClient.post(`/api/ou_management/${ouId}/analyses/submit`, rest);
};

export const newAssessmentGetCurrent = ({
  userId,
  ouId,
}: NewAssessmentGetCurrentActionPayloadType) => {
  return apiClient.get<INewAssessmentGetCurrentResponse>(
    `/api/ou_management/${ouId}/analyses/current_analyses?userId=${userId}`,
  );
};

export const newAssessmentSetCurrent = ({ ouId, ...rest }: INewAssessmentSetCurrentPayload) => {
  return apiClient.put<INewAssessmentGetCurrentResponse>(
    `/api/ou_management/${ouId}/analyses`,
    rest,
  );
};

export const newAssessmentSetCurrentFirst = ({
  ouId,
  ...rest
}: INewAssessmentSetCurrentPayload) => {
  return apiClient.post<INewAssessmentGetCurrentResponse>(
    `/api/ou_management/${ouId}/analyses`,
    rest,
  );
};

export const newAssessmentSubmitCurrent = ({ ouId, ...rest }: INewAssessmentSetCurrentPayload) => {
  return apiClient.post<INewAssessmentGetCurrentResponse>(
    `/api/ou_management/${ouId}/analyses/submit`,
    rest,
  );
};
