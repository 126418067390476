import { FC } from 'react';
import { Layout as AntdLayout } from 'antd';
import { useSelector } from 'react-redux';
import { IWrapperProps } from '@utils';
import { StateType } from '../types/general';
import Header from './Header';
import Sidebar from './Sidebar';
import './Layout.scss';

const { Content } = AntdLayout;

const Layout: FC<IWrapperProps> = ({ children }) => {
  const token = useSelector<StateType, string>((state) => {
    return state.auth.token;
  });

  return (
    <AntdLayout className="main-layout">
      {token && <Sidebar />}
      <AntdLayout className="content-layout-outer">
        <Header />
        <Content className="content-layout-inner">{children}</Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
