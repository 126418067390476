import {
  AnalysisPeopleOuScoresListType,
  GetAnalysisPeopleOuAverageScoresRequestPayloadType,
  IGetAnalysisPeopleOuAverageScoresErrorAction,
  IGetAnalysisPeopleOuAverageScoresRequestAction,
  IGetAnalysisPeopleOuAverageScoresSuccessAction,
} from '@type/analysisPeople';
import { AnalysisPeopleTypes } from './people.constants';

export const getAnalysisPeopleOuAverageScoresRequestAction = (
  payload: GetAnalysisPeopleOuAverageScoresRequestPayloadType,
): IGetAnalysisPeopleOuAverageScoresRequestAction => {
  return {
    payload,
    type: AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_REQUEST,
  };
};

export const getAnalysisPeopleOuAverageScoresSuccessAction = (
  payload: AnalysisPeopleOuScoresListType,
): IGetAnalysisPeopleOuAverageScoresSuccessAction => {
  return {
    payload,
    type: AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_SUCCESS,
  };
};

export const getAnalysisPeopleOuAverageScoresErrorAction =
  (): IGetAnalysisPeopleOuAverageScoresErrorAction => {
    return {
      type: AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_ERROR,
    };
  };
