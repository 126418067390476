import { AuthSelectorType } from '@components/Forms/UserForm/UserForm';
import {
  IFetchUserByIdFailureAction,
  IFetchUserByIdRequestAction,
  IFetchUserByIdSuccessAction,
  IEditUserModel,
  IUserModel,
  IRemoveUserByIdFailureAction,
  IRemoveUserByIdRequestAction,
  IRemoveUserByIdSuccessAction,
  ISaveUserFailureAction,
  ISaveUserSuccessAction,
  ISaveUserAction,
} from '@type/users';
import { EditUserTypes } from './editUser.constants';

export const removeUserByIdAction = (id: string): IRemoveUserByIdRequestAction => {
  return {
    type: EditUserTypes.REMOVE_USER_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeUserByIdSuccessAction = (): IRemoveUserByIdSuccessAction => {
  return {
    type: EditUserTypes.REMOVE_USER_BY_ID_SUCCESS,
  };
};

export const removeUserByIdFailureAction = (error: Error): IRemoveUserByIdFailureAction => {
  return {
    type: EditUserTypes.REMOVE_USER_BY_ID_FAILURE,
    error,
  };
};

export const fetchUserByIdRequestAction = (id: string): IFetchUserByIdRequestAction => {
  return {
    type: EditUserTypes.FETCH_USER_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchUserByIdSuccessAction = (payload: IUserModel): IFetchUserByIdSuccessAction => {
  return {
    type: EditUserTypes.FETCH_USER_BY_ID_SUCCESS,
    user: payload,
  };
};

export const fetchUserByIdFailure = (error: Error): IFetchUserByIdFailureAction => {
  return {
    type: EditUserTypes.FETCH_USER_BY_ID_FAILURE,
    error,
  };
};

export const saveUserAction = (
  id: string,
  data: IEditUserModel,
  currentUserEdit: boolean,
  userData: AuthSelectorType,
): ISaveUserAction => {
  return {
    type: EditUserTypes.SAVE_USER_REQUEST,
    payload: {
      id,
      data,
      currentUserEdit,
      userData,
    },
  };
};

export const saveUserSuccessAction = (): ISaveUserSuccessAction => {
  return {
    type: EditUserTypes.SAVE_USER_SUCCESS,
  };
};

export const saveUserFailureAction = (error: Error): ISaveUserFailureAction => {
  return {
    type: EditUserTypes.SAVE_USER_FAILURE,
    error,
  };
};
