import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { redirectAction } from '@store/redirect/redirect.actions';
import { ISaveNewOuAction } from '@type/ous';
import { messages } from '@utils';
import { saveNewOuRequest } from '@api/ous';
import { NewOuTypes } from './newOu.constants';
import { saveNewOuFailureAction, saveNewOuSuccessAction } from './newOu.actions';

const { saveOuSuccessMessage } = messages.alerts.success;
const { saveOuErrorMessage } = messages.alerts.error;

function* saveNewOuSaga(action: ISaveNewOuAction) {
  const { payload } = action;

  try {
    yield call(saveNewOuRequest, payload);
    yield put(saveNewOuSuccessAction());
  } catch (e) {
    yield put(saveNewOuFailureAction(e));
  }

  yield;
}

function* saveNewOuSuccessSaga() {
  yield put(redirectAction('/settings/ous'));
  yield message.success({
    content: saveOuSuccessMessage,
    key: 'save-new-ou-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewOuFailureSaga() {
  message.error({
    content: saveOuErrorMessage,
    key: 'save-new-ou-error-message',
    duration: 3,
  });

  yield;
}

export function* newOuSaga() {
  yield all([
    takeLatest(NewOuTypes.SAVE_NEW_OU_REQUEST, saveNewOuSaga),
    takeLatest(NewOuTypes.SAVE_NEW_OU_SUCCESS, saveNewOuSuccessSaga),
    takeLatest(NewOuTypes.SAVE_NEW_OU_FAILURE, saveNewOuFailureSaga),
  ]);
}
