import {
  IChangeEmailInputAction,
  IChangePasswordInputAction,
  ILoginErrorAction,
  ILoginRequestAction,
  ILoginRequestPayload,
  ILoginSuccessAction,
  ILoginSuccessPayloadType,
  ILogoutRequestAction,
} from 'types/auth';
import { AuthTypes } from './auth.constants';

export const logoutRequestAction = (): ILogoutRequestAction => {
  return {
    type: AuthTypes.LOGOUT,
  };
};

export const loginRequestAction = (payload: ILoginRequestPayload): ILoginRequestAction => {
  return {
    type: AuthTypes.LOGIN_REQUEST,
    payload,
  };
};

export const loginSuccessAction = (payload: ILoginSuccessPayloadType): ILoginSuccessAction => {
  return {
    type: AuthTypes.LOGIN_SUCCESS,
    payload,
  };
};

export const loginErrorAction = (): ILoginErrorAction => {
  return {
    type: AuthTypes.LOGIN_ERROR,
  };
};

export const emailInputChangeAction = (payload: string): IChangeEmailInputAction => {
  return {
    type: AuthTypes.CHANGE_EMAIL_INPUT,
    payload,
  };
};

export const passwordInputChangeAction = (payload: string): IChangePasswordInputAction => {
  return {
    type: AuthTypes.CHANGE_PASSWORD_INPUT,
    payload,
  };
};
