import { AnalysisUserDetailActionType, IAnalysisUserDetailState } from '@type/analysisUserDetail';
import { UserDetailsActionTypes } from './userDetail.constants';

const initialState: IAnalysisUserDetailState = {
  latestIdp: null,
  scatterPlotData: [],
  miniGraphsData: [],
};

const UserDetailReducer = (
  state = initialState,
  action: AnalysisUserDetailActionType,
): IAnalysisUserDetailState => {
  switch (action.type) {
    case UserDetailsActionTypes.GET_LATEST_IDP_REQUEST ||
      UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_REQUEST ||
      UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_REQUEST:
      return { ...state };
    case UserDetailsActionTypes.GET_LATEST_IDP_SUCCESS:
      return { ...state, latestIdp: action.payload };
    case UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_SUCCESS:
      return { ...state, scatterPlotData: action.payload };
    case UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_SUCCESS:
      return { ...state, miniGraphsData: action.payload };
    default:
      return { ...state };
  }
};

export default UserDetailReducer;
