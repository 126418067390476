import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { getFaqByIdRequest, removeFaqByIdRequest, saveFaqByIdRequest } from '@api/faq';
import { redirectAction } from '@store/actions';
import { IFaqModel, ISaveFaqAction } from '@type/faq';
import {
  removeFaqByIdSuccess,
  removeFaqByIdFailure,
  fetchFaqByIdSuccess,
  fetchFaqByIdFailure,
  saveFaqSuccessAction,
  saveFaqFailureAction,
} from './editFaq.actions';
import { EditFaqTypes } from './editFaq.constants';

interface IFetchFaqByIdAction {
  type: string;
  payload: string;
}

const { removeFaqErrorMessage, saveFaqErrorMessage } = messages.alerts.error;
const { removeFaqSuccessMessage, saveFaqSuccessMessage } = messages.alerts.success;

function* removeFaqByIdSaga(action: IFetchFaqByIdAction) {
  const { payload } = action;

  try {
    yield call(removeFaqByIdRequest, payload);
    yield put(removeFaqByIdSuccess());
  } catch (e) {
    yield put(removeFaqByIdFailure(e));
  }

  yield;
}

function* removeFaqByIdSuccessSaga() {
  yield put(redirectAction('/settings/help-center'));
  yield message.success({
    content: removeFaqSuccessMessage,
    key: 'remove-faq-success-message',
    duration: 3,
  });
}

function* removeFaqByIdFailureSaga() {
  message.error({
    content: removeFaqErrorMessage,
    key: 'remove-faq-error-message',
    duration: 3,
  });
  yield;
}

function* fetchFaqByIdSaga(action: IFetchFaqByIdAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IFaqModel>> = yield call(getFaqByIdRequest, payload);
    yield put(fetchFaqByIdSuccess(response.data.data));
  } catch (e) {
    yield put(fetchFaqByIdFailure(e));
  }
}

function* saveFaqSaga(action: ISaveFaqAction) {
  const { id, data } = action.payload;

  try {
    yield call(saveFaqByIdRequest, id, data);
    yield put(saveFaqSuccessAction());
  } catch (e) {
    yield put(saveFaqFailureAction(e));
  }

  yield;
}

function* saveFaqSuccessSaga() {
  yield put(redirectAction('/settings/help-center'));
  yield message.success({
    content: saveFaqSuccessMessage,
    key: 'save-faq-success-message',
    duration: 3,
  });

  yield;
}

function* saveFaqFailureSaga() {
  message.error({
    content: saveFaqErrorMessage,
    key: 'save-faq-error-message',
    duration: 3,
  });
  yield;
}

export function* editFaqSaga() {
  yield all([
    takeLatest(EditFaqTypes.FETCH_FAQ_BY_ID_REQUEST, fetchFaqByIdSaga),
    takeLatest(EditFaqTypes.REMOVE_FAQ_BY_ID_REQUEST, removeFaqByIdSaga),
    takeLatest(EditFaqTypes.REMOVE_FAQ_BY_ID_SUCCESS, removeFaqByIdSuccessSaga),
    takeLatest(EditFaqTypes.REMOVE_FAQ_BY_ID_FAILURE, removeFaqByIdFailureSaga),
    takeLatest(EditFaqTypes.SAVE_FAQ_REQUEST, saveFaqSaga),
    takeLatest(EditFaqTypes.SAVE_FAQ_SUCCESS, saveFaqSuccessSaga),
    takeLatest(EditFaqTypes.SAVE_FAQ_FAILURE, saveFaqFailureSaga),
  ]);
}
