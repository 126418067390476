import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { IUserModel, ISaveUserAction } from '@type/users';
import { getUserByIdRequest, removeUserByIdRequest, saveUserByIdRequest } from '@api/users';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import { getOus } from '@api/general';
import { clearDebounceCustomSelectAction } from '@store/components/debounce-select/debounceSelect.actions';
import { loginSuccessAction } from '@store/actions';
import {
  fetchUserByIdFailure,
  fetchUserByIdSuccessAction,
  removeUserByIdFailureAction,
  removeUserByIdSuccessAction,
  saveUserFailureAction,
  saveUserSuccessAction,
} from './editUser.actions';
import { EditUserTypes } from './editUser.constants';

interface IFetchUserByIdAction {
  type: string;
  payload: string;
}

const { name } = messages.settings.users.editUserPage.formInputs.ousInput;
const { removeUserErrorMessage, saveUserErrorMessage } = messages.alerts.error;
const { removeUserSuccessMessage, saveUserSuccessMessage } = messages.alerts.success;

function* removeUserByIdSaga(action: IFetchUserByIdAction) {
  const { payload } = action;

  try {
    yield call(removeUserByIdRequest, payload);
    yield put(removeUserByIdSuccessAction());
  } catch (e) {
    yield put(removeUserByIdFailureAction(e));
  }

  yield;
}

function* removeUserByIdSuccessSaga() {
  yield put(redirectAction('/settings/users'));
  yield message.success({
    content: removeUserSuccessMessage,
    key: 'remove-user-success-message',
    duration: 3,
  });
}

function* removeUserByIdFailureSaga() {
  message.error({
    content: removeUserErrorMessage,
    key: 'remove-user-error-message',
    duration: 3,
  });
  yield;
}

function* fetchUsersByIdSaga(action: IFetchUserByIdAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IUserModel>> = yield call(getUserByIdRequest, payload);
    yield put(fetchUserByIdSuccessAction(response.data.data));
  } catch (e) {
    yield put(fetchUserByIdFailure(e));
  }
}

function* saveUserSaga(action: ISaveUserAction) {
  const { id, data, currentUserEdit, userData } = action.payload;

  try {
    const response: AxiosResponse<IResponse<IUserModel>> = yield call(
      saveUserByIdRequest,
      id,
      data,
    );

    yield put(saveUserSuccessAction());

    if (currentUserEdit) {
      const { data: responseData } = response.data;

      const newData = {
        ...userData,
        firstName: responseData.firstName,
        lastName: responseData.lastName,
        email: responseData.email,
        jobDescription: responseData.jobDescription,
        id: responseData.id,
      };

      yield put(loginSuccessAction(newData));
    }

    yield put(
      clearDebounceCustomSelectAction({ name, fetchFunction: getOus, combinedSelect: true }),
    );
  } catch (e) {
    yield put(saveUserFailureAction(e));
  }

  yield;
}

function* saveUserSuccessSaga() {
  yield put(redirectAction('/settings/users'));
  yield message.success({
    content: saveUserSuccessMessage,
    key: 'save-user-success-message',
    duration: 3,
  });

  yield;
}

function* saveUserFailureSaga() {
  message.error({
    content: saveUserErrorMessage,
    key: 'save-user-error-message',
    duration: 3,
  });
  yield;
}

export function* editUserSaga() {
  yield all([
    takeLatest(EditUserTypes.FETCH_USER_BY_ID_REQUEST, fetchUsersByIdSaga),
    takeLatest(EditUserTypes.REMOVE_USER_BY_ID_REQUEST, removeUserByIdSaga),
    takeLatest(EditUserTypes.REMOVE_USER_BY_ID_SUCCESS, removeUserByIdSuccessSaga),
    takeLatest(EditUserTypes.REMOVE_USER_BY_ID_FAILURE, removeUserByIdFailureSaga),
    takeLatest(EditUserTypes.SAVE_USER_REQUEST, saveUserSaga),
    takeLatest(EditUserTypes.SAVE_USER_SUCCESS, saveUserSuccessSaga),
    takeLatest(EditUserTypes.SAVE_USER_FAILURE, saveUserFailureSaga),
  ]);
}
