import React from 'react';
import { SpinProps, Table as AntdTable } from 'antd';
import {
  FilterValue,
  TablePaginationConfig,
  SorterResult,
  ColumnsType,
  TableRowSelection,
} from 'antd/lib/table/interface';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import './Table.scss';

interface ITableProps<T extends object> extends Pick<AntdTableProps<T>, 'dataSource' | 'onRow'> {
  columns: ColumnsType<T>;
  data: AntdTableProps<T>['dataSource'];
  loading?: boolean | SpinProps;
  rowSelection?: TableRowSelection<T>;
  pagination?: false | TablePaginationConfig;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
}

const Table = <T extends object>({
  columns,
  data,
  onRow,
  rowSelection,
  onChange,
  loading,
  pagination,
}: ITableProps<T>) => {
  return (
    <AntdTable
      onRow={onRow}
      columns={columns}
      dataSource={data}
      onChange={onChange}
      pagination={pagination}
      rowSelection={rowSelection}
      loading={loading}
    />
  );
};

export default Table;
