import {
  ISaveFaqModel,
  ISaveNewFaqSuccessAction,
  ISaveNewFaqFailureAction,
  ISaveNewFaqRequestAction,
} from '@type/faq';
import { NewFaqTypes } from './newFaq.constants';

export const saveNewFaqAction = (payload: ISaveFaqModel): ISaveNewFaqRequestAction => {
  return {
    payload,
    type: NewFaqTypes.SAVE_NEW_FAQ_REQUEST,
  };
};

export const saveNewFaqSuccessAction = (): ISaveNewFaqSuccessAction => {
  return {
    type: NewFaqTypes.SAVE_NEW_FAQ_SUCCESS,
  };
};

export const saveNewFaqFailureAction = (error: Error): ISaveNewFaqFailureAction => {
  return {
    error,
    type: NewFaqTypes.SAVE_NEW_FAQ_FAILURE,
  };
};
