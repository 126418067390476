import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getTimeFramesRequest } from '@api/timeframes';
import { getSubOusRequest } from '@api/analysis';
import { getBehavioursListSuccessAction } from '@store/actions';
import { TimeFrameListType, TimeFrameModelType } from '@type/timeframes';
import { IResponse } from '@utils/interfaces/response';
import { IGetBehavioursListAction } from '@type/analysis';
import { IOusModel } from '@type/ous';
import { getCurrentQuarterFailedAction, getCurrentQuarterSuccessAction } from './analysis.actions';
import { AnalysisTypes } from './analysis.constants';

function* getCurrentQuarterSaga() {
  try {
    const response: AxiosResponse<IResponse<TimeFrameListType>> = yield call(getTimeFramesRequest);
    const firstTimeFrameItem: TimeFrameModelType = response.data.data.shift();

    if (firstTimeFrameItem) {
      yield put(getCurrentQuarterSuccessAction(firstTimeFrameItem));
    } else {
      throw Error('array.pop returned undefined');
    }
  } catch (e) {
    yield put(getCurrentQuarterFailedAction());
  }
}

function* getBehavioursListSaga(action: IGetBehavioursListAction) {
  const { payload: ouId } = action;

  try {
    const response: AxiosResponse<IResponse<IOusModel[]>> = yield call(getSubOusRequest, '');
    const ousArr = response.data.data;

    const { behaviours = [] } =
      ousArr.find((ou) => {
        return ou.id === ouId;
      }) || {};

    yield put(getBehavioursListSuccessAction(behaviours));
  } catch (e) {
    yield put(getCurrentQuarterFailedAction());
  }
}

export function* analysisSaga() {
  yield all([takeLatest(AnalysisTypes.GET_CURRENT_QUARTER_REQUEST, getCurrentQuarterSaga)]);
  yield all([takeLatest(AnalysisTypes.GET_BEHAVIOURS_LIST_REQUEST, getBehavioursListSaga)]);
}
