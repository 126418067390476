import { IEditCoachingNoteState, IEditCoachingNoteAction } from '@type/coachingNotes';
import { EditCoachingNoteTypes } from './editCoachingNote.constants';

const initialState: IEditCoachingNoteState = {
  pending: false,
  coachingNote: null,
  error: null,
};

const EditCoachingNoteReducer = (
  state = initialState,
  action: IEditCoachingNoteAction,
): IEditCoachingNoteState => {
  switch (action.type) {
    case EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, coachingNote: action.coachingNote };
    case EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, coachingNote: null };

    case EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, coachingNote: null };
    case EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditCoachingNoteTypes.UPDATE_COACHING_NOTE_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCoachingNoteTypes.UPDATE_COACHING_NOTE_SUCCESS:
      return { ...state, pending: false, error: null, coachingNote: null };
    case EditCoachingNoteTypes.UPDATE_COACHING_NOTE_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditCoachingNoteReducer;
