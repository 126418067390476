import { INewFaqAction, INewFaqState } from '@type/faq';
import { NewFaqTypes } from './newFaq.constants';

const initialState: INewFaqState = {
  pending: false,
  error: null,
};

const NewFaqReducer = (state = initialState, action: INewFaqAction): INewFaqState => {
  switch (action.type) {
    case NewFaqTypes.SAVE_NEW_FAQ_REQUEST:
      return { ...state, pending: true, error: null };
    case NewFaqTypes.SAVE_NEW_FAQ_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewFaqTypes.SAVE_NEW_FAQ_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error };
    default:
      return { ...state };
  }
};

export default NewFaqReducer;
