import React, { FC, useEffect, useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { IObjectLiteral, IOption } from '@utils';
import { Button, Col, Input, Row, Select } from 'antd';
import './CustomSelect.scss';

interface ICustomSelectProps {
  required?: boolean;
  label: string;
  placeholder?: string;
  options: Array<IOption>;
  labelCol?: number;
  wrapperCol?: number;
  loading?: boolean;
  notFoundContent?: React.ReactNode;
  onSearch?: (value: string) => void;
}

const { Option } = Select;

const CustomSelect: FC<ICustomSelectProps> = ({
  placeholder,
  label,
  required,
  labelCol,
  wrapperCol,
  loading,
  notFoundContent,
  options: options2,
  onSearch = () => {},
}) => {
  const [options, setOptions] = useState<IObjectLiteral<{ value: string; label: string }>>({});
  const [selected, setSelected] = useState<IOption>();
  const [OUList, setOUList] = useState<IObjectLiteral<IOption>>({});

  useEffect(() => {
    const hashTable = options2.reduce((acc, option) => {
      return {
        ...acc,
        [option.value]: option,
      };
    }, {});

    setOptions({ ...hashTable });
  }, []);

  const handleSelect = (value: string): void => {
    setSelected(options[value]);
  };

  const handleAddButtonClick = (): void => {
    if (selected) {
      setOUList((prev) => {
        return {
          ...prev,
          [selected.value]: selected,
        };
      });
      setSelected(undefined);
      setOptions((prev) => {
        delete prev[selected.value];

        return { ...prev };
      });
    }
  };

  const handleDeleteButtonClick = (value: string): void => {
    const deleted = OUList[value];
    setOUList((prev) => {
      delete prev[value];

      return { ...prev };
    });

    setOptions((prev) => {
      return { ...prev, [value]: deleted };
    });
  };

  return (
    <Row className="custom-select">
      <Col span={labelCol || 7} className="ant-form-item-label">
        <label className={required ? 'ant-form-item-required' : ''}>{label}</label>
      </Col>

      <Col span={wrapperCol || 17}>
        {Object.entries(OUList).map(([value, option]) => {
          return (
            <div key={value} className="delete-input-container">
              <Input value={option.label} />
              <Button
                type="primary"
                danger
                onClick={() => {
                  return handleDeleteButtonClick(value);
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          );
        })}
        <div className="input-container">
          <Select
            showSearch
            loading={loading}
            placeholder={placeholder}
            onChange={handleSelect}
            onSearch={onSearch}
            value={selected?.value}
            notFoundContent={notFoundContent}
            filterOption={(input, option) => {
              return (
                option!.props.children.toLowerCase().includes(input.toLowerCase()) ||
                option!.value.toLowerCase().includes(input.toLowerCase())
              );
            }}
          >
            {Object.entries(options).map(([value, option]) => {
              return (
                <Option key={value} value={value}>
                  {option.label}
                </Option>
              );
            })}
          </Select>
          <Button type="primary" onClick={handleAddButtonClick}>
            <PlusOutlined />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default CustomSelect;
