import { AxiosError } from 'axios';
import {
  IForgotPasswordRequestAction,
  IForgotPasswordSuccessAction,
  IForgotPasswordFailureAction,
} from '@type/forgotPassword';
import { ForgotPasswordTypes } from './forgotPassword.constants';

export const forgotPasswordRequestAction = (payload: string): IForgotPasswordRequestAction => {
  return {
    payload,
    type: ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST,
  };
};

export const forgotPasswordSuccessAction = (payload: string): IForgotPasswordSuccessAction => {
  return {
    payload,
    type: ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS,
  };
};

export const forgotPasswordErrorAction = (payload: AxiosError): IForgotPasswordFailureAction => {
  return {
    payload,
    type: ForgotPasswordTypes.FORGOT_PASSWORD_FAILURE,
  };
};
