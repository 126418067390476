export enum UserDetailsActionTypes {
  GET_LATEST_IDP_REQUEST = 'GET_LATEST_IDP_REQUEST',
  GET_LATEST_IDP_SUCCESS = 'GET_LATEST_IDP_SUCCESS',
  GET_LATEST_IDP_ERROR = 'GET_LATEST_IDP_ERROR',

  GET_SCATTER_PLOT_DATA_REQUEST = 'GET_SCATTER_PLOT_DATA_REQUEST',
  GET_SCATTER_PLOT_DATA_SUCCESS = 'GET_SCATTER_PLOT_DATA_SUCCESS',
  GET_SCATTER_PLOT_DATA_ERROR = 'GET_SCATTER_PLOT_DATA_ERROR',

  GET_MINI_GRAPHS_DATA_REQUEST = 'GET_MINI_GRAPHS_DATA_REQUEST',
  GET_MINI_GRAPHS_DATA_SUCCESS = 'GET_MINI_GRAPHS_DATA_SUCCESS',
  GET_MINI_GRAPHS_DATA_ERROR = 'GET_MINI_GRAPHS_DATA_ERROR',
}
