import {
  ISaveBehaviourModel,
  ISaveNewBehaviourAction,
  ISaveNewBehaviourFailureAction,
  ISaveNewBehaviourSuccessAction,
} from '@type/behaviours';
import { NewBehaviourTypes } from './newBehaviour.constants';

export const saveNewBehaviourAction = (payload: ISaveBehaviourModel): ISaveNewBehaviourAction => {
  return {
    payload,
    type: NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_REQUEST,
  };
};

export const saveNewBehaviourSuccessAction = (): ISaveNewBehaviourSuccessAction => {
  return {
    type: NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_SUCCESS,
  };
};

export const saveNewBehaviourFailureAction = (error: Error): ISaveNewBehaviourFailureAction => {
  return {
    error,
    type: NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_FAILURE,
  };
};
