import { IPaginatedResponse } from '@utils/interfaces/response';
import {
  IFetchUserFailureAction,
  IFetchUserRequestAction,
  IFetchUserSuccessAction,
  IUserModel,
} from 'types/users';
import { UsersTypes } from './users.constants';

export const fetchUsersRequestAction = (page: number): IFetchUserRequestAction => {
  return {
    type: UsersTypes.FETCH_USERS_REQUEST,
    payload: page,
  };
};

export const fetchUsersSuccessAction = (
  payload: IPaginatedResponse<IUserModel>,
): IFetchUserSuccessAction => {
  return {
    type: UsersTypes.FETCH_USERS_SUCCESS,
    payload,
  };
};

export const fetchUsersErrorAction = (): IFetchUserFailureAction => {
  return {
    type: UsersTypes.FETCH_USERS_FAILURE,
  };
};

export const searchUsersAction = (message: string, page: number) => {
  return {
    type: UsersTypes.SEARCH_USERS,
    message,
    page,
  };
};

export const exportAllAction = () => {
  return {
    type: UsersTypes.EXPORT_ALL,
  };
};

export const exportAllSuccessAction = () => {
  return {
    type: UsersTypes.EXPORT_ALL_SUCCESS,
  };
};

export const exportAllErrorAction = (e: Error) => {
  return {
    type: UsersTypes.EXPORT_ALL_FAILURE,
    payload: e,
  };
};
