import { IHelpCenterState } from '@type/help-center';
import { HelpCenterTypes, IHelpCenterAction } from './help-center.constants';

const initialState: IHelpCenterState = {
  pending: false,
};

const HelpCenterReducer = (state = initialState, action: IHelpCenterAction): IHelpCenterState => {
  switch (action.type) {
    case HelpCenterTypes.HELP_CENTER_MESSAGE_ACTION:
      return { ...state, pending: true };
    case HelpCenterTypes.HELP_CENTER_MESSAGE_SUCCESS:
      return { ...state, pending: false };
    case HelpCenterTypes.HELP_CENTER_MESSAGE_ERROR:
      return { ...state, pending: false };
    default:
      return { ...state };
  }
};

export default HelpCenterReducer;
