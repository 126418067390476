import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import {
  getCompanyByIdRequest,
  removeCompanyByIdRequest,
  saveCompanyByIdRequest,
} from '@api/companies';
import { redirectAction } from '@store/actions';
import { ICompanyModel, ISaveCompanyAction } from '@type/companies';
import {
  removeCompanyByIdSuccess,
  removeCompanyByIdFailure,
  fetchCompanyByIdSuccess,
  fetchCompanyByIdFailure,
  saveCompanySuccessAction,
  saveCompanyFailureAction,
} from './editCompany.actions';
import { EditCompanyTypes } from './editCompany.constants';

interface IFetchCompanyByIdAction {
  type: string;
  payload: string;
}

const { removeCompanyErrorMessage, saveCompanyErrorMessage } = messages.alerts.error;
const { removeCompanySuccessMessage, editCompanySuccessMessage } = messages.alerts.success;

function* removeCompanyByIdSaga(action: IFetchCompanyByIdAction) {
  const { payload } = action;

  try {
    yield call(removeCompanyByIdRequest, payload);
    yield put(removeCompanyByIdSuccess());
  } catch (e) {
    yield put(removeCompanyByIdFailure(e));
  }

  yield;
}

function* removeCompanyByIdSuccessSaga() {
  yield put(redirectAction('/settings/companies'));
  yield message.success({
    content: removeCompanySuccessMessage,
    key: 'remove-company-success-message',
    duration: 3,
  });
}

function* removeCompanyByIdFailureSaga() {
  message.error({
    content: removeCompanyErrorMessage,
    key: 'remove-company-error-message',
    duration: 3,
  });
  yield;
}

function* fetchCompanyByIdSaga(action: IFetchCompanyByIdAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<ICompanyModel>> = yield call(
      getCompanyByIdRequest,
      payload,
    );
    yield put(fetchCompanyByIdSuccess(response.data.data));
  } catch (e) {
    yield put(fetchCompanyByIdFailure(e));
  }
}

function* saveCompanySaga(action: ISaveCompanyAction) {
  const { id, data } = action.payload;

  try {
    yield call(saveCompanyByIdRequest, id, data);
    yield put(saveCompanySuccessAction());
  } catch (e) {
    yield put(saveCompanyFailureAction(e));
  }

  yield;
}

function* saveCompanySuccessSaga() {
  yield put(redirectAction('/settings/companies'));
  yield message.success({
    content: editCompanySuccessMessage,
    key: 'save-company-success-message',
    duration: 3,
  });

  yield;
}

function* saveCompanyFailureSaga() {
  message.error({
    content: saveCompanyErrorMessage,
    key: 'save-company-error-message',
    duration: 3,
  });
  yield;
}

export function* editCompanySaga() {
  yield all([
    takeLatest(EditCompanyTypes.FETCH_COMPANY_BY_ID_REQUEST, fetchCompanyByIdSaga),
    takeLatest(EditCompanyTypes.REMOVE_COMPANY_BY_ID_REQUEST, removeCompanyByIdSaga),
    takeLatest(EditCompanyTypes.REMOVE_COMPANY_BY_ID_SUCCESS, removeCompanyByIdSuccessSaga),
    takeLatest(EditCompanyTypes.REMOVE_COMPANY_BY_ID_FAILURE, removeCompanyByIdFailureSaga),
    takeLatest(EditCompanyTypes.SAVE_COMPANY_REQUEST, saveCompanySaga),
    takeLatest(EditCompanyTypes.SAVE_COMPANY_SUCCESS, saveCompanySuccessSaga),
    takeLatest(EditCompanyTypes.SAVE_COMPANY_FAILURE, saveCompanyFailureSaga),
  ]);
}
