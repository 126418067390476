export enum EditFaqTypes {
  FETCH_FAQ_BY_ID_REQUEST = 'FETCH_FAQ_BY_ID_REQUEST',
  FETCH_FAQ_BY_ID_SUCCESS = 'FETCH_FAQ_BY_ID_SUCCESS',
  FETCH_FAQ_BY_ID_FAILURE = 'FETCH_FAQ_BY_ID_FAILURE',

  REMOVE_FAQ_BY_ID_REQUEST = 'REMOVE_FAQ_BY_ID_REQUEST',
  REMOVE_FAQ_BY_ID_SUCCESS = 'REMOVE_FAQ_BY_ID_SUCCESS',
  REMOVE_FAQ_BY_ID_FAILURE = 'REMOVE_FAQ_BY_ID_FAILURE',

  SAVE_FAQ_REQUEST = 'SAVE_FAQ_REQUEST',
  SAVE_FAQ_SUCCESS = 'SAVE_FAQ_SUCCESS',
  SAVE_FAQ_FAILURE = 'SAVE_FAQ_FAILURE',
}
