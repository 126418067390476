import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import {
  getCoachingNoteByIdRequest,
  removeCoachingNoteByIdRequest,
  updateCoachingNoteByIdRequest,
} from '@api/coachingNotes';
import { getOuByIdRequest } from '@api/ous';
import {
  fetchCoachingNoteByIdFailure,
  fetchCoachingNoteByIdSuccess,
  redirectAction,
  removeCoachingNoteByIdFailure,
  removeCoachingNoteByIdSuccess,
  updateCoachingNoteFailureAction,
  updateCoachingNoteSuccessAction,
} from '@store/actions';
import {
  ICoachingNotePayloadData,
  IFetchCoachingNoteByIdRequestAction,
  IRemoveCoachingNoteByIdFailureAction,
  IRemoveCoachingNoteByIdRequestAction,
  IUpdateCoachingNoteAction,
} from '@type/coachingNotes';
import { IOusModel } from '@type/ous';
import { EditCoachingNoteTypes } from './editCoachingNote.constants';

const {
  removeCoachingNoteErrorMessage,
  saveCoachingNoteErrorMessage,
  fetchCoachingNoteByIdErrorMessage,
} = messages.alerts.error;
const { removeCoachingNoteSuccessMessage, editCoachingNoteSuccessMessage } =
  messages.alerts.success;

function* fetchCoachingNoteByIdSaga(action: IFetchCoachingNoteByIdRequestAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<ICoachingNotePayloadData>> = yield call(
      getCoachingNoteByIdRequest,
      payload,
    );
    yield put(fetchCoachingNoteByIdSuccess(response.data.data));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(fetchCoachingNoteByIdFailure(e));
  }
}

function* removeCoachingNoteByIdSaga(action: IRemoveCoachingNoteByIdRequestAction) {
  const { payload } = action;

  try {
    yield call(removeCoachingNoteByIdRequest, payload);
    yield put(removeCoachingNoteByIdSuccess());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(removeCoachingNoteByIdFailure(e));
  }

  yield;
}

function* removeCoachingNoteByIdSuccessSaga() {
  yield put(redirectAction('/plans/coaching-notes'));
  yield message.success({
    content: removeCoachingNoteSuccessMessage,
    key: 'remove-coaching-note-success-message',
    duration: 3,
  });
}

function* removeCoachingNoteByIdFailureSaga() {
  message.error({
    content: removeCoachingNoteErrorMessage,
    key: 'remove-coaching-note-error-message',
    duration: 3,
  });
  yield;
}

function* fetchCoachingNoteByIdFailureSaga(action: IRemoveCoachingNoteByIdFailureAction) {
  const { error } = action;
  message.error({
    content: `${fetchCoachingNoteByIdErrorMessage}: ${error}`,
    key: 'fetch-coaching-note-by-id-error-message',
    duration: 3,
  });
  yield;
}

function* saveCoachingNoteSaga(action: IUpdateCoachingNoteAction) {
  const ouId: string = action.payload.ouId.toString();
  const response: AxiosResponse<IResponse<IOusModel>> = yield call(getOuByIdRequest, ouId);
  const leaderId = response.data.data.leader.id;

  try {
    yield call(updateCoachingNoteByIdRequest, {
      ...action.payload,
      savedBy: leaderId,
    });
    yield put(updateCoachingNoteSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(updateCoachingNoteFailureAction(e));
  }

  yield;
}

function* saveCoachingNoteSuccessSaga() {
  yield put(redirectAction('/plans/coaching-notes'));
  yield message.success({
    content: editCoachingNoteSuccessMessage,
    key: 'save-coaching-note-success-message',
    duration: 3,
  });

  yield;
}

function* saveCoachingNoteFailureSaga() {
  message.error({
    content: saveCoachingNoteErrorMessage,
    key: 'save-coaching-note-error-message',
    duration: 3,
  });
  yield;
}

export function* editCoachingNoteSaga() {
  yield all([
    takeLatest(EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_REQUEST, fetchCoachingNoteByIdSaga),
    takeLatest(
      EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_FAILURE,
      fetchCoachingNoteByIdFailureSaga,
    ),
    takeLatest(
      EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_REQUEST,
      removeCoachingNoteByIdSaga,
    ),
    takeLatest(
      EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_SUCCESS,
      removeCoachingNoteByIdSuccessSaga,
    ),
    takeLatest(
      EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_FAILURE,
      removeCoachingNoteByIdFailureSaga,
    ),
    takeLatest(EditCoachingNoteTypes.UPDATE_COACHING_NOTE_REQUEST, saveCoachingNoteSaga),
    takeLatest(EditCoachingNoteTypes.UPDATE_COACHING_NOTE_SUCCESS, saveCoachingNoteSuccessSaga),
    takeLatest(EditCoachingNoteTypes.UPDATE_COACHING_NOTE_FAILURE, saveCoachingNoteFailureSaga),
  ]);
}
