import { IEditFaqState, IEditFaqAction } from '@type/faq';
import { EditFaqTypes } from './editFaq.constants';

const initialState: IEditFaqState = {
  pending: false,
  faq: null,
  error: null,
};

const EditFaqReducer = (state = initialState, action: IEditFaqAction): IEditFaqState => {
  switch (action.type) {
    case EditFaqTypes.FETCH_FAQ_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditFaqTypes.FETCH_FAQ_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, faq: action.faq };
    case EditFaqTypes.FETCH_FAQ_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, faq: null };

    case EditFaqTypes.REMOVE_FAQ_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditFaqTypes.REMOVE_FAQ_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, faq: null };
    case EditFaqTypes.REMOVE_FAQ_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditFaqTypes.SAVE_FAQ_REQUEST:
      return { ...state, pending: true, error: null };
    case EditFaqTypes.SAVE_FAQ_SUCCESS:
      return { ...state, pending: false, error: null, faq: null };
    case EditFaqTypes.SAVE_FAQ_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditFaqReducer;
