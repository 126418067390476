import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { redirectAction } from '@store/redirect/redirect.actions';
import { ISaveNewOuAction } from '@type/ous';
import { messages } from '@utils';
import { saveNewBehaviourRequest } from '@api/behaviours';
import { NewBehaviourTypes } from './newBehaviour.constants';
import {
  saveNewBehaviourFailureAction,
  saveNewBehaviourSuccessAction,
} from './newBehaviour.actions';

const { saveOuSuccessMessage } = messages.alerts.success;
const { saveOuErrorMessage } = messages.alerts.error;

function* saveNewBehaviourSaga(action: ISaveNewOuAction) {
  const { payload } = action;
  try {
    yield call(saveNewBehaviourRequest, payload);
    yield put(saveNewBehaviourSuccessAction());
  } catch (e) {
    yield put(saveNewBehaviourFailureAction(e));
  }

  yield;
}

function* saveNewBehaviourSuccessSaga() {
  yield put(redirectAction('/settings/behaviours'));
  yield message.success({
    content: saveOuSuccessMessage,
    key: 'save-new-behaviour-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewOuFailureSaga() {
  message.error({
    content: saveOuErrorMessage,
    key: 'save-new-behaviour-success-message',
    duration: 3,
  });

  yield;
}

export function* newBehaviourSaga() {
  yield all([
    takeLatest(NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_REQUEST, saveNewBehaviourSaga),
    takeLatest(NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_SUCCESS, saveNewBehaviourSuccessSaga),
    takeLatest(NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_FAILURE, saveNewOuFailureSaga),
  ]);
}
