import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { resetPasswordRequest } from '@api/forgotPassword';
import {
  IResetPasswordFailureAction,
  IResetPasswordRequestAction,
  IResetPasswordSuccessAction,
} from '@type/forgotPassword';
import { redirectAction } from '@store/redirect/redirect.actions';
import { ResetPasswordTypes } from './resetPassword.constants';
import { resetPasswordErrorAction, resetPasswordSuccessAction } from './resetPassword.actions';

function* resetPasswordRequestSaga(action: IResetPasswordRequestAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse = yield call(resetPasswordRequest, payload);

    yield put(resetPasswordSuccessAction(response.data.message));
    yield put(redirectAction('/'));
  } catch (e) {
    yield put(resetPasswordErrorAction(e));
  }

  yield;
}

function* resetPasswordSuccessSaga(action: IResetPasswordSuccessAction) {
  const { payload } = action;

  yield antdMessage.success({
    content: payload,
    key: 'settings-reset-password-success-message',
    duration: 3,
  });

  yield;
}

function* resetPasswordFailureSaga(action: IResetPasswordFailureAction) {
  const { payload } = action;

  yield antdMessage.error({
    content: payload?.message,
    key: 'settings-reset-password-error-message',
    duration: 3,
  });

  yield;
}

export function* resetPasswordSaga() {
  yield all([
    takeLatest(ResetPasswordTypes.RESET_PASSWORD_REQUEST, resetPasswordRequestSaga),
    takeLatest(ResetPasswordTypes.RESET_PASSWORD_SUCCESS, resetPasswordSuccessSaga),
    takeLatest(ResetPasswordTypes.RESET_PASSWORD_FAILURE, resetPasswordFailureSaga),
  ]);
}
