import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';
import {
  IForgotPasswordModel,
  IForgotPasswordRequestData,
  IResetPasswordRequestData,
} from '@type/forgotPassword';

export const forgotPasswordRequest = (data: IForgotPasswordRequestData) => {
  return apiClient.post<IResponse<IForgotPasswordModel>>(`/api/forgot-password`, data);
};

export const resetPasswordRequest = (data: IResetPasswordRequestData) => {
  return apiClient.post<IResponse<IForgotPasswordModel>>(`/api/reset-password`, data);
};
