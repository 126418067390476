import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { OusModelType } from '@type/ous';
import { getOusRequest } from '@api/ous';
import { fetchOusError, fetchOusSuccess } from './ous.actions';
import { ousTypes } from './ous.constants';

const { errorMessage } = messages.alerts.error;

function* fetchOusSaga() {
  try {
    const response: AxiosResponse<IResponse<OusModelType[]>> = yield call(getOusRequest);
    yield put(fetchOusSuccess(response.data.data));
  } catch (e) {
    yield put(fetchOusError());
  }
}

function* fetchOusFailureSaga() {
  antdMessage.error({
    content: errorMessage,
    key: 'settings-ous-message',
    duration: 3,
  });

  yield;
}

export function* ousSaga() {
  yield all([
    takeLatest(ousTypes.FETCH_OUS_REQUEST, fetchOusSaga),
    takeLatest(ousTypes.FETCH_OUS_FAILURE, fetchOusFailureSaga),
  ]);
}
