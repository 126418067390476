import { IFaqModel, ISaveFaqModel } from '@type/faq';
import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';

export const getFaqRequest = () => {
  return apiClient.get<IResponse<IFaqModel[]>>(`/api/faqs`);
};

export const getFaqByIdRequest = (id: string) => {
  return apiClient.get<IResponse<IFaqModel>>(`/api/faqs/${id}/show`);
};

export const removeFaqByIdRequest = (id: string) => {
  return apiClient.delete<IResponse<IFaqModel>>(`/api/faqs/${id}`);
};

export const saveFaqByIdRequest = (id: string, data: ISaveFaqModel) => {
  return apiClient.put<IResponse<IFaqModel>>(`/api/faqs/${id}`, data);
};

export const saveNewFaqRequest = (data: ISaveFaqModel) => {
  return apiClient.post<IResponse<IFaqModel>>(`/api/faqs/`, data);
};
