import {
  INewUserModel,
  ISaveNewUserFailureAction,
  ISaveNewUserSuccessAction,
  ISaveNewUser,
} from '@type/users';
import { NewUserTypes } from './newUser.constants';

export const saveNewUserAction = (data: INewUserModel): ISaveNewUser => {
  return {
    type: NewUserTypes.SAVE_NEW_USER_REQUEST,
    payload: data,
  };
};

export const saveNewUserSuccessAction = (id: number): ISaveNewUserSuccessAction => {
  return {
    type: NewUserTypes.SAVE_NEW_USER_SUCCESS,
    payload: id,
  };
};

export const saveNewUserFailureAction = (error: Error): ISaveNewUserFailureAction => {
  return {
    type: NewUserTypes.SAVE_NEW_USER_FAILURE,
    payload: error,
  };
};
