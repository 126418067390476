import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import randomColor from 'randomcolor';
import {
  getAnalysisBehavioursRequest,
  getBehavioursLineChartDataRequest,
} from '@api/analysisBehaviours';
import { getUserByIdRequest } from '@api/users';
import {
  getLineChartDataSuccessAction,
  setBehavioursListAction,
  setFilteredLineChartDataSuccessAction,
  setFilteredUsersAction,
} from '@store/actions';
import { IResponse } from '@utils/interfaces/response';
import { messages, OptionListType } from '@utils';
import { ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE } from '@utils/global/configs';
import {
  AnalysisBehavioursUsersListType,
  FilteredLineChartDataType,
  IGetAnalysisBehavioursUsersRequestAction,
  IGetLineChartDataRequestAction,
  ISetBehavioursSelectedOptionAction,
  ISetFilteredLineChartDataRequestAction,
  LineChartDataType,
} from '@type/analysisBehaviours';
import { StateType } from '@type/general';
import { IUserModel } from '@type/users';
import {
  getAnalysisBehavioursUsersErrorAction,
  getAnalysisBehavioursUsersSuccessAction,
} from './behaviours.actions';
import { AnalysisBehavioursTypes } from './behaviours.constants';

const { getBehavioursPageUsersMsg } = messages.alerts.error;

const behavioursListMapper = (data: AnalysisBehavioursUsersListType): OptionListType => {
  const behavioursList: OptionListType = [];

  data.forEach((user) => {
    user.scatterData.forEach((behaviour) => {
      behavioursList.push({
        label: behaviour.behaviourName,
        value: behaviour.behaviourId.toString(),
      });
    });
  });

  const uniqueBehaviours = [
    ...new Map(behavioursList.map((item) => [item['value'], item])).values(),
  ];

  return uniqueBehaviours;
};

const getUsersState = (state: StateType): AnalysisBehavioursUsersListType =>
  state.analysis.behaviours.users;
const getFilteredLineChartData = (state: StateType): FilteredLineChartDataType =>
  state.analysis.behaviours.filteredLineChartData;

function* getAnalysisBehavioursUsersRequestSaga(action: IGetAnalysisBehavioursUsersRequestAction) {
  const { payload: params } = action;

  try {
    const response: AxiosResponse<IResponse<AnalysisBehavioursUsersListType>> = yield call(
      getAnalysisBehavioursRequest,
      params,
    );

    let { data } = response.data;

    yield (data = data.map((user) => {
      return {
        ...user,
        userColor: randomColor(),
      };
    }));

    yield put(setBehavioursListAction(behavioursListMapper(data)));
    yield put(getAnalysisBehavioursUsersSuccessAction(data));
    yield put(setFilteredUsersAction(data));
  } catch (e) {
    yield put(getAnalysisBehavioursUsersErrorAction());
  }
}

function* getLineChartDataRequestSaga(action: IGetLineChartDataRequestAction) {
  let { payload: params } = action;

  if (params.behaviourId === ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE) {
    const { behaviourId: _, ...rest } = params;

    params = rest;
  }

  try {
    const response: AxiosResponse<IResponse<LineChartDataType>> = yield call(
      getBehavioursLineChartDataRequest,
      params,
    );
    const { data } = response.data;

    yield put(getLineChartDataSuccessAction(data));
  } catch (e) {
    message.error({
      content: (e as Error).message,
      key: 'behaviours-error-message',
      duration: 3,
    });
  }
}

function* getLineChartDataFilteredDataRequestSaga(action: ISetFilteredLineChartDataRequestAction) {
  const { payload } = action;
  let params;

  if (payload.behaviourId === ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE) {
    const { behaviourId: _, ...rest } = payload;

    params = rest;
  }

  try {
    if (params) {
      const filteredLineChartData: FilteredLineChartDataType = yield select(
        getFilteredLineChartData,
      );
      const result: FilteredLineChartDataType = filteredLineChartData;
      const response: AxiosResponse<IResponse<LineChartDataType>> = yield call(
        getBehavioursLineChartDataRequest,
        params,
      );
      const { data } = response.data;
      const user: AxiosResponse<IResponse<IUserModel>> = yield call(
        getUserByIdRequest,
        params.userId.toString(),
      );

      const computedData: LineChartDataType = yield data.map((timeframe) => {
        timeframe.user = user.data.data;

        return timeframe;
      });

      if (params.push) {
        result[params.userId] = computedData;
      } else {
        delete result[params.userId];
      }

      yield put(setFilteredLineChartDataSuccessAction(result));
    }
  } catch (e) {
    message.error({
      content: (e as Error).message,
      key: 'behaviours-error-message',
      duration: 3,
    });
  }
  yield;
}

function* getAnalysisBehavioursUsersFailureSaga() {
  message.error({
    content: getBehavioursPageUsersMsg,
    key: 'new-assessment-loading-message',
    duration: 3,
  });

  yield;
}

function* setBehaviourSelectedOptionSaga(action: ISetBehavioursSelectedOptionAction) {
  const { payload } = action;

  try {
    const users: AnalysisBehavioursUsersListType = yield select(getUsersState);
    const result: AnalysisBehavioursUsersListType = [];

    if (users.length) {
      if (payload === ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE) {
        yield put(setFilteredUsersAction(users));

        return;
      }

      yield users.forEach((user) => {
        user.scatterData.forEach((behaviour) => {
          if (behaviour.behaviourId === payload) {
            result.push({
              ...user,
              competenceAverage: behaviour.competence,
              commitmentAverage: behaviour.commitment,
            });
          }
        });
      });

      yield put(setFilteredUsersAction(result));
    }
  } catch (e) {
    message.error({
      content: (e as Error).message,
      key: 'behaviours-error-message',
      duration: 3,
    });
  }

  yield;
}

export function* analysisBehavioursSaga() {
  yield all([
    takeLatest(
      AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_REQUEST,
      getAnalysisBehavioursUsersRequestSaga,
    ),
    takeLatest(
      AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_ERROR,
      getAnalysisBehavioursUsersFailureSaga,
    ),
    takeLatest(
      AnalysisBehavioursTypes.SET_BEHAVIOURS_SELECTED_OPTION,
      setBehaviourSelectedOptionSaga,
    ),
    takeLatest(AnalysisBehavioursTypes.GET_LINE_CHART_DATA_REQUEST, getLineChartDataRequestSaga),
    takeLatest(
      AnalysisBehavioursTypes.SET_FILTERED_LINE_CHART_DATA_REQUEST,
      getLineChartDataFilteredDataRequestSaga,
    ),
  ]);
}
