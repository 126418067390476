import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { forgotPasswordRequest } from '@api/forgotPassword';
import { IForgotPasswordRequestAction, IForgotPasswordSuccessAction } from '@type/forgotPassword';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import { ForgotPasswordTypes } from './forgotPassword.constants';
import { forgotPasswordErrorAction, forgotPasswordSuccessAction } from './forgotPassword.actions';

const { thisEmailIsNotCorrect } = messages.alerts.error;

function* forgotPasswordRequestSaga(action: IForgotPasswordRequestAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse = yield call(forgotPasswordRequest, {
      email: payload,
    });

    yield put(forgotPasswordSuccessAction(response.data.message));
    yield put(redirectAction('/'));
  } catch (e) {
    yield put(forgotPasswordErrorAction(e));
  }

  yield;
}

function* forgotPasswordSuccessSaga(action: IForgotPasswordSuccessAction) {
  const { payload } = action;

  // TODO: Success message is << We have emailed your password reset link! >> in both cases: when I am admin and change users password and when I am user.
  // will be better to change work <<your>> to <<user's>> if it was admin
  yield antdMessage.success({
    content: payload,
    key: 'settings-forgot-password-success-message',
    duration: 3,
  });

  yield;
}

function* forgotPasswordFailureSaga() {
  yield antdMessage.error({
    content: thisEmailIsNotCorrect,
    key: 'settings-forgot-password-error-message',
    duration: 3,
  });

  yield;
}

export function* forgotPasswordSaga() {
  yield all([
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga),
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS, forgotPasswordSuccessSaga),
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_FAILURE, forgotPasswordFailureSaga),
  ]);
}
