import React, { FC } from 'react';
import { Row, Col } from 'antd';
import './InputLayout.scss';

interface IProps {
  children: React.ReactNode;
  inputName: string;
  label: string;
  required?: boolean;
  labelSpan?: number;
  wrapperSpan?: number;
}

const InputLayout: FC<IProps> = ({
  children,
  inputName,
  label,
  required = false,
  labelSpan = 7,
  wrapperSpan = 17,
}) => {
  const labelClassName: string = required ? 'required' : '';

  return (
    <Row className="input-layout-row">
      <Col span={labelSpan}>
        <label className={labelClassName} htmlFor={inputName}>
          {label}
        </label>
      </Col>
      <Col span={wrapperSpan}>{children}</Col>
    </Row>
  );
};

export default InputLayout;
