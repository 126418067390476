import {
  AnalysisUserDetailMiniGraphsType,
  AnalysisUserDetailScatterPlotDataType,
  IGetScatterPlotDataRequestPayload,
  IUserIdPayload,
} from '@type/analysisUserDetail';
import { IIdpModel } from '@type/idps';
import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';

export const getLatestIdpRequest = (params: IUserIdPayload) => {
  return apiClient.get<IResponse<IIdpModel>>(`/api/idp_latest`, {
    params,
  });
};

export const getScatterPlotDataRequest = (params: IGetScatterPlotDataRequestPayload) => {
  return apiClient.get<IResponse<AnalysisUserDetailScatterPlotDataType>>(
    `/api/graphs/user_detail`,
    {
      params,
    },
  );
};

export const getMiniGraphDataRequest = (params: IUserIdPayload) => {
  return apiClient.get<IResponse<AnalysisUserDetailMiniGraphsType>>(`/api/graphs/mini_graphs`, {
    params,
  });
};
