export enum RedirectActionTypes {
  REDIRECT_ACTION = 'REDIRECT_ACTION',
}

export type RedirectActionType = {
  type: RedirectActionTypes.REDIRECT_ACTION;
  path: string;
};

export const redirectAction = (path: string): RedirectActionType => {
  return {
    path,
    type: RedirectActionTypes.REDIRECT_ACTION,
  };
};
