import { AnalysisPeopleActionType, IAnalysisPeopleState } from '@type/analysisPeople';
import { AnalysisPeopleTypes } from './people.constants';

const initialState: IAnalysisPeopleState = {
  loading: true,
  behaviours: [],
};

const AnalysisPeopleReducer = (
  state = initialState,
  action: AnalysisPeopleActionType,
): IAnalysisPeopleState => {
  switch (action.type) {
    case AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_REQUEST:
      return { ...state, loading: true };
    case AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_SUCCESS:
      return { ...state, loading: false, behaviours: action.payload };
    case AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_ERROR:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};

export default AnalysisPeopleReducer;
