import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { redirectAction } from '@store/redirect/redirect.actions';
import {
  fetchOuByIdFailure,
  fetchOuByIdSuccess,
  removeOuByIdFailure,
  removeOuByIdSuccess,
  saveOuFailureAction,
  saveOuSuccessAction,
} from '@store/actions';
import { IOusModel, ISaveOuAction } from '@type/ous';
import { messages } from '@utils';
import { getOuByIdRequest, removeOuByIdRequest, saveOuByIdRequest } from '@api/ous';
import { EditOuTypes } from './editOu.constants';

interface IFetchOuByIdAction {
  type: string;
  payload: string;
}

const { removeOuErrorMessage, saveOuErrorMessage } = messages.alerts.error;
const { removeOuSuccessMessage, saveOuSuccessMessage } = messages.alerts.success;

function* removeOuByIdSaga(action: IFetchOuByIdAction) {
  const { payload } = action;

  try {
    yield call(removeOuByIdRequest, payload);
    yield put(removeOuByIdSuccess());
  } catch (e) {
    yield put(removeOuByIdFailure(e));
  }

  yield;
}

function* removeOuByIdSuccessSaga() {
  yield put(redirectAction('/settings/ous'));
  yield message.success({
    content: removeOuSuccessMessage,
    key: 'remove-ou-success-message',
    duration: 3,
  });
}

function* removeOuByIdFailureSaga() {
  message.error({
    content: removeOuErrorMessage,
    key: 'remove-ou-error-message',
    duration: 3,
  });
  yield;
}

function* fetchOuByIdSaga(action: IFetchOuByIdAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IOusModel>> = yield call(getOuByIdRequest, payload);
    yield put(fetchOuByIdSuccess(response.data.data));
  } catch (e) {
    yield put(fetchOuByIdFailure(e));
  }
}

function* saveOuSaga(action: ISaveOuAction) {
  const { id, data } = action.payload;

  try {
    yield call(saveOuByIdRequest, id, data);
    yield put(saveOuSuccessAction());
  } catch (e) {
    yield put(saveOuFailureAction(e));
  }

  yield;
}

function* saveOuSuccessSaga() {
  yield put(redirectAction('/settings/ous'));
  yield message.success({
    content: saveOuSuccessMessage,
    key: 'save-ou-success-message',
    duration: 3,
  });

  yield;
}

function* saveOuFailureSaga() {
  message.error({
    content: saveOuErrorMessage,
    key: 'save-ou-error-message',
    duration: 3,
  });
  yield;
}

export function* editOuSaga() {
  yield all([
    takeLatest(EditOuTypes.FETCH_OU_BY_ID_REQUEST, fetchOuByIdSaga),
    takeLatest(EditOuTypes.REMOVE_OU_BY_ID_REQUEST, removeOuByIdSaga),
    takeLatest(EditOuTypes.REMOVE_OU_BY_ID_SUCCESS, removeOuByIdSuccessSaga),
    takeLatest(EditOuTypes.REMOVE_OU_BY_ID_FAILURE, removeOuByIdFailureSaga),
    takeLatest(EditOuTypes.SAVE_OU_REQUEST, saveOuSaga),
    takeLatest(EditOuTypes.SAVE_OU_SUCCESS, saveOuSuccessSaga),
    takeLatest(EditOuTypes.SAVE_OU_FAILURE, saveOuFailureSaga),
  ]);
}
