import { AxiosResponse } from 'axios';
import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { message as antdMessage } from 'antd';
import { IPaginatedResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { IFetchUserRequestAction, IUserModel } from '@type/users';
import { exportAllRequest, getSearchedUsersRequest, getUsersRequest } from '@api/users';
import {
  exportAllErrorAction,
  exportAllSuccessAction,
  fetchUsersErrorAction,
  fetchUsersSuccessAction,
} from './users.actions';
import { UsersTypes } from './users.constants';

interface ISearchAction {
  type: string;
  message: string;
  page: number;
}

const { errorMessage } = messages.alerts.error;
const { exportAllFailureMessage } = messages.alerts.error;
const { exportAllSuccessMessage } = messages.alerts.success;

function* fetchUsersSaga(action: IFetchUserRequestAction) {
  try {
    const response: AxiosResponse<IPaginatedResponse<IUserModel>> = yield call(
      getUsersRequest,
      action.payload,
    );
    yield put(fetchUsersSuccessAction(response.data));
  } catch (e) {
    yield put(fetchUsersErrorAction());
  }
}

function* fetchUsersFailureSaga() {
  antdMessage.error({
    content: errorMessage,
    key: 'settings-users-error-message',
    duration: 3,
  });

  yield;
}

function* searchUsersSaga(action: ISearchAction) {
  const { message, page } = action;

  try {
    const response: AxiosResponse<IPaginatedResponse<IUserModel>> = yield call(
      getSearchedUsersRequest,
      message,
      page,
    );

    yield put(fetchUsersSuccessAction(response.data));
  } catch (e) {
    antdMessage.error({
      content: errorMessage,
      key: 'settings-search-users-error-message',
      duration: 3,
    });

    yield;
  }
}

function* exportAllSaga() {
  try {
    const { data }: AxiosResponse = yield call(exportAllRequest);
    FileSaver.saveAs(data, 'report.xlsx');
    yield put(exportAllSuccessAction());
  } catch (e) {
    yield put(exportAllErrorAction(e));
  }
  yield;
}

function* exportAllSuccessSaga() {
  yield antdMessage.success({
    content: exportAllSuccessMessage,
    key: 'export-users-success-message',
    duration: 3,
  });
}

function* exportAllFailureSaga() {
  yield antdMessage.error({
    content: exportAllFailureMessage,
    key: 'export-users-error-message',
    duration: 3,
  });
}

export function* usersSaga() {
  yield all([
    takeLatest(UsersTypes.FETCH_USERS_REQUEST, fetchUsersSaga),
    debounce(500, UsersTypes.SEARCH_USERS, searchUsersSaga),
    takeLatest(UsersTypes.FETCH_USERS_FAILURE, fetchUsersFailureSaga),
    takeLatest(UsersTypes.EXPORT_ALL, exportAllSaga),
    takeLatest(UsersTypes.EXPORT_ALL_SUCCESS, exportAllSuccessSaga),
    takeLatest(UsersTypes.EXPORT_ALL_FAILURE, exportAllFailureSaga),
  ]);
}
