import { ILoading } from '@type/general';
import { INewAssessmentGetCurrentResponse, NewAssessmentActionType } from '@type/newAssessment';
import { NewAssessmentTypes } from './newAssessment.constants';

interface ICustomStateProps {
  getCurrentPending: boolean;
  currentAssessment: INewAssessmentGetCurrentResponse['data'];
}

type NewAssigmentStateType = ILoading & ICustomStateProps;

const initialState: NewAssigmentStateType = {
  pending: true,
  getCurrentPending: false,
  currentAssessment: [],
  success: false,
  error: false,
};

const NewAssessmentReducer = (
  state = initialState,
  action: NewAssessmentActionType,
): NewAssigmentStateType => {
  switch (action.type) {
    case NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_REQUEST:
      return { ...state, getCurrentPending: true };

    case NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_SAVE:
      return { ...state, currentAssessment: action.payload };

    case NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_FINISH:
      return { ...state, getCurrentPending: false };

    case NewAssessmentTypes.NEW_ASSESSMENT_SET_CURRENT:
    case NewAssessmentTypes.NEW_ASSESSMENT_SUBMIT_CURRENT:
      return { ...state };

    default:
      return { ...state };
  }
};

export default NewAssessmentReducer;
