import { IOusAction, IOusState } from '@type/ous';
import { ousTypes } from './ous.constants';

const initialState: IOusState = {
  pending: false,
  ous: [],
  error: null,
};

const OusReducer = (state = initialState, action: IOusAction) => {
  switch (action.type) {
    case ousTypes.FETCH_OUS_REQUEST:
      return { ...state, pending: true, error: null };
    case ousTypes.FETCH_OUS_SUCCESS:
      return { ...state, pending: false, ous: action.payload };
    case ousTypes.FETCH_OUS_FAILURE:
      return { ...state, pending: false, error: action.payload.error, ous: [] };
    default:
      return { ...state };
  }
};

export default OusReducer;
