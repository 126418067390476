import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import {
  newAssessmentGetCurrent,
  newAssessmentSetCurrent,
  newAssessmentSetCurrentFirst,
  newAssessmentSubmitCurrent,
} from '@api/newAssessment';
import {
  newAssessmentGetCurrentFinishAction,
  newAssessmentGetCurrentSaveAction,
} from '@store/actions';
import { messages } from '@utils';
import {
  INewAssessmentGetCurrentAction,
  INewAssessmentGetCurrentResponse,
  INewAssessmentSetCurrentAction,
} from '@type/newAssessment';
import { NewAssessmentMessages, NewAssessmentTypes } from './newAssessment.constants';

const { saveNewAssessmentSuccessMessage } = messages.alerts.success;

function* newAssessmentGetCurrentActionSaga({ payload }: INewAssessmentGetCurrentAction) {
  try {
    const response: AxiosResponse<INewAssessmentGetCurrentResponse> = yield call(
      newAssessmentGetCurrent,
      payload,
    );

    yield put(newAssessmentGetCurrentSaveAction(response.data.data));
  } finally {
    yield put(newAssessmentGetCurrentFinishAction());
  }

  yield;
}

function* newAssessmentSetCurrentActionSaga({ payload }: INewAssessmentSetCurrentAction) {
  try {
    const { itemCreated, ...rest } = payload;

    const response: AxiosResponse<INewAssessmentGetCurrentResponse> = yield call(
      itemCreated ? newAssessmentSetCurrent : newAssessmentSetCurrentFirst,
      rest,
    );

    yield message.success({
      content: saveNewAssessmentSuccessMessage,
      key: NewAssessmentMessages.loadingMessageKey,
      duration: 3,
    });

    yield put(newAssessmentGetCurrentSaveAction(response.data.data));
  } finally {
    yield put(newAssessmentGetCurrentFinishAction());
  }

  yield;
}

function* newAssessmentSubmitCurrentActionSaga({ payload }: INewAssessmentSetCurrentAction) {
  try {
    const response: AxiosResponse<INewAssessmentGetCurrentResponse> = yield call(
      newAssessmentSubmitCurrent,
      payload,
    );

    yield message.success({
      content: saveNewAssessmentSuccessMessage,
      key: NewAssessmentMessages.loadingMessageKey,
      duration: 3,
    });

    yield put(newAssessmentGetCurrentSaveAction(response.data.data));
  } finally {
    yield put(newAssessmentGetCurrentFinishAction());
  }

  yield;
}

export function* newAssessmentSaga() {
  yield all([
    takeLatest(
      NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_REQUEST,
      newAssessmentGetCurrentActionSaga,
    ),
    takeLatest(NewAssessmentTypes.NEW_ASSESSMENT_SET_CURRENT, newAssessmentSetCurrentActionSaga),
    takeLatest(
      NewAssessmentTypes.NEW_ASSESSMENT_SUBMIT_CURRENT,
      newAssessmentSubmitCurrentActionSaga,
    ),
  ]);
}
