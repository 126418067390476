export enum EditCoachingNoteTypes {
  FETCH_COACHING_NOTE_BY_ID_REQUEST = 'FETCH_COACHING_NOTE_BY_ID_REQUEST',
  FETCH_COACHING_NOTE_BY_ID_SUCCESS = 'FETCH_COACHING_NOTE_BY_ID_SUCCESS',
  FETCH_COACHING_NOTE_BY_ID_FAILURE = 'FETCH_COACHING_NOTE_BY_ID_FAILURE',

  REMOVE_COACHING_NOTE_BY_ID_REQUEST = 'REMOVE_COACHING_NOTE_BY_ID_REQUEST',
  REMOVE_COACHING_NOTE_BY_ID_SUCCESS = 'REMOVE_COACHING_NOTE_BY_ID_SUCCESS',
  REMOVE_COACHING_NOTE_BY_ID_FAILURE = 'REMOVE_COACHING_NOTE_BY_ID_FAILURE',

  UPDATE_COACHING_NOTE_REQUEST = 'UPDATE_COACHING_NOTE_REQUEST',
  UPDATE_COACHING_NOTE_SUCCESS = 'UPDATE_COACHING_NOTE_SUCCESS',
  UPDATE_COACHING_NOTE_FAILURE = 'UPDATE_COACHING_NOTE_FAILURE',
}
