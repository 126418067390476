import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getOus } from '@api/general';
import { getOusRoles } from '@api/ous';
import { DumbDebounceSelect, DebounceCustomSelect } from '@components';
import {
  debouncePopRequestAction,
  debouncePushRequestAction,
  debounceSelectItemAction,
  debounceSelectSearchAction,
} from '@store/actions';
import {
  ouRoleSelectPushItemAction,
  ouRoleSelectPopItemRequestAction,
} from '@store/components/ou-role-select/ouRoleSelect.actions';
import {
  OuRoleSelectedOptionsType,
  RoleSelectedOptionsType,
  OuRoleSelectOptionType,
} from '@type/ouRoleSelect';
import { StateType } from '@type/general';
import { IDebounceSelectState } from '@type/debounceSelect';
import { IOptionType } from '@type/users';
import { getOptions } from '@utils/global/helpers';
import SelectedOption from './SelectedOption';
import './OuRoleSelect.scss';

export type SelectOptionType = Record<'key' | 'value' | 'label', string>;
type SelectStateType = IDebounceSelectState[0];

interface IOuRoleSelectProps {
  name: string;
}

const OuRoleSelect: FC<IOuRoleSelectProps> = ({ name }) => {
  const [roleSelectOption, setRoleSelectOption] = useState<IOptionType>(null);
  const dispatch = useDispatch();

  const roleSelectName = `${name}-rolesList`;

  const { loading, options, selectedOption } = useSelector<StateType, SelectStateType>((state) => {
    return state.components.debounceSelect[name] || {};
  });
  const roleSelect = useSelector<StateType, SelectStateType>((state) => {
    return state.components.debounceSelect[roleSelectName] || {};
  });
  const selectedOptionsList = useSelector<StateType, OuRoleSelectedOptionsType>(
    (state) => state?.components?.ouRoleSelect?.selectedOptionsList,
  );

  useEffect(() => {
    setRoleSelectOption(roleSelect.selectedOption);
  }, [roleSelect.selectedOption]);

  const getRoleSelectedOptions = (): RoleSelectedOptionsType => ({
    ou: selectedOption,
    role: roleSelectOption,
  });

  const onFocus = () => {
    if (!options || !Object.keys(options).length) {
      dispatch(
        debounceSelectSearchAction({
          name,
          query: '',
          fetchFunction: getOus,
        }),
      );
    }
  };

  const handleSelect = (option: SelectOptionType): void => {
    const { value, label } = option;

    dispatch(debounceSelectItemAction({ name, option: { value, label } }));
  };

  const handleAddButtonClick = (): void => {
    if (selectedOption && !!Object.keys(roleSelect.selectedOption || {}).length) {
      dispatch(debouncePushRequestAction({ name }));
      dispatch(debounceSelectItemAction({ name: roleSelectName, option: null }));
      dispatch(ouRoleSelectPushItemAction(getRoleSelectedOptions()));
    }
  };

  const handleCombinedSelectDeleteButtonClick = (
    optionValue: OuRoleSelectOptionType | string,
  ): void => {
    if (typeof optionValue === 'string') {
      return;
    }

    const { ou, role } = optionValue;

    if (ou && role) {
      const { value } = ou;

      dispatch(debouncePopRequestAction({ value, name }));
      dispatch(ouRoleSelectPopItemRequestAction(value));
    }
  };

  const rolesMapper = async () => {
    const { data } = await getOusRoles();

    return getOptions(
      data.data.filter((role) => !role.name.toLowerCase().includes('admin')),
      {
        getName: (obj) => obj.name,
        getValue: (obj) => obj.id.toString(),
      },
    );
  };

  return (
    <Row className="ou-role-select">
      {Object.entries(selectedOptionsList || {}).map(([key, value]) => {
        return (
          <SelectedOption
            key={key}
            option={value}
            onClick={handleCombinedSelectDeleteButtonClick}
          />
        );
      })}
      <div className="input-container combined">
        <Col span="16">
          <DumbDebounceSelect
            name={name}
            options={options}
            loading={loading}
            value={selectedOption}
            onChange={handleSelect}
            onFocus={onFocus}
          />
        </Col>
        <Col span={8}>
          <DebounceCustomSelect name={roleSelectName} fetchOptions={rolesMapper} multiple={false} />
        </Col>
        <Button type="primary" onClick={handleAddButtonClick}>
          <PlusOutlined />
        </Button>
      </div>
    </Row>
  );
};

export default OuRoleSelect;
