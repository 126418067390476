import { FC } from 'react';
import { Button, Row } from 'antd';
import { messages } from '@utils';

interface IHeaderRowProps {
  isEditPage: boolean | undefined;
  confirm: () => void;
  forgotPassword: () => void;
}

const { editUserPage, newUserPage } = messages.settings.users;
const { subtitle: editUserPageSubtitle, topButtons } = editUserPage;
const { subtitle: newUserPageSubtitle } = newUserPage;
const { resetPassword: resetPasswordBtn, remove } = topButtons;

const HeaderRow: FC<IHeaderRowProps> = ({ isEditPage, confirm, forgotPassword }) => {
  if (isEditPage) {
    return (
      <Row className="header-row">
        <h3>{editUserPageSubtitle}</h3>
        <div className="buttons">
          <Button onClick={forgotPassword}>{resetPasswordBtn}</Button>
          <Button danger onClick={confirm}>
            {remove}
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <Row className="header-row">
      <h3>{newUserPageSubtitle}</h3>
    </Row>
  );
};

export default HeaderRow;
