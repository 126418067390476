import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { message as antdMessage } from 'antd';
import {
  IClearDebounceCustomSelectAction,
  IDebouncePopRequestAction,
  IDebouncePushItemAction,
  IDebounceSearchRequest,
  IDebounceSelectState,
  OptionHashTableType,
} from '@type/debounceSelect';
import debounceDeep from '@store/debounceDeep.effect';
import { StateType } from '@type/general';
import { IOption, messages } from '@utils';
import { clearSelectedOptionAction } from '../ou-role-select/ouRoleSelect.actions';
import {
  debouncePopItemAction,
  debouncePushItemAction,
  debounceSelectSearchAction,
  debounceSelectSearchSuccessAction,
  debounceSetSelectedOptionList,
} from './debounceSelect.actions';
import { DebounceSearchTypes } from './debounceSelect.constants';

const { errorMessage } = messages.alerts.error;

const getDebounceSelectState = (state: StateType): IDebounceSelectState =>
  state.components.debounceSelect;
const getSelectedOption = (state: StateType, name: string) => {
  return state.components.debounceSelect[name].selectedOption;
};

function* clearSelectSaga(action: IClearDebounceCustomSelectAction) {
  const { name, fetchFunction, combinedSelect } = action.payload;
  if (combinedSelect) {
    yield put(clearSelectedOptionAction());
  }

  yield put(debounceSetSelectedOptionList({ name, newSelectedOptions: {} }));
  yield put(debounceSelectSearchAction({ name, fetchFunction, query: '' }));

  yield;
}

function* searchSaga(action: IDebounceSearchRequest) {
  const { name, query, fetchFunction } = action.payload;

  try {
    const response: OptionHashTableType = yield call(fetchFunction, query);
    const state: IDebounceSelectState = yield select(getDebounceSelectState);
    const toBeExcludedList: string[] = yield Object.keys(state[name]?.selectedOptionList);
    const filteredResponse = Object.fromEntries(
      Object.entries(response).filter(([key]) => {
        return !toBeExcludedList.includes(key);
      }),
    );

    yield put(
      debounceSelectSearchSuccessAction({
        name,
        query,
        options: filteredResponse,
      }),
    );
  } catch (e) {
    antdMessage.error({
      content: errorMessage,
      key: 'settings-debounce-select-message',
      duration: 3,
    });

    yield;
  }
}

function* pushItemSaga(action: IDebouncePushItemAction) {
  const { name, option, options, selectedOptions } = action.payload;
  const newPushOptions: OptionHashTableType = { ...options };
  let filteredOptions: OptionHashTableType = {};
  let newSelectedOptions: OptionHashTableType = {
    ...selectedOptions,
  };

  if (option) {
    const { [option.value]: _, ...rest } = newPushOptions;
    filteredOptions = rest;

    newSelectedOptions = {
      ...newSelectedOptions,
      [option.value]: option,
    };
  }

  yield put(
    debouncePushItemAction({
      name,
      option,
      options: filteredOptions,
      selectedOptions: newSelectedOptions,
    }),
  );
}

function* pushRequestSaga(action: IDebouncePushItemAction) {
  const {
    payload: { name },
    type,
  } = action;

  const debounceSelectState: IDebounceSelectState = yield select(getDebounceSelectState);
  const selectedOption: IOption = yield select(getSelectedOption, name);

  const debounceSelect = { ...debounceSelectState[name] };

  yield call(pushItemSaga, {
    type,
    payload: {
      name,
      option: selectedOption,
      options: debounceSelect.options,
      selectedOptions: debounceSelect.selectedOptionList,
    },
  });
}

function* popItemSaga(action: IDebouncePopRequestAction) {
  const { name, value } = action.payload;

  const debounceSelectState: IDebounceSelectState = yield select(getDebounceSelectState);
  const debounceSelect = debounceSelectState[name];
  const deletedOption: IOption = { ...debounceSelect.selectedOptionList }[value];
  let filteredOptions: OptionHashTableType = {};

  let newPopOptionsList: OptionHashTableType = {};

  if (debounceSelect?.options && Object.keys(debounceSelect?.options).length) {
    newPopOptionsList = {
      ...debounceSelect.options,
      [value]: { ...deletedOption },
    };
  } else {
    newPopOptionsList = {
      [value]: { ...deletedOption },
    };
  }

  const newPopSelectedOptionsList: OptionHashTableType = {
    ...debounceSelect.selectedOptionList,
  };

  const { [value]: _, ...rest } = newPopSelectedOptionsList;
  filteredOptions = rest;

  yield put(
    debouncePopItemAction({
      name,
      newOptions: newPopOptionsList,
      newSelectedOptions: filteredOptions,
    }),
  );
}

function* popRequestItemSaga(action: IDebouncePopRequestAction) {
  const { payload, type } = action;

  yield call(popItemSaga, {
    type,
    payload,
  });
}

export function* debounceSelectSaga() {
  yield all([
    debounceDeep<IDebounceSearchRequest, typeof searchSaga>(
      DebounceSearchTypes.DEBOUNCE_SEARCH_REQUEST,
      (action) => {
        return action.payload.name;
      },
      searchSaga,
    ),
    takeLatest(DebounceSearchTypes.PUSH_ITEM_REQUEST, pushRequestSaga),
    takeLatest(DebounceSearchTypes.POP_ITEM_REQUEST, popRequestItemSaga),
    takeLatest(DebounceSearchTypes.CLEAR_DEBOUNCE_CUSTOM_SELECT, clearSelectSaga),
  ]);
}
