import { message } from 'antd';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { saveNewCoachingNoteRequest } from '@api/coachingNotes';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import {
  ISaveNewCoachingNoteFailureAction,
  ISaveNewCoachingNoteRequestAction,
} from '@type/coachingNotes';
import { saveNewCoachingNoteFailureAction, saveNewCoachingNoteSuccessAction } from '@store/actions';
import { NewCoachingNoteTypes } from './newCoachingNote.constants';

const { saveCoachingNoteSuccessMessage } = messages.alerts.success;
const { saveCoachingNoteErrorMessage } = messages.alerts.error;

function* saveNewCoachingNoteSaga(action: ISaveNewCoachingNoteRequestAction) {
  const { payload } = action;
  try {
    yield call(saveNewCoachingNoteRequest, payload);
    yield put(saveNewCoachingNoteSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(saveNewCoachingNoteFailureAction(e));
  }

  yield;
}

function* saveNewCoachingNoteSuccessSaga() {
  yield put(redirectAction('/plans/coaching-notes'));
  yield message.success({
    content: saveCoachingNoteSuccessMessage,
    key: 'save-new-coaching-note-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewOuFailureSaga(action: ISaveNewCoachingNoteFailureAction) {
  message.error({
    content: `${saveCoachingNoteErrorMessage}: ${action.payload.message}`,
    key: 'save-new-coaching-note-success-message',
    duration: 3,
  });

  yield;
}

export function* newCoachingNoteSaga() {
  yield all([
    takeLatest(NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_REQUEST, saveNewCoachingNoteSaga),
    takeLatest(NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_SUCCESS, saveNewCoachingNoteSuccessSaga),
    takeLatest(NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_FAILURE, saveNewOuFailureSaga),
  ]);
}
