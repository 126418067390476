import {
  IFetchOusFailureAction,
  IFetchOusRequestAction,
  IFetchOusSuccessAction,
  OusModelType,
} from '@type/ous';
import { ousTypes } from './ous.constants';

export const fetchOusRequest = (): IFetchOusRequestAction => {
  return {
    type: ousTypes.FETCH_OUS_REQUEST,
  };
};

export const fetchOusSuccess = (payload: Array<OusModelType>): IFetchOusSuccessAction => {
  return {
    type: ousTypes.FETCH_OUS_SUCCESS,
    payload,
  };
};

export const fetchOusError = (): IFetchOusFailureAction => {
  return {
    type: ousTypes.FETCH_OUS_FAILURE,
  };
};
