import {
  IFetchCompaniesRequestAction,
  IFetchCompaniesSuccessAction,
  IFetchCompaniesFailureAction,
  ICompanyModel,
} from '@type/companies';
import { ErrorNullType } from '@type/general';
import { CompaniesTypes } from './companies.constants';

export const fetchCompaniesRequest = (): IFetchCompaniesRequestAction => {
  return {
    type: CompaniesTypes.FETCH_COMPANIES_REQUEST,
  };
};

export const fetchCompaniesSuccess = (
  payload: Array<ICompanyModel>,
): IFetchCompaniesSuccessAction => {
  return {
    payload: {
      companies: payload,
    },
    type: CompaniesTypes.FETCH_COMPANIES_SUCCESS,
  };
};

export const fetchCompaniesError = (payload: ErrorNullType): IFetchCompaniesFailureAction => {
  return {
    payload,
    type: CompaniesTypes.FETCH_COMPANIES_FAILURE,
  };
};
