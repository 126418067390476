import { ResetPasswordActionType, IForgotPasswordState } from '@type/forgotPassword';
import { ResetPasswordTypes } from './resetPassword.constants';

const initialState: IForgotPasswordState = {
  pending: false,
  message: '',
  error: null,
};

const ResetPasswordReducer = (
  state = initialState,
  action: ResetPasswordActionType,
): IForgotPasswordState => {
  switch (action.type) {
    case ResetPasswordTypes.RESET_PASSWORD_REQUEST:
      return { ...state, pending: true, error: null };
    case ResetPasswordTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, pending: false, message: action.payload, error: null };
    case ResetPasswordTypes.RESET_PASSWORD_FAILURE:
      return { ...state, pending: false, error: action.payload, message: '' };
    default:
      return { ...state };
  }
};

export default ResetPasswordReducer;
