import { IBehavioursAction, IBehavioursState } from '@type/behaviours';
import { BehavioursTypes } from './behaviours.constants';

const initialState: IBehavioursState = {
  pending: false,
  behaviours: [],
  error: null,
};

const BehavioursReducer = (state = initialState, action: IBehavioursAction) => {
  switch (action.type) {
    case BehavioursTypes.FETCH_BEHAVIOURS_REQUEST:
      return { ...state, pending: true, error: null };
    case BehavioursTypes.FETCH_BEHAVIOURS_SUCCESS:
      return { ...state, pending: false, behaviours: action.payload };
    case BehavioursTypes.FETCH_BEHAVIOURS_FAILURE:
      return { ...state, pending: false, error: action.payload.error, behaviours: [] };
    default:
      return { ...state };
  }
};

export default BehavioursReducer;
