import { OuRoleSelectedOptionsType } from '@type/ouRoleSelect';
import { OuRolesListType } from '@type/users';
import { USER_PASSWORD_LENGTH } from '@utils/global/configs';
import { EMAIL_REGEX } from '@utils/global/regex';

export const getSelectedOptionsList = (list: OuRoleSelectedOptionsType): OuRolesListType => {
  if (!list) {
    return [];
  }

  return Object.entries(list).map(([_, option]) => {
    const { ou, role } = option;
    return {
      ouId: ou ? ou.value : '0',
      roleId: role ? role?.value : '0',
    };
  });
};

export const validateEmail = (email: string) => {
  const re = EMAIL_REGEX;

  return re.test(String(email));
};

export const validatePassword = (password: string) => {
  return password.length >= USER_PASSWORD_LENGTH;
};

export const validateOuRoles = (list: OuRoleSelectedOptionsType) => {
  return !!getSelectedOptionsList(list).length;
};
