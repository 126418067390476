import { IEditCompanyState, IEditCompanyAction } from '@type/companies';
import { EditCompanyTypes } from './editCompany.constants';

const initialState: IEditCompanyState = {
  pending: false,
  company: null,
  error: null,
};

const EditCompanyReducer = (
  state = initialState,
  action: IEditCompanyAction,
): IEditCompanyState => {
  switch (action.type) {
    case EditCompanyTypes.FETCH_COMPANY_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCompanyTypes.FETCH_COMPANY_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, company: action.company };
    case EditCompanyTypes.FETCH_COMPANY_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, company: null };

    case EditCompanyTypes.REMOVE_COMPANY_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCompanyTypes.REMOVE_COMPANY_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, company: null };
    case EditCompanyTypes.REMOVE_COMPANY_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditCompanyTypes.SAVE_COMPANY_REQUEST:
      return { ...state, pending: true, error: null };
    case EditCompanyTypes.SAVE_COMPANY_SUCCESS:
      return { ...state, pending: false, error: null, company: null };
    case EditCompanyTypes.SAVE_COMPANY_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditCompanyReducer;
