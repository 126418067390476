import React from 'react';
import { Col, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { IOption } from '@utils';
import { OuRoleSelectOptionType } from '@type/ouRoleSelect';

interface ISelectedOptionProps<T extends IOption | OuRoleSelectOptionType> {
  option: T;
  onClick: (value: string | T) => void;
  objectKey?: string;
}

const isCombinedOptionsType = (
  option: OuRoleSelectOptionType | IOption,
): option is OuRoleSelectOptionType => {
  return (
    Object.prototype.hasOwnProperty.call(option, 'ou') &&
    Object.prototype.hasOwnProperty.call(option, 'role')
  );
};

const SelectedOption = <T extends IOption | OuRoleSelectOptionType>({
  option,
  objectKey,
  onClick,
}: ISelectedOptionProps<T>) => {
  return (
    <div className="delete-input-container combined">
      {isCombinedOptionsType(option) ? (
        <>
          <Col span={16}>
            <Input value={option.ou?.label || ''} />
          </Col>
          <Col span={8}>
            <Input value={option.role?.label || ''} />
          </Col>
        </>
      ) : (
        <Input value={option.label} />
      )}

      <Button
        type="primary"
        danger
        onClick={() => {
          return isCombinedOptionsType(option) ? onClick(option) : onClick(objectKey!);
        }}
      >
        <CloseOutlined />
      </Button>
    </div>
  );
};

export default SelectedOption;
