import { FC } from 'react';
import { Layout } from 'antd';
import TokenCheck from './components/TokenCheck';
import './Header.scss';

const Header: FC = () => {
  return (
    <Layout.Header>
      <div className="end-container">
        <TokenCheck />
      </div>
    </Layout.Header>
  );
};

export default Header;
