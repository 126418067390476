import { FC } from 'react';
import { Select } from 'antd';

const { Option } = Select;

export interface ISelectSearchButtonProps {
  label?: string;
  placeholder?: string;
  options: string[];
}

const SelectSearchButton: FC<ISelectSearchButtonProps> = ({ label, options, placeholder }) => {
  return (
    <div className="big-select">
      <label className="select-search-label" htmlFor="select-search">
        {label}
      </label>
      <div className="select-wrapper">
        <Select showSearch id="select-search" size="large" placeholder={placeholder}>
          {options.map((option) => {
            return (
              <Option key={option} value={option}>
                {option}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default SelectSearchButton;
