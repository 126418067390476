import { DebounceSearchActionType, IDebounceSelectState } from '@type/debounceSelect';
import { DebounceSearchTypes } from './debounceSelect.constants';

const initialSelectState = {
  loading: false,
  options: {},
  total: 0,
  selectedOption: null,
  selectedOptionList: {},
};
const initialState: IDebounceSelectState = {};

const DebounceSelectReducer = (
  state = initialState,
  action: DebounceSearchActionType,
): IDebounceSelectState => {
  switch (action.type) {
    case DebounceSearchTypes.DEBOUNCE_SEARCH_REQUEST:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          ...initialSelectState,
          ...(state[action.payload.name]?.selectedOptionList
            ? {
                selectedOptionList: { ...state[action.payload.name].selectedOptionList },
                total: state[action.payload.name].total,
              }
            : {}),
          loading: true,
          searchQuery: action.payload.query,
        },
      };
    case DebounceSearchTypes.DEBOUNCE_SEARCH_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          loading: false,
          options: action.payload.options,
        },
      };
    case DebounceSearchTypes.DEBOUNCE_SEARCH_FAILURE:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          loading: false,
          selectedOptionList: {},
        },
      };
    case DebounceSearchTypes.SELECT_ITEM:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          selectedOption: action.payload.option,
        },
      };
    case DebounceSearchTypes.PUSH_ITEM:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          selectedOption: null,
          options: action.payload.options,
          selectedOptionList: action.payload.selectedOptions,
        },
      };
    case DebounceSearchTypes.POP_ITEM:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          selectedOption: null,
          options: action.payload.newOptions,
          selectedOptionList: action.payload.newSelectedOptions,
        },
      };
    case DebounceSearchTypes.SET_SELECTED_OPTIONS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          selectedOptionList: action.payload.newSelectedOptions,
        },
      };
    default:
      return { ...state };
  }
};

export default DebounceSelectReducer;
