import {
  FetchIdpsRequestPayloadType,
  IFetchIdpsFailureAction,
  IFetchIdpsRequestAction,
  IFetchIdpsSuccessAction,
  FetchIdpsSuccessPayloadType,
} from '@type/idps';
import { ErrorNullType } from '@type/general';
import { IdpsTypes } from './idps.constants';

export const fetchIdpsRequestAction = (
  payload: FetchIdpsRequestPayloadType,
): IFetchIdpsRequestAction => {
  return {
    payload,
    type: IdpsTypes.FETCH_IDPS_REQUEST,
  };
};

export const fetchIdpsSuccessAction = (
  payload: FetchIdpsSuccessPayloadType,
): IFetchIdpsSuccessAction => {
  return {
    payload,
    type: IdpsTypes.FETCH_IDPS_SUCCESS,
  };
};

export const fetchIdpsErrorAction = (payload: ErrorNullType): IFetchIdpsFailureAction => {
  return {
    payload,
    type: IdpsTypes.FETCH_IDPS_FAILURE,
  };
};
