import { IGetAnalysisBehavioursUsersRequestPayload } from '@type/analysisBehaviours';
import { AnalysisPeopleOuScoresListType } from '@type/analysisPeople';
import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';

export const getAnalysisPeopleRequest = (params: IGetAnalysisBehavioursUsersRequestPayload) => {
  return apiClient.get<IResponse<AnalysisPeopleOuScoresListType>>(`/api/graphs/ou_average_scores`, {
    params,
  });
};
