import apiClient from '@utils/global/apiClient';
import { IOusModel, ISaveOuModel, RoleListType } from '@type/ous';
import { IResponse } from '@utils/interfaces/response';

export const getOusRequest = () => {
  return apiClient.get<IResponse<IOusModel[]>>(`/api/ous`);
};

export const getOuByIdRequest = (id: string) => {
  return apiClient.get<IResponse<IOusModel>>(`/api/ous/${id}/show`);
};

export const removeOuByIdRequest = (id: string) => {
  return apiClient.delete<IResponse<IOusModel>>(`/api/ous/${id}`);
};

export const saveOuByIdRequest = (id: string, data: ISaveOuModel) => {
  return apiClient.put<IResponse<IOusModel>>(`/api/ous/${id}`, data);
};

export const saveNewOuRequest = (data: ISaveOuModel) => {
  return apiClient.post<IResponse<IOusModel>>(`/api/ous/`, data);
};

export const getOusRoles = () => {
  return apiClient.get<IResponse<RoleListType>>(`/api/roles`);
};
