import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IPaginatedResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { getCoachingNotesRequest } from '@api/coachingNotes';
import { ICoachingNoteModel, IFetchCoachingNotesRequestAction } from '@type/coachingNotes';
import { fetchCoachingNotesError, fetchCoachingNotesSuccess } from '@store/actions';
import { CoachingNotesTypes } from './coachingNotes.constants';

const { errorMessage } = messages.alerts.error;

function* fetchCoachingNotesSaga({ payload }: IFetchCoachingNotesRequestAction) {
  try {
    const response: AxiosResponse<IPaginatedResponse<ICoachingNoteModel>> = yield call(
      getCoachingNotesRequest,
      payload,
    );

    yield put(
      fetchCoachingNotesSuccess({
        data: response?.data?.data,
        total: response?.data?.meta?.total?.toString(),
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(fetchCoachingNotesError(e));
  }

  yield;
}

function* fetchCoachingNotesFailureSaga() {
  yield antdMessage.error({
    content: errorMessage,
    key: 'settings-coachingNotes-message',
    duration: 3,
  });

  yield;
}

export function* coachingNotesSaga() {
  yield all([
    takeLatest(CoachingNotesTypes.FETCH_COACHING_NOTES_REQUEST, fetchCoachingNotesSaga),
    takeLatest(CoachingNotesTypes.FETCH_COACHING_NOTES_FAILURE, fetchCoachingNotesFailureSaga),
  ]);
}
