import { IEditIdpState, IEditIdpAction } from '@type/idps';
import { EditIdpTypes } from './editIdp.constants';

const initialState: IEditIdpState = {
  pending: false,
  checkPending: false,
  idp: null,
  error: null,
  idpForCheck: null,
};

const EditIdpReducer = (state = initialState, action: IEditIdpAction): IEditIdpState => {
  switch (action.type) {
    case EditIdpTypes.FETCH_IDP_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditIdpTypes.FETCH_IDP_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, idp: action.idp };
    case EditIdpTypes.FETCH_IDP_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, idp: null };

    case EditIdpTypes.REMOVE_IDP_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditIdpTypes.REMOVE_IDP_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, idp: null };
    case EditIdpTypes.REMOVE_IDP_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditIdpTypes.SAVE_IDP_REQUEST:
      return { ...state, pending: true, error: null };
    case EditIdpTypes.SAVE_IDP_SUCCESS:
      return { ...state, pending: false, error: null, idp: null };
    case EditIdpTypes.SAVE_IDP_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditIdpTypes.CHECK_IDP_FOR_USER_REQUEST:
      return { ...state, checkPending: true };
    case EditIdpTypes.CHECK_IDP_FOR_USER_SUCCESS:
      return { ...state, checkPending: false, idpForCheck: action.idp };
    case EditIdpTypes.CHECK_IDP_FOR_USER_FAILURE:
      return { ...state, checkPending: false, idpForCheck: null };

    default:
      return { ...state };
  }
};

export default EditIdpReducer;
