import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Form, Modal, Row, Button, Col, Input, message as antdMessage } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons';
import { getOus } from '@api/general';
import { OuRoleSelect, LinkButton } from '@components';
import Fallback from '@router/Fallback';
import {
  fetchUserByIdRequestAction,
  debounceSelectSearchAction,
  debounceSetSelectedOptionList,
  ouRoleSelectPushItemAction,
  removeUserByIdAction,
  saveUserAction,
  saveNewUserAction,
  clearDebounceCustomSelectAction,
  forgotPasswordRequestAction,
} from '@store/actions';
import InputLayout from '@layout/InputLayout/InputLayout';
import { getUserOusRolesOptions } from '@pages/Settings/Users/EditUserPage/helpers';
import {
  getSelectedOptionsList,
  validateEmail,
  validateOuRoles,
} from '@pages/Settings/Users/helpers';

import { IParams, StateType } from '@type/general';
import { OuRoleSelectedOptionsType } from '@type/ouRoleSelect';
import { IUserOusModel, IEditUserModel, INewUserModel, UserModelNullType } from '@type/users';
import { messages } from '@utils';
import { IFormProps } from '@utils/interfaces/formInterfaces';
import { USER_PASSWORD_LENGTH } from '@utils/global/configs';
import { validatePassword } from '@utils/global/helpers';
import { ILoginSuccessPayloadType } from '@type/auth';
import HeaderRow from './UserPageHeaderRow';

interface ISelectedState {
  user: UserModelNullType;
  pending: boolean;
  currentUserEdit?: boolean | undefined;
}

export type AuthSelectorType = Omit<ILoginSuccessPayloadType, 'firstName' | 'lastName'>;

const { settingsUsersPageTitle } = messages.pagesTitles;
const { editUserPage, newUserPage } = messages.settings.users;
const { passwordInput, ousInput: newOusInput } = newUserPage.formInputs;
const { formInputs, bottomButtons, confirmModal, errorMsgs } = editUserPage;
const {
  firstNameInput,
  lastNameInput,
  emailInput,
  jobDescriptionInput,
  ousInput: editOusInput,
} = formInputs;
const { cancel, save } = bottomButtons;
const { modalTitle, modalContent } = confirmModal;
const { modalOk, modalCancel } = confirmModal.buttons;

const UserForm: FC<IFormProps> = ({ isEditPage }) => {
  const ousInput = isEditPage ? editOusInput : newOusInput;

  const dispatch = useDispatch();

  const { id } = useParams<IParams>();
  const [form] = Form.useForm();

  const selectedOptionsList = useSelector<StateType, OuRoleSelectedOptionsType>(
    (state) => state?.components?.ouRoleSelect?.selectedOptionsList,
  );

  const { user, pending, currentUserEdit } = useSelector<StateType, ISelectedState>((state) => {
    if (!isEditPage) {
      return {
        user: null,
        pending: false,
      };
    }

    const editUserPageData = state?.settings.users?.editUserPage;
    const currentUserId = state.auth.id;

    if (editUserPageData && currentUserId && editUserPageData.user?.id === currentUserId) {
      return {
        ...editUserPageData,
        currentUserEdit: true,
      };
    }

    return state?.settings.users?.editUserPage;
  });

  const userData = useSelector<StateType, AuthSelectorType>((state) => {
    return {
      token: state.auth.token,
      jobDescription: state.auth.jobDescription,
      companyName: state.auth.companyName,
    };
  });

  useEffect(() => {
    if (isEditPage && user) {
      const { firstName, lastName, email, jobDescription, ous } = user;

      form.setFieldsValue({
        [firstNameInput.name]: firstName,
        [lastNameInput.name]: lastName,
        [emailInput.name]: email,
        [jobDescriptionInput.name]: jobDescription,
      });

      fillOusRolesSelect(ous);
    }
  }, [user]);

  useEffect(() => {
    form.setFieldsValue({
      [ousInput.name]: '',
    });
    form.setFieldsValue({
      [ousInput.name]: { ...selectedOptionsList },
    });
  }, [selectedOptionsList]);

  useEffect(() => {
    dispatch(
      clearDebounceCustomSelectAction({
        name: ousInput.name,
        fetchFunction: getOus,
        combinedSelect: true,
      }),
    );
    dispatch(
      debounceSelectSearchAction({
        name: ousInput.name,
        query: '',
        fetchFunction: getOus,
      }),
    );

    if (isEditPage) {
      dispatch(fetchUserByIdRequestAction(id));
    }
  }, [id]);

  const fillOusRolesSelect = (ous: Array<IUserOusModel>) => {
    dispatch(
      debounceSetSelectedOptionList({
        name: ousInput.name,
        newSelectedOptions: getUserOusRolesOptions(ous),
      }),
    );

    ous.forEach((ou) => {
      dispatch(
        ouRoleSelectPushItemAction({
          ou: { label: ou.name, value: ou.id.toString() },
          role: {
            label: ou.roleName,
            value: ou.pivot.role_id.toString(),
          },
        }),
      );
    });
  };

  const handleRemoveUser = () => {
    dispatch(removeUserByIdAction(id));
  };

  const handleForgotPasswordBtnClick = () => {
    if (user) {
      dispatch(forgotPasswordRequestAction(user.email));
    } else {
      antdMessage.error({
        content: errorMsgs.noUserSelected,
        key: 'settings-user-form-error-message',
        duration: 3,
      });
    }
  };

  const handleSaveBtnClick = () => {
    const {
      [ousInput.name]: userPageOus,
      [passwordInput.name]: password,
      ...formValues
    } = form.getFieldsValue();

    const inputsObj: IEditUserModel = {
      ...formValues,
      ouRoles: currentUserEdit ? null : getSelectedOptionsList(userPageOus),
    };

    if (isEditPage) {
      dispatch(saveUserAction(id, inputsObj, currentUserEdit || false, userData));

      return;
    }

    const saveObj: INewUserModel = {
      ...inputsObj,
      password,
    };

    dispatch(saveNewUserAction(saveObj));
  };

  const confirm = () => {
    Modal.confirm({
      centered: true,
      title: modalTitle,
      icon: <UserDeleteOutlined />,
      content: modalContent,
      okText: modalOk,
      okType: 'danger',
      cancelText: modalCancel,
      onOk: handleRemoveUser,
      getContainer: false,
    });
  };

  if (isEditPage && !user && pending) {
    return <Fallback />;
  }

  return (
    <div className="settings-user-form">
      <Form form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <h1>{settingsUsersPageTitle}</h1>
        <div className="main-content">
          <HeaderRow
            isEditPage={isEditPage}
            confirm={confirm}
            forgotPassword={handleForgotPasswordBtnClick}
          />
          <Row>
            <Col span={14}>
              <Form.Item
                name={firstNameInput.name}
                label={firstNameInput.label}
                rules={[
                  {
                    required: true,
                    message: firstNameInput.message,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={lastNameInput.name}
                label={lastNameInput.label}
                rules={[
                  {
                    required: true,
                    message: lastNameInput.message,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={emailInput.name}
                label={emailInput.label}
                rules={[
                  {
                    type: 'email',
                    message: emailInput.message.correct,
                  },
                  {
                    required: true,
                    message: emailInput.message.empty,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {!isEditPage && (
                <Form.Item
                  name={passwordInput.name}
                  label={passwordInput.label}
                  rules={[
                    {
                      required: true,
                      message: passwordInput.message.empty,
                    },
                    {
                      min: USER_PASSWORD_LENGTH,
                      message: passwordInput.message.correct,
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              )}
              <Form.Item
                name={jobDescriptionInput.name}
                label={jobDescriptionInput.label}
                rules={[
                  {
                    required: true,
                    message: jobDescriptionInput.message,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {!currentUserEdit ? (
                <>
                  <Form.Item name={ousInput.name} hidden />
                  <InputLayout required inputName={ousInput.name} label={ousInput.label}>
                    <OuRoleSelect name={ousInput.name} />
                  </InputLayout>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={18} />
            <Col id="buttons-wrapper" span={6}>
              <div className="buttons">
                <LinkButton to="/settings/users" className="full-width-btn" name={cancel} />
                <Form.Item shouldUpdate className="submit" wrapperCol={{ span: 24 }}>
                  {({ getFieldsValue }) => {
                    const {
                      firstName,
                      lastName,
                      jobDescription,
                      email,
                      [ousInput.name]: userPageOus,
                      password = '',
                    } = getFieldsValue();

                    const validation = Boolean(
                      firstName &&
                        lastName &&
                        jobDescription &&
                        validateEmail(email) &&
                        (currentUserEdit || validateOuRoles(userPageOus)) &&
                        (isEditPage || validatePassword(password)),
                    );

                    return (
                      <Button
                        className="full-width-btn"
                        type="primary"
                        onClick={handleSaveBtnClick}
                        disabled={!validation}
                      >
                        {save}
                      </Button>
                    );
                  }}
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
