import { INewIdpState, INewIdpAction } from '@type/idps';
import { NewIdpTypes } from './newIdp.constants';

const initialState: INewIdpState = {
  pending: false,
  error: null,
};

const NewIdpReducer = (state = initialState, action: INewIdpAction): INewIdpState => {
  switch (action.type) {
    case NewIdpTypes.SAVE_NEW_IDP_REQUEST:
      return { ...state, pending: true, error: null };
    case NewIdpTypes.SAVE_NEW_IDP_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewIdpTypes.SAVE_NEW_IDP_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error };
    default:
      return { ...state };
  }
};

export default NewIdpReducer;
