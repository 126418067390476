import { USER_PASSWORD_LENGTH } from './configs';
import { getInputCorrectPlaceholder, getInputPlaceholder, getSearchPlaceholder } from './helpers';

export const messages = {
  // GLOBAL
  pagesTitles: {
    dashboardPageTitle: 'Dashboard',
    idpPageTitle: 'Individual Development Plan',
    newIDPPageTitle: 'New Development Plan',
    editIDPPageTitle: 'Edit Development Plan',
    plansNewCoachingNote: 'New Coaching Note',
    plansCoachingNotes: 'Coaching Notes',
    settingsPageTitle: 'Settings - Overview',
    settingsUsersPageTitle: 'Settings - Users',
    settingsHelpCenterPageTitle: 'Settings - Help Center',
    settingsBehavioursPageTitle: 'Settings - Behaviours',
    settingsOusPageTitle: 'Settings - Organizational Units',
    settingsCompaniesPageTitle: 'Settings - Companies',
    helpCenter: 'Help Center',
    analysisUserOverview: 'Analysis - Users Overview',
    behaviourTitle: 'Behaviour',
  },

  alerts: {
    loading: {
      loadingMessage: 'Loading...',
      isEmpty: 'The List Is Empty !',
    },

    error: {
      errorMessage: 'Error !',
      removeErrorMessage: 'Removing Failed !',
      networkErrorMessage: 'Network error !',
      saveErrorMessage: 'Save Failed !',
      removeUserErrorMessage: 'Remove User Failed !',
      saveUserErrorMessage: 'Save User Failed !',
      removeOuErrorMessage: 'Remove Organizational Unit Failed !',
      removeBehaviourErrorMessage: 'Remove Behaviour Failed !',
      saveOuErrorMessage: 'Save Organizational Unit Failed !',
      saveBehaviourErrorMessage: 'Save Behaviour Failed !',
      exportAllFailureMessage: 'Export Failed !',
      newUserFailedMessage: 'Error! User was not created',
      removeFaqErrorMessage: 'Remove FAQ Failed !',
      saveFaqErrorMessage: 'Save FAQ Failed !',
      saveCompanyErrorMessage: 'Save Company Failed !',
      removeCompanyErrorMessage: 'Remove Company Failed !',
      saveCoachingNoteErrorMessage: 'Save Coaching Note Failed !',
      removeCoachingNoteErrorMessage: 'Remove Coaching Note Failed !',
      fetchCoachingNoteByIdErrorMessage: 'Fetch Failed !',
      saveNewAssessmentErrorMessage: 'Save Changes of New Assessment Failed !',
      submitNewAssessmentErrorMessage: 'Submit New Quarterly Scoring Failed !',
      getLatestIdpErrorMsg: 'Get Latest Idp Error !',
      getScatterPlotDataErrorMsg: 'Get Scatter Plot Data Error !',
      getMiniGraphsDataErrorMsg: 'Get Mini Graphs Data Error !',
      thisEmailIsNotCorrect: "We can't find a user with that email address",
      getBehavioursPageUsersMsg: 'Get Users Failure !',
    },

    success: {
      successMessage: 'Success !',
      sendSuccessMessage: 'Your message has been sent',
      removeSuccessMessage: 'Remove Successful !',
      editSuccessMessage: 'Edit Successful !',
      saveSuccessMessage: 'Save Was Successful !',
      saveUserSuccessMessage: 'User Saved Successfully !',
      removeUserSuccessMessage: 'User Removed Successfully !',
      newUserSuccessMessage: 'User created successful !',
      saveOuSuccessMessage: 'Organizational Unit Saved Successfully !',
      saveBehaviourSuccessMessage: 'Behaviour Saved Successfully !',
      newOuSuccessMessage: 'Organizational Unit created successful !',
      newBehaviourSuccessMessage: 'Behaviour created successful !',
      removeOuSuccessMessage: 'Organizational Unit Removed Successfully !',
      removeBehaviourSuccessMessage: 'Behaviour Removed Successfully !',
      loginUserSuccessMessage: 'User Logged In !',
      logoutUserSuccessMessage: 'Logout is successful !',
      exportAllSuccessMessage: 'Export is successful !',
      removeFaqSuccessMessage: 'FAQ Removed Successfully !',
      saveFaqSuccessMessage: 'FAQ created successful !',
      saveCompanySuccessMessage: 'Company created successfully !',
      editCompanySuccessMessage: 'Company updated successfully !',
      removeCompanySuccessMessage: 'Company removed successfully !',
      saveCoachingNoteSuccessMessage: 'Coaching note created successfully !',
      removeCoachingNoteSuccessMessage: 'Coaching note removed successfully !',
      editCoachingNoteSuccessMessage: 'Coaching note updated successfully !',
      saveNewAssessmentSuccessMessage: 'Save changes of new assessment was successfully !',
      submitNewAssessmentSuccessMessage: 'Submit new quarterly scoring  was successful !',
    },
  },

  header: {
    loginMsg: 'Login',
    logoutMsg: 'Log out',
    companyMsg: 'Company:',
  },

  buttons: {
    editButton: 'Edit',
    cancelBtnMsg: 'Cancel',
    saveBtnMsg: 'Save',
    sendBtnMsg: 'Send',
    removeBtnMsg: 'Remove',
    viewButtonMsg: 'View',
  },

  components: {
    buttonsBlock: {
      error: {
        behavioursListIsEmptyMsg: 'Behaviours List is Empty !',
      },
    },
    coachingNotePlanCard: {
      coachingNoteTitle: 'Coaching Note',
    },
    idpPlanCard: {
      idpTitle: 'Individual Development Plan',
      bodySectionsHeaders: {
        keyDevelopmentAreaMsg: 'Key Development Area',
        diagnosisMsg: 'Diagnosis',
        smartGoalMsg: 'SmartGoal',
        coachingActionMsg: 'Coaching Action',
      },
    },
    customDebounceSelect: {
      emptyOptionListMessage: 'No Results Found',
    },
    userCard: {
      titles: {
        nameTitle: 'Name',
        teamTitle: 'Team',
      },
    },
    subordinateSelect: {
      name: 'subordinateSelect',
      message: 'please select subordinate',
    },

    companySelect: {
      companySelectName: 'companySelect',
    },

    timeFrameSelect: {
      name: 'timeFrameSelect',
      message: 'please select timeframe',
    },
  },
  dashboardPage: {
    welcome: 'Welcome,',
  },
  loginPage: {
    title: 'Login - OPTICOACH',
    form: {
      emailInput: {
        label: 'E-mail',
        name: 'email',
        placeholder: getInputCorrectPlaceholder('email'),
      },
      passwordInput: {
        label: 'Password',
        name: 'password',
        placeholder: getInputCorrectPlaceholder('password'),
      },
      rememberMe: 'Remember me',
    },
    buttons: {
      forgotPasswordBtn: 'Forgot Password',
      loginBtn: 'Login',
    },
  },

  analysisBehavioursOverallPage: {
    labels: {
      people: 'People',
      aspect: 'Aspect',
      behaviours: 'Behaviours',
    },
    tableTitles: {
      firstName: 'FIRST NAME',
      lastName: 'LAST NAME',
      picture: 'PICTURE',
      cmScore: 'CM SCORE',
      cpScore: 'CP SCORE',
      total: 'TOTAL',
      assess: 'ASSESS',
      view: 'VIEW',
    },
  },

  behaviourOverview: {
    commitment: 'Histograph - Commitment',
    competence: 'Histograph - Competence',
  },
  analysisPeople: {
    title: 'Analysis - Behaviours',
    ouSelectLabel: 'Select a Team',
    scatterPlot: {
      quarterSelects: {
        fromSelectName: 'timeFrameFromSelect',
        toSelectName: 'timeToFromSelect',
      },
      scatterTitleMsg: 'Team - Behaviour Average',
    },

    table: {
      tableTitles: {
        behaviourTitle: 'Behaviour',
        cmScore: 'CM SCORE',
        cpScore: 'CP SCORE',
        total: 'TOTAL',
      },
    },
  },

  settings: {
    settingsPage: {
      currentUser: 'Edit Current User',
      users: 'Users',
      helpCenter: 'Help Center / FAQ',
      behaviours: 'Behaviours',
      organizationalUnits: 'Organizational Units / Teams',
      companies: 'Companies',
    },

    users: {
      usersPage: {
        subtitle: 'Users',
        searchPlaceholder: 'Start typing to search for users...',
        tableTitles: {
          firstName: 'FIRST NAME',
          lastName: 'LAST NAME',
          picture: 'PICTURE',
          ou: 'OU',
          jobDescription: 'JOB DESCRIPTION',
          edit: 'EDIT',
        },
        buttons: {
          newUser: 'New User',
          exportAll: 'Export All',
        },
      },

      newUserPage: {
        subtitle: 'New User',
        formInputs: {
          firstNameInput: {
            label: 'First Name',
            name: 'first-name',
            message: getInputPlaceholder('first name'),
          },
          lastNameInput: {
            label: 'Last Name',
            name: 'last-name',
            message: getInputPlaceholder('last name'),
          },
          emailInput: {
            label: 'Email',
            name: 'email',
            message: {
              correct: getInputCorrectPlaceholder('email'),
              empty: getInputPlaceholder('email'),
            },
          },
          passwordInput: {
            label: 'Password',
            name: 'password',
            message: {
              correct: getInputCorrectPlaceholder(
                `password: length should be more than ${USER_PASSWORD_LENGTH} symbols`,
              ),
              empty: getInputPlaceholder('password'),
            },
          },
          jobDescriptionInput: {
            label: 'Job Description',
            name: 'job-description',
            message: getInputPlaceholder('job description'),
          },
          ousInput: {
            label: 'Organizational Unit',
            name: 'userPageOus',
            placeholder: getSearchPlaceholder('organizational units'),
          },
        },
        bottomButtons: {
          cancel: 'Cancel',
          save: 'Save',
        },
      },

      editUserPage: {
        subtitle: 'Edit User',
        errorMsgs: {
          noUserSelected: 'Error: no user selected !',
        },
        formInputs: {
          firstNameInput: {
            label: 'First Name',
            name: 'firstName',
            message: getInputPlaceholder('first name'),
          },
          lastNameInput: {
            label: 'Last Name',
            name: 'lastName',
            message: getInputPlaceholder('last name'),
          },
          emailInput: {
            label: 'Email',
            name: 'email',
            message: {
              correct: getInputCorrectPlaceholder('email'),
              empty: getInputPlaceholder('email'),
            },
          },
          passwordInput: {
            label: 'Password',
            name: 'password',
            message: {
              correct: getInputCorrectPlaceholder(
                `password: length should be more than ${USER_PASSWORD_LENGTH} symbols`,
              ),
              empty: getInputPlaceholder('password'),
            },
          },
          jobDescriptionInput: {
            label: 'Job Description',
            name: 'jobDescription',
            message: getInputPlaceholder('job description'),
          },
          ousInput: {
            label: 'Organizational Unit',
            name: 'userPageOus',
            placeholder: getSearchPlaceholder('organizational units'),
          },
        },
        topButtons: {
          resetPassword: 'Reset Password',
          remove: 'Remove',
        },
        bottomButtons: {
          cancel: 'Cancel',
          save: 'Save',
        },
        confirmModal: {
          modalTitle: 'Remove user',
          modalContent: 'Are you sure?',
          buttons: {
            modalCancel: 'Cancel',
            modalOk: 'Ok',
          },
        },
      },
    },

    ous: {
      ousPage: {
        subtitle: 'Organizational Units',
        newOUButton: 'New OU',
        tableTitles: {
          name: 'Name',
          administrators: 'Administrators',
          edit: 'EDIT',
        },
      },
      newOuPage: {
        subtitle: 'New OU',
        buttons: {
          removeBtn: 'Remove',
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          nameInput: {
            label: 'Name',
            name: 'newOuPageFirstName',
            message: getInputPlaceholder('name'),
          },
          administratorsInput: {
            label: 'Leader',
            name: 'newOuPageLeaderId',
            message: getInputPlaceholder('leader for this OU'),
          },
          usersInput: {
            label: 'Users',
            name: 'newOuPageUsers',
            message: getInputPlaceholder('users'),
          },
          behavioursInput: {
            label: 'Behaviours',
            name: 'newOuPageBehaviours',
            message: getInputPlaceholder('behaviours'),
          },
        },
      },
      editOuPage: {
        subtitle: 'Edit OU',
        buttons: {
          removeBtn: 'Remove',
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          nameInput: {
            label: 'Name',
            name: 'editOuPageFirstName',
            message: getInputPlaceholder('name'),
          },
          administratorsInput: {
            label: 'Leader',
            name: 'editOuPageLeaderId',
            message: getInputPlaceholder('leader for this OU'),
          },
          usersInput: {
            label: 'Users',
            name: 'editOuPageUsers',
            message: getInputPlaceholder('users'),
          },
          behavioursInput: {
            label: 'Behaviours',
            name: 'editOuPageBehaviours',
            message: getInputPlaceholder('behaviours'),
          },
        },
        confirmModal: {
          modalTitle: 'Remove OU',
          modalContent: 'Are you sure?',
          buttons: {
            modalCancel: 'Cancel',
            modalOk: 'Ok',
          },
        },
      },
    },

    behaviours: {
      behavioursPage: {
        subtitle: 'Behaviours',
        newBehaviourButton: 'New Behaviour',
        tableTitles: {
          name: 'Name',
          teams: 'Teams',
          edit: 'EDIT',
        },
      },
      newBehavioursPage: {
        subtitle: 'New Behaviour',
        buttons: {
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          nameInput: {
            label: 'Name',
            name: 'newBehaviourPageName',
            message: getInputPlaceholder('behaviour name'),
          },
          ousInput: {
            label: 'Organizational Units',
            name: 'newBehaviourPageOus',
            messages: {
              requiredMsg: getInputPlaceholder('ous'),
            },
          },
        },
      },
      editBehavioursPage: {
        subtitle: 'Edit Behaviour',
        buttons: {
          removeBtn: 'Remove',
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          nameInput: {
            label: 'Name',
            name: 'editBehaviourPageName',
            message: getInputPlaceholder('behaviour name'),
          },
          ousInput: {
            label: 'Organizational Units',
            name: 'editBehaviourPageOus',
            messages: {
              requiredMsg: getInputPlaceholder('ous'),
            },
          },
        },
        confirmModal: {
          modalTitle: 'Remove Behaviour',
          modalContent: 'Are you sure?',
          buttons: {
            modalCancel: 'Cancel',
            modalOk: 'Ok',
          },
        },
      },
    },

    helpCenter: {
      helpCenterPage: {
        subtitle: 'Questions',
        newQuestionButton: 'New Question',
        tableTitles: {
          question: 'Question',
          answer: 'Answer',
          edit: 'EDIT',
        },
      },
      newQuestionPage: {
        subtitle: 'New Question',
        buttons: {
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          formName: 'new-question',
          questionInput: {
            label: 'Question',
            name: 'new-question',
            message: getInputPlaceholder('question'),
          },
          answerInput: {
            label: 'Answer',
            name: 'answer',
            message: getInputPlaceholder('answer'),
          },
        },
      },
      editQuestionPage: {
        subtitle: 'Edit Question',
        buttons: {
          removeBtn: 'Remove',
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          formName: 'edit-question',
          questionInput: {
            label: 'Question',
            name: 'newQuestion',
            message: getInputPlaceholder('question'),
          },
          answerInput: {
            label: 'Answer',
            name: 'answer',
            message: getInputPlaceholder('answer'),
          },
        },
        confirmModal: {
          modalTitle: 'Remove Question',
          modalContent: 'Are you sure?',
          buttons: {
            modalCancel: 'Cancel',
            modalOk: 'Ok',
          },
        },
      },
    },

    companies: {
      companiesPage: {
        subtitle: 'Companies',
        newCompanyButton: 'New Company',
        tableTitles: {
          name: 'Name',
          edit: 'EDIT',
        },
      },
      newCompaniesPage: {
        subtitle: 'New Company',
        buttons: {
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
        },
        formInputs: {
          formName: 'new-company',
          nameInput: {
            label: 'Name',
            name: 'newCompanyPageName',
            message: getInputPlaceholder('name'),
          },
        },
      },
      editCompaniesPage: {
        subtitle: 'Edit Company',
        buttons: {
          cancelBtn: 'Cancel',
          saveBtn: 'Save',
          removeBtn: 'Remove',
        },
        formInputs: {
          formName: 'edit-company',
          nameInput: {
            label: 'Name',
            name: 'editCompanyPageName',
            message: getInputPlaceholder('name'),
          },
          administratorInput: {
            label: 'Administrator',
          },
        },
        confirmModal: {
          modalTitle: 'Remove Company',
          modalContent: 'Are you sure?',
          buttons: {
            modalCancel: 'Cancel',
            modalOk: 'Ok',
          },
        },
      },
    },
  },

  helpCenter: {
    FAQ: 'FAQ',
    Support: 'Support',
    formFields: {
      name: 'Name',
      email: 'Email',
      message: 'Message',
    },
  },

  plans: {
    coachingNotes: {
      selectPlaceholderMsg: 'Choose User...',
      newCoachingNoteBtnMsg: 'New Coaching Note',
      newCoachingNote: {
        title: 'New Coaching Note',
        formInputs: {
          noteTextArea: {
            name: 'newCNTextArea',
            helperText: 'Helper Text',
            textAreaPlaceholderMsg: 'Type here your note...',
          },
        },
      },

      editCoachingNote: {
        title: 'Edit Coaching Note',
        formInputs: {
          noteTextArea: {
            name: 'editCNTextArea',
            helperText: 'Helper Text',
            textAreaPlaceholderMsg: 'Type here your note...',
          },
        },
      },
    },

    IDP: {
      newDevelopmentPlanBtnMsg: 'New Development Plan',
      newIDP: {
        subtitle: 'You are creating a new plan for ',
        editSubtitle: 'You are editing the plan for ',
        hasIdp: {
          isСreated: 'The IDP has already been created',
          isEditable: 'You can change it by clicking on the button',
          isSubmitted: 'You cannot change this item',
        },
        formInputs: {
          keyDevelopmentAreaInput: {
            name: 'newIDPkeyDevelopmentAreaInput',
            label: 'Key Development Area',
            placeholder: 'Type Key Development Area ...',
            message: getInputPlaceholder('key development area'),
          },
          diagnosisInput: {
            name: 'newIDPDiagnosisInput',
            label: 'Diagnosis',
            placeholder: 'Type Diagnosis ...',
            message: getInputPlaceholder('diagnosis'),
          },
          smartGoalInput: {
            name: 'newSmartGoalInput',
            label: 'Smart Goal',
            placeholder: 'Type Smart Goal ...',
            message: getInputPlaceholder('smart goal'),
          },
          coachingActionInput: {
            name: 'newCoachingActionInput',
            label: 'Coaching Action',
            placeholder: 'Type Coaching Action ...',
            message: getInputPlaceholder('coaching action'),
          },
        },
        buttons: {
          cancelBtnMsg: 'Cancel',
          saveBtnMsg: 'Save Changes',
          submitPlanBtnMsg: 'Submit Plan',
        },
      },
    },
  },

  analysis: {
    newAssessment: {
      title: 'Analysis - New Assessment',
      subtitle: "You're creating a new assessment for ",
      topSelects: {
        teamSelectName: 'analysisNewAssessmentTeamSelect',
        userSelectName: 'analysisNewAssessmentUserSelect',
      },
      formInputs: {
        titles: {
          commitment: 'Commitment',
          competence: 'Competence',
        },
      },
      buttons: {
        cancelBtnMsg: 'Cancel',
        saveChangesBtnMsg: 'Save Changes',
        submitNewQuarterlyScoring: 'Submit New Quarterly Scoring',
      },
    },

    behavioursPageMsgs: {
      ouSelectLabel: 'People',
      aspectSelectLabel: 'Aspect',
      behavioursSelectLabel: 'Behaviours',
      quarterSelects: {
        fromSelectName: 'timeFrameFromSelect',
        toSelectName: 'timeToFromSelect',
      },
    },

    userDetail: {
      title: 'Analysis - User Detail',
      planCardTitle: 'Latest Individual Development Plan',
      buttons: {
        viewCoachingNotesBtnMsg: 'View Coaching Notes',
        viewDevelopmentPlansBtnMsg: 'View Development Plans',
        newAssessmentBtnMsg: 'New Assessment',
      },
      scatterPlotMsgs: {
        scatterPlotTitle: 'Sales {name} - Behaviours Scores - Scatter plot',
        behaviourMsg: 'BEHAVIOUR',
        commitmentMsg: 'COMMITMENT',
        competenceMsg: 'COMPETENCE',
        totalMsg: 'TOTAL',
        viewMsg: 'VIEW',
      },
      table: {
        quarterSelects: {
          fromSelectName: 'timeFrameFromSelect',
          toSelectName: 'timeToFromSelect',
        },
        tableTitles: {
          behaviourTitle: 'BEHAVIOUR',
          commitmentTitle: 'COMMITMENT',
          competenceTitle: 'COMPETENCE',
          totalTitle: 'TOTAL',
          viewTitle: 'VIEW',
        },
      },

      miniGraphsMsgs: {
        showDetails: 'Show Details',
        lastCommitmentScoreMsg: 'Last Commitment Score',
        lastCompetenceScoreMsg: 'Last Competence Score',
        lastTotalScoreMsg: 'Last Total Score',
      },
    },
  },

  forgotPassword: {
    forgotPassword: {
      title: 'Forgot Password',
      formInputs: {
        email: {
          name: 'forgot-password-new-password-input',
          label: 'Email',
          messages: {
            correctMsg: 'This email is invalid !',
            emptyMsg: getInputPlaceholder('email'),
          },
        },
      },
      buttons: {
        sendLinkBtnMessage: 'Send Link',
      },
    },
    resetPassword: {
      title: 'Reset Password',
      errors: {
        invalidLink: 'Error: Invalid Link was provided !',
      },
      formInputs: {
        newPassword: {
          name: 'reset-password-new-password-input',
          label: 'New Password',
          messages: {
            correctMsg: `length should be more than ${USER_PASSWORD_LENGTH} symbols`,
            emptyMsg: getInputPlaceholder('new password'),
          },
        },
        newPasswordConfirm: {
          name: 'reset-password-new-password-confirm-input',
          label: 'Confirm New Password',
          messages: {
            correctMsg: `Please confirm your password!`,
            emptyMsg: getInputPlaceholder('confirm new password'),
            confirmError: 'Passwords entered do not match!',
          },
        },
      },
      buttons: {
        updateBtnMessage: 'Update',
      },
    },
  },
} as const;
