import { Suspense, FC, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { renderRoutes, IRoutesProps } from '@utils';
import SettingsEditUserPage from '@pages/Settings/Users/EditUserPage';
import { StateType } from '@type/general';
import Layout from '../Layout';
import Fallback from './Fallback';
import ScrollToTop from './ScrollToTop';
import Redirect from './Redirect';

const DashboardPage = lazy(() => {
  return import('@pages/DashboardPage');
});
const BehavioursPage = lazy(() => {
  return import('@pages/Analysis/Teams');
});
const SettingsPage = lazy(() => {
  return import('@pages/Settings/SettingsPage');
});
const SettingsUsersPage = lazy(() => {
  return import('@pages/Settings/Users/UsersPage');
});
const SettingsNewUserPage = lazy(() => {
  return import('@pages/Settings/Users/NewUserPage');
});
const SettingsHelpCenterPage = lazy(() => {
  return import('@pages/Settings/HelpCenter/HelpCenterPage');
});
const SettingsNewQuestionPage = lazy(() => {
  return import('@pages/Settings/HelpCenter/NewQuestionPage');
});
const SettingsEditQuestionPage = lazy(() => {
  return import('@pages/Settings/HelpCenter/EditQuestionPage');
});
const SettingsBehavioursPage = lazy(() => {
  return import('@pages/Settings/Behaviours/SettingsBehavioursPage');
});
const SettingsNewBehaviourPage = lazy(() => {
  return import('@pages/Settings/Behaviours/NewBehaviourPage');
});
const SettingsEditBehaviourPage = lazy(() => {
  return import('@pages/Settings/Behaviours/EditBehavioursPage/EditBehavioursPage');
});
const SettingsOusPage = lazy(() => {
  return import('@pages/Settings/OrganisationalUnits/OusPage');
});
const SettingsNewOusPage = lazy(() => {
  return import('@pages/Settings/OrganisationalUnits/NewOuPage');
});
const SettingsEditOusPage = lazy(() => {
  return import('@pages/Settings/OrganisationalUnits/EditOuPage');
});
const SettingsCompaniesPage = lazy(() => {
  return import('@pages/Settings/Companies/CompaniesPage');
});
const SettingsNewCompanyPage = lazy(() => {
  return import('@pages/Settings/Companies/NewCompanyPage');
});
const SettingsEditCompanyPage = lazy(() => {
  return import('@pages/Settings/Companies/EditCompanyPage');
});
const LoginPage = lazy(() => {
  return import('@pages/LoginPage');
});
const CoachingNotes = lazy(() => {
  return import('@pages/Plans/CoachingNotes/CoachingNotesPage/CoachingNotesPage');
});
const EditCoachingNote = lazy(() => {
  return import('@pages/Plans/CoachingNotes/EditCoachingNotePage/EditCoachingNotePage');
});
const NewCoachingNote = lazy(() => {
  return import('@pages/Plans/CoachingNotes/NewCoachingNotePage/NewCoachingNotePage');
});
const NewIdpPage = lazy(() => {
  return import('@pages/Plans/IDP/NewIdpPage/NewIdpPage');
});
const EditIdpPage = lazy(() => {
  return import('@pages/Plans/IDP/EditIdpPage/EditIdpPage');
});
const PlansIdpPage = lazy(() => {
  return import('@pages/Plans/IDP/IdpPage/IdpPage');
});
const ResetPassword = lazy(() => {
  return import('@pages/ResetPassword/ResetPassword');
});
const ForgotPassword = lazy(() => {
  return import('@pages/ForgotPassword/ForgotPassword');
});
const AnalysisNewAssessment = lazy(() => {
  return import('@pages/Analysis/NewAssessment/NewAssessment');
});
const AnalysisUserOverview = lazy(() => {
  return import('@pages/Analysis/UserOverview/UserOverview');
});
const AnalysisUserDetail = lazy(() => {
  return import('@pages/Analysis/UserDetail/UserDetail');
});
const AnalysisUserBehavour = lazy(() => {
  return import('@pages/Analysis/UserBehaviour/UserBehaviour');
});
const AnalysisPeople = lazy(() => {
  return import('@pages/Analysis/Behaviours');
});
const HelpCenter = lazy(() => {
  return import('@pages/HelpCenter');
});

export const LOGIN_PATH = '/login';

export const routes: IRoutesProps[] = [
  {
    name: 'DashboardPage',
    path: '/',
    Component: DashboardPage,
    exact: true,
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    Component: ForgotPassword,
  },
  {
    name: 'BehavioursPage',
    path: '/analysis/behaviours',
    Component: BehavioursPage,
  },
  {
    name: 'AnalysisPeople',
    path: '/analysis/people',
    Component: AnalysisPeople,
  },
  {
    name: 'AnalysisNewAssessment',
    path: '/analysis/new-assesment/:id?/:ouId?',
    Component: AnalysisNewAssessment,
  },
  {
    name: 'AnalysisUserOverview',
    path: '/analysis/users-overview',
    Component: AnalysisUserOverview,
    exact: true,
  },
  {
    name: 'AnalysisUserDetail',
    path: '/analysis/users-overview/user-detail/:id',
    Component: AnalysisUserDetail,
  },
  {
    name: 'AnalysisUserBehaviour',
    path: '/analysis/users-overview/user-behaviour/:id/:behaviourId',
    Component: AnalysisUserBehavour,
  },
  {
    name: 'EditIdpPage',
    path: '/plans/edit-individual-development-plan/:ouId/:id',
    Component: EditIdpPage,
  },
  {
    name: 'NewIDP',
    path: '/plans/new-individual-development-plan',
    Component: NewIdpPage,
  },
  {
    name: 'PlansIdpPage',
    path: '/plans/individual-development-plans/:id?',
    Component: PlansIdpPage,
  },
  {
    name: 'CoachingNotes',
    path: '/plans/coaching-notes/:id?',
    Component: CoachingNotes,
  },
  {
    name: 'EditCoachingNote',
    path: '/plans/edit-coaching-note/:id',
    Component: EditCoachingNote,
  },
  {
    name: 'NewCoachingNote',
    path: '/plans/new-coaching-note',
    Component: NewCoachingNote,
  },
  {
    name: 'HelpCenter',
    path: '/help-center',
    Component: HelpCenter,
  },
  {
    name: 'SettingsPage',
    path: '/settings',
    Component: SettingsPage,
    exact: true,
  },
  {
    name: 'SettingsEditUserPage',
    path: '/settings/users/edit-user/:id',
    Component: SettingsEditUserPage,
  },
  {
    name: 'SettingsNewUserPage',
    path: '/settings/users/new-user',
    Component: SettingsNewUserPage,
  },
  {
    name: 'SettingsUsersPage',
    path: '/settings/users',
    Component: SettingsUsersPage,
  },
  {
    name: 'SettingsNewQuestionPage',
    path: '/settings/help-center/new-question',
    exact: true,
    Component: SettingsNewQuestionPage,
  },
  {
    name: 'SettingsNewQuestionPage',
    path: '/settings/help-center/edit-question/:id',
    exact: true,
    Component: SettingsEditQuestionPage,
  },
  {
    name: 'SettingsHelpCenterPage',
    path: '/settings/help-center',
    Component: SettingsHelpCenterPage,
  },
  {
    name: 'SettingsNewBehavioursPage',
    path: '/settings/behaviours/new-behaviour',
    exact: true,
    Component: SettingsNewBehaviourPage,
  },
  {
    name: 'SettingsEditBehavioursPage',
    path: '/settings/behaviours/edit-behaviour/:id',
    exact: true,
    Component: SettingsEditBehaviourPage,
  },
  {
    name: 'SettingsBehavioursPage',
    path: '/settings/behaviours',
    Component: SettingsBehavioursPage,
  },
  {
    name: 'SettingsNewOusPage',
    path: '/settings/ous/new-ou',
    exact: true,
    Component: SettingsNewOusPage,
  },
  {
    name: 'SettingsEditOusPage',
    path: '/settings/ous/edit-ou/:id',
    exact: true,
    Component: SettingsEditOusPage,
  },
  {
    name: 'SettingsOusPage',
    path: '/settings/ous',
    Component: SettingsOusPage,
  },
  {
    name: 'SettingsCompaniesPage',
    path: '/settings/companies/edit-company/:id',
    Component: SettingsEditCompanyPage,
  },
  {
    name: 'SettingsCompaniesPage',
    path: '/settings/companies/new-company',
    Component: SettingsNewCompanyPage,
  },
  {
    name: 'SettingsCompaniesPage',
    path: '/settings/companies',
    Component: SettingsCompaniesPage,
  },
];

const MainRouter: FC = () => {
  const token = useSelector<StateType, string>((state) => {
    return state.auth.token;
  });

  return (
    <Router>
      <ScrollToTop>
        <Redirect>
          <Layout>
            <Suspense fallback={<Fallback />}>
              <Switch>
                <Route key="login" exact path={LOGIN_PATH} component={LoginPage} />
                <Route key="login" exact path="/forgot-password" component={ForgotPassword} />
                <Route key="ResetPassword" exact path="/reset-password" component={ResetPassword}/>
                {renderRoutes(routes, token)}
              </Switch>
            </Suspense>
          </Layout>
        </Redirect>
      </ScrollToTop>
    </Router>
  );
};

export default MainRouter;
