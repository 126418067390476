import { FC } from 'react';
import { Loader } from '@components';

const Fallback: FC = () => {
  return (
    <div className="router-lazy-spin">
      <Loader />
    </div>
  );
};

export default Fallback;
