import {
  IOuRoleSelectPushItemPayload,
  IOuRoleSelectPushItemAction,
  IOuRoleSelectPopItemRequestAction,
  OuRoleSelectedOptionsType,
  IOuRoleSelectPopItemAction,
  IClearSelectedOptionListAction,
} from '@type/ouRoleSelect';
import { OuRoleSelectEnum } from './ouRoleSelect.constants';

export const ouRoleSelectPushItemAction = (
  payload: IOuRoleSelectPushItemPayload,
): IOuRoleSelectPushItemAction => {
  return {
    payload,
    type: OuRoleSelectEnum.OU_ROLE_SELECT_PUSH_ITEM,
  };
};

export const ouRoleSelectPopItemRequestAction = (
  payload: string,
): IOuRoleSelectPopItemRequestAction => {
  return {
    payload,
    type: OuRoleSelectEnum.OU_ROLE_SELECT_POP_ITEM_REQUEST,
  };
};

export const ouRoleSelectPopItemAction = (
  payload: OuRoleSelectedOptionsType,
): IOuRoleSelectPopItemAction => {
  return {
    payload,
    type: OuRoleSelectEnum.OU_ROLE_SELECT_POP_ITEM,
  };
};

export const clearSelectedOptionAction = (): IClearSelectedOptionListAction => {
  return {
    type: OuRoleSelectEnum.CLEAR_SELECTED_OPTION_LIST,
  };
};
