import { combineReducers } from 'redux';
import AuthReducer from './auth/auth.reducer';

import OuRoleReducer from './components/ou-role-select/ouRoleSelect.reducer';
import ForgotPasswordReducer from './forgotPassword/forgotPassword/forgotPassword.reducer';
import ResetPasswordReducer from './forgotPassword/resetPassword/resetPassword.reducer';
import NewCoachingNoteReducer from './plans/coachingNotes/newCoachingNote/newCoachingNote.reducer';
import EditCoachingNoteReducer from './plans/coachingNotes/editCoachingNote/editCoachingNote.reducer';
import CoachingNotesReducer from './plans/coachingNotes/coachingNotes.reducer';
import DebounceSelectReducer from './components/debounce-select/debounceSelect.reducer';
import RedirectReducer from './redirect/redirect.reducer';
import BehavioursReducer from './settings/behaviours/behaviours.reducer';
import EditBehaviourReducer from './settings/behaviours/editBehaviour/editBehaviour.reducer';
import NewBehaviourReducer from './settings/behaviours/newBehaviour/newBehaviour.reducer';
import CompaniesReducer from './settings/companies/companies.reducer';
import EditCompanyReducer from './settings/companies/editCompany/editCompany.reducer';
import NewCompanyReducer from './settings/companies/newCompany/newCompany.reducer';
import EditFaqReducer from './settings/faq/editFaq/editFaq.reducer';
import FaqReducer from './settings/faq/faq.reducer';
import NewFaqReducer from './settings/faq/newFaq/newFaq.reducer';
import EditOuReducer from './settings/ous/editOu/editOu.reducer';
import NewOuReducer from './settings/ous/newOu/newOu.reducer';
import EditUserReducer from './settings/users/editUser/editUser.reducer';
import NewUserReducer from './settings/users/newUser/newUser.reducer';
import UsersReducer from './settings/users/users.reducer';
import OusReducer from './settings/ous/ous.reducer';
import IdpsReducer from './plans/idps/idps.reducer';
import EditIdpReducer from './plans/idps/editIdp/editIdp.reducer';
import NewIdpReducer from './plans/idps/newIdp/newIdp.reducer';
import AnalysisReducer from './analysis/analysis.reducer';
import NewAssessmentReducer from './analysis/newAssessment/newAssessment.reducer';
import AnalysisBehavioursReducer from './analysis/behaviours/behaviours.reducer';
import UserDetailReducer from './analysis/userDetail/userDetail.reducer';
import AnalysisPeopleReducer from './analysis/people/people.reducer';
import HelpCenterReducer from './help-center/help-center.reducer';

const rootReducer = combineReducers({
  components: combineReducers({
    debounceSelect: DebounceSelectReducer,
    ouRoleSelect: OuRoleReducer,
  }),
  redirect: RedirectReducer,
  auth: AuthReducer,
  helpCenter: HelpCenterReducer,
  forgotPassword: combineReducers({
    forgotPassword: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
  }),
  analysis: combineReducers({
    analysis: AnalysisReducer,
    newAssessment: NewAssessmentReducer,
    behaviours: AnalysisBehavioursReducer,
    userDetail: UserDetailReducer,
    people: AnalysisPeopleReducer,
  }),
  plans: combineReducers({
    coachingNotes: combineReducers({
      coachingNotesPage: CoachingNotesReducer,
      editCoachingNotePage: EditCoachingNoteReducer,
      newCoachingNotePage: NewCoachingNoteReducer,
    }),
    idps: combineReducers({
      idpPage: IdpsReducer,
      editIdpPage: EditIdpReducer,
      newIdpPage: NewIdpReducer,
    }),
  }),
  settings: combineReducers({
    users: combineReducers({
      usersPage: UsersReducer,
      editUserPage: EditUserReducer,
      newUserPage: NewUserReducer,
    }),
    ous: combineReducers({
      ousPage: OusReducer,
      newOuPage: NewOuReducer,
      editOuPage: EditOuReducer,
    }),
    behaviours: combineReducers({
      behavioursPage: BehavioursReducer,
      newBehaviourPage: NewBehaviourReducer,
      editBehaviourPage: EditBehaviourReducer,
    }),
    faq: combineReducers({
      faqPage: FaqReducer,
      newFaqPage: NewFaqReducer,
      editFaqPage: EditFaqReducer,
    }),
    companies: combineReducers({
      companiesPage: CompaniesReducer,
      newCompanyPage: NewCompanyReducer,
      editCompanyPage: EditCompanyReducer,
    }),
  }),
});

export default rootReducer;
