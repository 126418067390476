import { OptionHashTableType } from '@type/debounceSelect';
import { RoleSelectedOptionsType } from '@type/ouRoleSelect';
import { IOptionType, IUserOusModel } from '@type/users';

export const getUserOusRolesOptions = (ousArray: Array<IUserOusModel>): OptionHashTableType => {
  const options = ousArray.reduce((acc, option) => {
    return {
      ...acc,
      [option.id]: {
        label: option.name,
        value: option.id,
      },
    };
  }, {});

  return options;
};

export const getRoleSelectedOptions = (
  selectedOption: IOptionType,
  roleSelectOption: IOptionType,
): RoleSelectedOptionsType => ({
  ou: selectedOption,
  role: roleSelectOption,
});
