import { INewBehavioursAction, INewBehavioursState } from '@type/behaviours';
import { NewBehaviourTypes } from './newBehaviour.constants';

const initialState: INewBehavioursState = {
  pending: false,
  error: null,
};

const NewBehaviourReducer = (
  state = initialState,
  action: INewBehavioursAction,
): INewBehavioursState => {
  switch (action.type) {
    case NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_REQUEST:
      return { ...state, pending: true, error: null };
    case NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewBehaviourTypes.SAVE_NEW_BEHAVIOUR_FAILURE:
      return { ...state, pending: false, error: action.payload.error };
    default:
      return { ...state };
  }
};

export default NewBehaviourReducer;
