import { IUsersAction, IUsersState } from 'types/users';
import { UsersTypes } from './users.constants';

const initialState: IUsersState = {
  pending: false,
  users: [],
  total: 0,
  error: false,
};

const UsersReducer = (state = initialState, action: IUsersAction): IUsersState => {
  switch (action.type) {
    case UsersTypes.FETCH_USERS_REQUEST:
      return { ...state, pending: true, error: false };
    case UsersTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: false,
        users: action.payload.data,
        total: action.payload.meta.total,
      };
    case UsersTypes.FETCH_USERS_FAILURE:
      return { ...state, pending: false, error: true, users: [] };
    case UsersTypes.SEARCH_USERS:
      return { ...state, pending: true, error: false };
    case UsersTypes.EXPORT_ALL_FAILURE:
      return { ...state, pending: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default UsersReducer;
