import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, SelectProps } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { IDebounceSelectState, OptionHashTableType } from '@type/debounceSelect';
import { StateType } from '@type/general';
import {
  clearDebounceCustomSelectAction,
  debouncePopRequestAction,
  debouncePushRequestAction,
  debounceSelectItemAction,
  debounceSelectSearchAction,
} from '@store/actions';
import SelectedOption from '@components/OuRoleSelect/SelectedOption';
import { IOptionType } from '@type/users';
import { getCompanyIdSelectedOptionState } from '@utils/global/helpers';
import DumbDebounceSelect from './DumbDebounceSelect';
import './DebounceCustomSelect.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IDebounceCustomSelectProps<T = any> extends Omit<SelectProps<T>, 'options' | 'children'> {
  name: string;
  id?: string;
  required?: boolean;
  multiple?: boolean;
  className?: string;
  defaultOptionIndex?: number;
  defaultOptionValue?: number;
  loadOptionsOnClick?: boolean;
  updateOnCompanyChange?: boolean;
  disabled?: boolean;
  additionalFetchVar?: unknown;
  fetchOptions: (search: string) => Promise<OptionHashTableType>;
}

export type SelectOptionType = Record<'key' | 'value' | 'label', string>;

const DebounceCustomSelect: FC<IDebounceCustomSelectProps> = ({
  fetchOptions,
  name,
  id,
  className,
  showSearch,
  loadOptionsOnClick,
  defaultOptionIndex,
  defaultOptionValue,
  updateOnCompanyChange,
  additionalFetchVar,
  multiple = true,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  type SelectStateType = IDebounceSelectState[0] & {
    companySelectedOption: IOptionType;
  };

  const {
    loading,
    options,
    selectedOption,
    selectedOptionList,
    searchQuery,
    companySelectedOption,
  } = useSelector<StateType, SelectStateType>((state) => {
    const select = state.components.debounceSelect[name] || {};
    const companyV = getCompanyIdSelectedOptionState(state);

    return {
      ...select,
      companySelectedOption: companyV,
    };
  });

  useEffect(() => {
    if (!loadOptionsOnClick) {
      loadOptions();
    }
  }, [additionalFetchVar]);

  useEffect(() => {
    if (options && updateOnCompanyChange) {
      dispatch(
        clearDebounceCustomSelectAction({
          name,
          fetchFunction: fetchOptions,
        }),
      );
    }
  }, [companySelectedOption]);

  useEffect(() => {
    if (defaultOptionValue !== undefined && options && !selectedOption) {
      const entries = Object.entries(options);
      const filteredEntry = entries.find(([key]) => parseInt(key, 10) === defaultOptionValue);

      if (filteredEntry) {
        const [_, computedEntry] = filteredEntry;

        dispatch(debounceSelectItemAction({ name, option: computedEntry }));
      }
    }
  }, [options, defaultOptionValue]);

  useEffect(() => {
    if (
      defaultOptionIndex !== undefined &&
      (defaultOptionValue === undefined || isNaN(defaultOptionValue)) &&
      options &&
      !selectedOption
    ) {
      const entries = Object.entries(options);
      const item = entries.slice(defaultOptionIndex)[0];

      if (item) {
        const [__, itemOption] = item;

        dispatch(debounceSelectItemAction({ name, option: itemOption }));
      }
    }
  }, [options, defaultOptionIndex]);

  const onFocus = () => {
    if (loadOptionsOnClick) {
      loadOptions();
    }
  };

  const onSearch = (query: string) => {
    dispatch(
      debounceSelectSearchAction({
        name,
        query,
        fetchFunction: fetchOptions,
      }),
    );
  };

  const loadOptions = () => {
    if (!additionalFetchVar && options && Object.keys(options).length) {
      return;
    }

    dispatch(
      debounceSelectSearchAction({
        name,
        query: '',
        fetchFunction: fetchOptions,
      }),
    );
  };

  const handleSelect = (option: SelectOptionType): void => {
    const { value, label } = option;

    dispatch(debounceSelectItemAction({ name, option: { value, label } }));
  };

  const handleAddButtonClick = (): void => {
    if (!selectedOption) {
      return;
    }

    dispatch(debouncePushRequestAction({ name }));
    dispatch(debounceSelectItemAction({ name, option: null }));

    if (searchQuery) {
      dispatch(debounceSelectSearchAction({ name, query: '', fetchFunction: fetchOptions }));
    }
  };

  const handleDeleteButtonClick = (value: string | unknown): void => {
    if (typeof value !== 'string') {
      return;
    }

    dispatch(debouncePopRequestAction({ name, value }));
  };

  return (
    <Row className="debounce-custom-select">
      {multiple &&
        Object.entries(selectedOptionList || {}).map(([key, option]) => {
          return (
            <SelectedOption
              key={key}
              option={option}
              onClick={handleDeleteButtonClick}
              objectKey={key}
            />
          );
        })}
      <div className="input-container">
        <DumbDebounceSelect
          name={name}
          id={id}
          className={className}
          options={options}
          loading={loading}
          value={selectedOption}
          disabled={disabled}
          onChange={handleSelect}
          showSearch={showSearch}
          onSearch={showSearch ? onSearch : undefined}
          onFocus={onFocus}
        />
        {multiple && (
          <Button type="primary" onClick={handleAddButtonClick}>
            <CheckOutlined />
          </Button>
        )}
      </div>
    </Row>
  );
};

export default DebounceCustomSelect;
