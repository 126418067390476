import React, { FC, useMemo } from 'react';
import './UserImage.scss';

interface IUserImageProps {
  firstName: string;
  lastName: string;
}

const getInitials = (...words: string[]): string => {
  const initials = words
    .map((word) => {
      return word.charAt(0);
    })
    .join('')
    .toUpperCase();

  return initials;
};

const UserImage: FC<IUserImageProps> = ({ firstName, lastName }) => {
  const initials = useMemo(() => {
    return getInitials(firstName, lastName);
  }, [firstName, lastName]);
  return (
    <div className="user-image">
      <div>{initials}</div>
    </div>
  );
};

export default UserImage;
