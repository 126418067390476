import {
  AnalysisBehavioursUsersListType,
  BehaviourSelectedOptionType,
  FilteredLineChartDataType,
  GetLineChartDataRequestPayloadType,
  IGetAnalysisBehavioursUsersErrorAction,
  IGetAnalysisBehavioursUsersRequestAction,
  IGetAnalysisBehavioursUsersRequestPayload,
  IGetAnalysisBehavioursUsersSuccessAction,
  IGetLineChartDataErrorAction,
  IGetLineChartDataRequestAction,
  IGetLineChartDataSuccessAction,
  ISetAspectSelectedOptionAction,
  ISetBehavioursListAction,
  ISetBehavioursSelectedOptionAction,
  ISetFilteredLineChartDataRequestAction,
  ISetFilteredLineChartDataSuccessAction,
  ISetFilteredUsersAction,
  LineChartDataType,
  SetFilteredLineChartDataRequestPayloadType,
} from '@type/analysisBehaviours';
import { OptionListType } from '@utils';
import { AnalysisBehavioursTypes } from './behaviours.constants';

export const getAnalysisBehavioursUsersRequestAction = (
  payload: IGetAnalysisBehavioursUsersRequestPayload,
): IGetAnalysisBehavioursUsersRequestAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_REQUEST,
  };
};

export const getAnalysisBehavioursUsersSuccessAction = (
  payload: AnalysisBehavioursUsersListType,
): IGetAnalysisBehavioursUsersSuccessAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_SUCCESS,
  };
};

export const getAnalysisBehavioursUsersErrorAction = (): IGetAnalysisBehavioursUsersErrorAction => {
  return {
    type: AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_ERROR,
  };
};

export const getLineChartDataRequestAction = (
  payload: GetLineChartDataRequestPayloadType,
): IGetLineChartDataRequestAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.GET_LINE_CHART_DATA_REQUEST,
  };
};

export const getLineChartDataSuccessAction = (
  payload: LineChartDataType,
): IGetLineChartDataSuccessAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.GET_LINE_CHART_DATA_SUCCESS,
  };
};

export const getLineChartDataErrorAction = (): IGetLineChartDataErrorAction => {
  return {
    type: AnalysisBehavioursTypes.GET_LINE_CHART_DATA_ERROR,
  };
};

export const setBehavioursListAction = (payload: OptionListType): ISetBehavioursListAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_BEHAVIOURS_LIST,
  };
};

export const setBehavioursSelectedOptionAction = (
  payload: BehaviourSelectedOptionType,
): ISetBehavioursSelectedOptionAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_BEHAVIOURS_SELECTED_OPTION,
  };
};

export const setAspectSelectedOptionAction = (
  payload: BehaviourSelectedOptionType,
): ISetAspectSelectedOptionAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_ASPECT_SELECTED_OPTION,
  };
};

export const setFilteredUsersAction = (
  payload: AnalysisBehavioursUsersListType,
): ISetFilteredUsersAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_FILTERED_USERS,
  };
};

export const setFilteredLineChartDataRequestAction = (
  payload: SetFilteredLineChartDataRequestPayloadType,
): ISetFilteredLineChartDataRequestAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_FILTERED_LINE_CHART_DATA_REQUEST,
  };
};

export const setFilteredLineChartDataSuccessAction = (
  payload: FilteredLineChartDataType,
): ISetFilteredLineChartDataSuccessAction => {
  return {
    payload,
    type: AnalysisBehavioursTypes.SET_FILTERED_LINE_CHART_DATA_SUCCESS,
  };
};
