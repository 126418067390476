import {
  IdpListModelType,
  FetchIdpsRequestPayloadType,
  ISaveIdpModel,
  IFetchIdpByIdRequestPayload,
  IIdpModel,
  IFetchIdpForUser,
} from '@type/idps';
import apiClient from '@utils/global/apiClient';
import { IPaginatedResponse, IResponse } from '@utils/interfaces/response';

export const getIdpsRequest = (data: FetchIdpsRequestPayloadType) => {
  const { timeframeId, ouId, userId, page } = data;

  return apiClient.get<IPaginatedResponse<IdpListModelType>>(`/api/ou_management/${ouId}/idps`, {
    params: {
      userId,
      page,
      timeframeId,
    },
  });
};

export const getIdpByIdRequest = (data: IFetchIdpByIdRequestPayload) => {
  const { ouId, id } = data;

  return apiClient.get<IResponse<IIdpModel>>(`/api/ou_management/${ouId}/idps/${id}/show`);
};

export const checkIdpForUserRequest = ({ ouId, userId, behaviourId }: IFetchIdpForUser) => {
  return apiClient.get<IResponse<IIdpModel>>(
    `/api/ou_management/${ouId}/idps/behaviour_current?userId=${userId}&behaviourId=${behaviourId}`,
  );
};

export const removeIdpByIdRequest = (id: string) => {
  return apiClient.delete<IResponse<IIdpModel>>(`/api/ou_management/idps/${id}`);
};

export const saveIdpByIdRequest = (data: ISaveIdpModel) => {
  return apiClient.put<IResponse<IIdpModel>>(
    `/api/ou_management/${data.ouId}/idps/${data.id}`,
    data,
  );
};

export const saveNewIdpRequest = (data: ISaveIdpModel) => {
  const { ouId, ...rest } = data;

  return apiClient.post<IResponse<IIdpModel>>(`/api/ou_management/${ouId}/idps`, { ...rest });
};

export const submitNewIdpRequest = ({ ouId, id, ...rest }: ISaveIdpModel) => {
  return apiClient.put<IResponse<IIdpModel>>(`/api/ou_management/${ouId}/idps/${id}/submit`, {
    ...rest,
  });
};
