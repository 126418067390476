import { ISupportForm } from '@type/help-center';

export enum HelpCenterTypes {
  HELP_CENTER_MESSAGE_ACTION = 'HELP_CENTER_MESSAGE_ACTION',
  HELP_CENTER_MESSAGE_SUCCESS = 'HELP_CENTER_MESSAGE_SUCCESS',
  HELP_CENTER_MESSAGE_ERROR = 'HELP_CENTER_MESSAGE_ERROR',
}

export interface IHelpCenterAction {
  type: HelpCenterTypes;
  payload: ISupportForm;
}

export interface IHelpCenterMessageSuccessAction {
  type: HelpCenterTypes.HELP_CENTER_MESSAGE_SUCCESS;
}
export interface IHelpCenterMessageErrorAction {
  type: HelpCenterTypes.HELP_CENTER_MESSAGE_ERROR;
}
