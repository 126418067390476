import {
  CoachingNoteListModelType,
  FetchCoachingNotesRequestPayloadType,
  ICoachingNotePayloadData,
  IFetchCoachingNoteByIdRequestPayload,
  ISaveCoachingNoteModel,
  IUpdateCoachingNotePayload,
} from '@type/coachingNotes';
import apiClient from '@utils/global/apiClient';
import { IPaginatedResponse, IResponse } from '@utils/interfaces/response';

export const getCoachingNotesRequest = (data: FetchCoachingNotesRequestPayloadType) => {
  const { ouId, userId, page } = data;

  return apiClient.get<IPaginatedResponse<CoachingNoteListModelType>>(
    `/api/ou_management/${ouId}/coaching_notes`,
    {
      params: {
        userId,
        page,
      },
    },
  );
};

export const getCoachingNoteByIdRequest = (data: IFetchCoachingNoteByIdRequestPayload) => {
  const { ouId, id } = data;

  return apiClient.get<IResponse<ICoachingNotePayloadData>>(
    `/api/ou_management/${ouId}/coaching_notes/${id}/show`,
  );
};

export const removeCoachingNoteByIdRequest = (data: IFetchCoachingNoteByIdRequestPayload) => {
  const { ouId, id } = data;

  return apiClient.delete<IResponse<ICoachingNotePayloadData>>(
    `/api/ou_management/${ouId}/coaching_notes/${id}`,
  );
};

export const updateCoachingNoteByIdRequest = (payload: IUpdateCoachingNotePayload) => {
  const { id, ouId, userId, note, savedBy } = payload;

  if (!savedBy) {
    throw Error(`savedBy is ${savedBy}`);
  }

  return apiClient.put<IResponse<ICoachingNotePayloadData>>(
    `/api/ou_management/${ouId}/coaching_notes/${id}`,
    {
      userId,
      note,
      savedBy,
    },
  );
};

export const saveNewCoachingNoteRequest = (data: ISaveCoachingNoteModel) => {
  const { ouId, ...rest } = data;

  return apiClient.post<IResponse<ICoachingNotePayloadData>>(
    `/api/ou_management/${ouId}/coaching_notes`,
    { ...rest },
  );
};
