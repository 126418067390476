import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Tooltip } from 'antd';
import { logoutRequestAction } from '@store/auth/auth.actions';
import { LogoutOutlined } from '@ant-design/icons';
import { messages } from '@utils';
import { IAuthState } from '@type/auth';
import { IOptionType } from '@type/users';
import { ICompanyModel } from '@type/companies';
import { DebounceCustomSelect } from '@components';
import { getCompaniesOptions } from '@api/general';
import { LOGIN_PATH } from '@router/MainRouter';
import { StateType } from '../../../types/general';

const { loginMsg, companyMsg } = messages.header;

const { companySelectName } = messages.components.companySelect;

type SelectorType = Omit<IAuthState, 'login'> & {
  selectedCompany: IOptionType;
  companies: Array<ICompanyModel>;
};

const TokenCheck: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { token, firstName, lastName, jobDescription, companyName, selectedCompany, companies } =
    useSelector<StateType, SelectorType>((state) => {
      return {
        token: state.auth.token,
        firstName: state.auth.firstName,
        lastName: state.auth.lastName,
        jobDescription: state.auth.jobDescription,
        companyName: state.auth.companyName,
        selectedCompany: state.components.debounceSelect[companySelectName]?.selectedOption,
        companies: state.settings.companies.companiesPage.companies,
      };
    });

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem('companyId', selectedCompany.value);
    }
  }, [selectedCompany]);

  const getDefaultOptionValue = (): number => {
    return parseInt(localStorage.getItem('companyId') || '10', 10);
  };

  const logout = () => {
    dispatch(logoutRequestAction());
  };

  const isLoginPage = location.pathname === LOGIN_PATH;

  if (!token) {
    if (isLoginPage) {
      return null;
    }

    return (
      <Button>
        <Link className="user" to="/login">
          {loginMsg}
        </Link>
      </Button>
    );
  }

  return (
    <>
      <div className="company-select-block">
        <label htmlFor="company-select">{companyMsg}</label>
        <DebounceCustomSelect
          id="company-select"
          defaultOptionValue={getDefaultOptionValue()}
          fetchOptions={getCompaniesOptions}
          name={companySelectName}
          additionalFetchVar={companies.length}
          multiple={false}
        />
      </div>
      <div className="name-logout-btn-block">
        <Tooltip
          placement="bottom"
          title={
            <>
              <p>{`job description: ${jobDescription}`}</p>
              <p>{`company name: ${companyName}`}</p>
            </>
          }
        >
          <span className="name">{`${firstName} ${lastName}`}</span>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip placement="bottom" title={<p>{messages.header.logoutMsg}</p>}>
          <LogoutOutlined onClick={logout} />
        </Tooltip>
      </div>
    </>
  );
};

export default TokenCheck;
