import {
  AnalysisBehavioursUsersListType,
  GetLineChartDataRequestPayloadType,
  IGetAnalysisBehavioursUsersRequestPayload,
  LineChartDataType,
} from '@type/analysisBehaviours';
import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';

export const getAnalysisBehavioursRequest = (params: IGetAnalysisBehavioursUsersRequestPayload) => {
  return apiClient.get<IResponse<AnalysisBehavioursUsersListType[]>>(
    `/api/graphs/behaviours_scatter`,
    {
      params,
    },
  );
};

export const getBehavioursLineChartDataRequest = (params: GetLineChartDataRequestPayloadType) => {
  return apiClient.get<IResponse<LineChartDataType[]>>(`/api/graphs/behaviours_line_chart`, {
    params,
  });
};
