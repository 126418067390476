import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message as AntdMessage } from 'antd';
import { saveNewUserRequest } from '@api/users';
import { redirectAction } from '@store/redirect/redirect.actions';
import { IUserModel, ISaveNewUser } from '@type/users';
import { messages } from '@utils';
import { getOus } from '@api/general';
import { IResponse } from '@utils/interfaces/response';
import { clearDebounceCustomSelectAction } from '@store/components/debounce-select/debounceSelect.actions';
import { saveNewUserFailureAction, saveNewUserSuccessAction } from './newUser.actions';
import { NewUserTypes } from './newUser.constants';

const { loadingMessage } = messages.alerts.loading;
const { newUserSuccessMessage } = messages.alerts.success;
const { newUserFailedMessage } = messages.alerts.error;
const { name } = messages.settings.users.newUserPage.formInputs.ousInput;

function* saveNewUserRequestSaga(action: ISaveNewUser) {
  AntdMessage.loading({
    content: loadingMessage,
    key: 'save-new-user-message',
    duration: 10,
  });
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IUserModel>> = yield call(saveNewUserRequest, payload);
    yield put(saveNewUserSuccessAction(response?.data?.data?.id));
    yield put(
      clearDebounceCustomSelectAction({ name, fetchFunction: getOus, combinedSelect: true }),
    );
  } catch (e) {
    yield put(saveNewUserFailureAction(e));
  }
}

function* saveNewUserSuccessSaga() {
  AntdMessage.success({
    content: newUserSuccessMessage,
    key: 'save-new-user-message',
    duration: 3,
  });
  yield put(redirectAction('/settings/users'));
}

function* saveNewUserFailureSaga() {
  AntdMessage.error({
    content: newUserFailedMessage,
    key: 'save-new-user-message',
    duration: 3,
  });
  yield;
}

export function* saveNewUserSaga() {
  yield all([
    takeLatest(NewUserTypes.SAVE_NEW_USER_REQUEST, saveNewUserRequestSaga),
    takeLatest(NewUserTypes.SAVE_NEW_USER_SUCCESS, saveNewUserSuccessSaga),
    takeLatest(NewUserTypes.SAVE_NEW_USER_FAILURE, saveNewUserFailureSaga),
  ]);
}
