import {
  ISaveCoachingNoteModel,
  ISaveNewCoachingNoteRequestAction,
  ISaveNewCoachingNoteSuccessAction,
  ISaveNewCoachingNoteFailureAction,
} from '@type/coachingNotes';
import { AxiosError } from 'axios';
import { NewCoachingNoteTypes } from './newCoachingNote.constants';

export const saveNewCoachingNoteAction = (
  payload: ISaveCoachingNoteModel,
): ISaveNewCoachingNoteRequestAction => {
  return {
    payload,
    type: NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_REQUEST,
  };
};

export const saveNewCoachingNoteSuccessAction = (): ISaveNewCoachingNoteSuccessAction => {
  return {
    type: NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_SUCCESS,
  };
};

export const saveNewCoachingNoteFailureAction = (
  payload: AxiosError,
): ISaveNewCoachingNoteFailureAction => {
  return {
    payload,
    type: NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_FAILURE,
  };
};
