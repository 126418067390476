import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { setAspectSelectedOptionAction, setBehavioursSelectedOptionAction } from '@store/actions';
import { messages, OptionListType } from '@utils';
import { BehaviourSelectedOptionType } from '@type/analysisBehaviours';
import './ButtonsBlock.scss';

type ButtonsBlockType = 'aspect' | 'behaviours';

export interface IButtonsBlockProps {
  type: ButtonsBlockType;
  label: string;
  optionList: OptionListType;
  selectedOption: BehaviourSelectedOptionType;
}

const ButtonsBlock: FC<IButtonsBlockProps> = ({ label, optionList, selectedOption, type }) => {
  const dispatch = useDispatch();
  const { behavioursListIsEmptyMsg } = messages.components.buttonsBlock.error;

  const setSelectedOption = (payload: BehaviourSelectedOptionType): void => {
    switch (type) {
      case 'behaviours':
        dispatch(setBehavioursSelectedOptionAction(payload));
        break;
      case 'aspect':
        dispatch(setAspectSelectedOptionAction(payload));
        break;
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const payload = parseInt((e.target as HTMLButtonElement).value, 10);

    setSelectedOption(payload);
  };

  return (
    <div className="buttons-block-container">
      <label className="buttons-block-label" htmlFor="buttons-block">
        {label}
      </label>
      <div className="buttons-block" id="buttons-block">
        {optionList ? (
          optionList.map((option) => {
            return (
              <Button
                key={option.value}
                htmlType="button"
                value={option.value}
                onClick={(e) => {
                  return handleClick(e);
                }}
                className={`${option.value === selectedOption?.toString() ? ' active' : ''}`}
              >
                {option.label}
              </Button>
            );
          })
        ) : (
          <h1>{behavioursListIsEmptyMsg}</h1>
        )}
      </div>
    </div>
  );
};

export default ButtonsBlock;
