import {
  IDebouncePopItemAction,
  IDebouncePopRequestAction,
  IDebouncePushItemAction,
  IDebouncePushRequestAction,
  IDebounceSearchFailureAction,
  IDebounceSearchRequest,
  IDebounceSearchSuccess,
  IDebounceSelectItemAction,
  IPopItemPayload,
  IPopRequestPayload,
  PushItemPayloadType,
  IPushRequestPayload,
  ISearchPayload,
  ISelectPayload,
  IDebounceSetSelectedOptionsAction,
  ISetSelectedOptionsPayload,
  SearchActionPayloadType,
  SearchSuccessActionPayloadType,
  IClearDebounceCustomSelectPayload,
  IClearDebounceCustomSelectAction,
} from '@type/debounceSelect';
import { DebounceSearchTypes } from './debounceSelect.constants';

export const debounceSelectSearchAction = (
  payload: SearchActionPayloadType,
): IDebounceSearchRequest => {
  return {
    payload,
    type: DebounceSearchTypes.DEBOUNCE_SEARCH_REQUEST,
  };
};

export const debounceSelectSearchSuccessAction = (
  payload: SearchSuccessActionPayloadType,
): IDebounceSearchSuccess => {
  return {
    payload,
    type: DebounceSearchTypes.DEBOUNCE_SEARCH_SUCCESS,
  };
};

export const debounceSelectSearchFailureAction = (
  payload: ISearchPayload,
): IDebounceSearchFailureAction => {
  return {
    payload,
    type: DebounceSearchTypes.DEBOUNCE_SEARCH_FAILURE,
  };
};

export const debounceSelectItemAction = (payload: ISelectPayload): IDebounceSelectItemAction => {
  return {
    payload,
    type: DebounceSearchTypes.SELECT_ITEM,
  };
};

export const debouncePushRequestAction = (
  payload: IPushRequestPayload,
): IDebouncePushRequestAction => {
  return {
    payload,
    type: DebounceSearchTypes.PUSH_ITEM_REQUEST,
  };
};
export const debouncePushItemAction = (payload: PushItemPayloadType): IDebouncePushItemAction => {
  return {
    payload,
    type: DebounceSearchTypes.PUSH_ITEM,
  };
};

export const debouncePopRequestAction = (
  payload: IPopRequestPayload,
): IDebouncePopRequestAction => {
  return {
    payload,
    type: DebounceSearchTypes.POP_ITEM_REQUEST,
  };
};

export const debouncePopItemAction = (payload: IPopItemPayload): IDebouncePopItemAction => {
  return {
    payload,
    type: DebounceSearchTypes.POP_ITEM,
  };
};

export const debounceSetSelectedOptionList = (
  payload: ISetSelectedOptionsPayload,
): IDebounceSetSelectedOptionsAction => {
  return {
    payload,
    type: DebounceSearchTypes.SET_SELECTED_OPTIONS,
  };
};

export const clearDebounceCustomSelectAction = (
  payload: IClearDebounceCustomSelectPayload,
): IClearDebounceCustomSelectAction => {
  return {
    payload,
    type: DebounceSearchTypes.CLEAR_DEBOUNCE_CUSTOM_SELECT,
  };
};
