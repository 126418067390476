import { ICoachingNotesState, ICoachingNotesAction } from '@type/coachingNotes';
import { CoachingNotesTypes } from './coachingNotes.constants';

const initialState: ICoachingNotesState = {
  pending: false,
  coachingNotes: [],
  error: null,
  total: '0',
};

const CoachingNotesReducer = (
  state = initialState,
  action: ICoachingNotesAction,
): ICoachingNotesState => {
  switch (action.type) {
    case CoachingNotesTypes.FETCH_COACHING_NOTES_REQUEST:
      return { ...state, pending: true, error: null };
    case CoachingNotesTypes.FETCH_COACHING_NOTES_SUCCESS:
      return {
        ...state,
        pending: false,
        coachingNotes: action?.payload?.data || [],
        total: action?.payload?.total || '0',
        error: null,
      };
    case CoachingNotesTypes.FETCH_COACHING_NOTES_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error || null, coachingNotes: [] };
    default:
      return { ...state };
  }
};

export default CoachingNotesReducer;
