import { AxiosError } from 'axios';
import apiClient from '@utils/global/apiClient';
import { INewUserModel, IEditUserModel, IUserModel, ISubordinateModel } from '@type/users';
import { IPaginatedResponse, IResponse } from '@utils/interfaces/response';

export const exportAllRequest = () => {
  return apiClient.get(`/api/users/export`, {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
};

export const getSearchedUsersRequest = (search: string, page: number = 1) => {
  return apiClient.get<IPaginatedResponse<IUserModel>>(`/api/users`, {
    params: {
      page,
      search,
    },
  });
};

export const getUserByIdRequest = (id: string) => {
  return apiClient.get<IResponse<IUserModel>>(`/api/users/${id}/show`).catch((e) => {
    const err = e as AxiosError;

    throw Error(err.response?.data?.message);
  });
};

export const removeUserByIdRequest = (id: string) => {
  return apiClient.delete<IPaginatedResponse<IUserModel>>(`/api/users/${id}`);
};

export const saveUserByIdRequest = (id: string, data: IEditUserModel) => {
  return apiClient.put<IResponse<IUserModel>>(`/api/users/${id}`, data, {
    params: id,
  });
};

export const getUsersRequest = (page: number) => {
  return apiClient.get<IPaginatedResponse<IUserModel>>(`/api/users`, {
    params: {
      page,
    },
  });
};

export const saveNewUserRequest = (data: INewUserModel) => {
  return apiClient.post('/api/users', data);
};

export const getSubordinatesRequest = (search: string) => {
  return apiClient.get<IResponse<ISubordinateModel[]>>('/api/subordinates', {
    params: {
      search,
    },
  });
};

export const getOuUsersRequest = (query: string) => {
  return apiClient.get<IPaginatedResponse<IUserModel>>(`/api/ou-users`, {
    params: {
      ouId: query,
    },
  });
};
