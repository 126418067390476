import { ICompanyModel, ISaveCompanyModel } from '@type/companies';
import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';

export const getCompaniesRequest = () => {
  return apiClient.get<IResponse<ICompanyModel[]>>(`/api/companies`);
};

export const getCompanyByIdRequest = (id: string) => {
  return apiClient.get<IResponse<ICompanyModel>>(`/api/companies/${id}/show`);
};

export const removeCompanyByIdRequest = (id: string) => {
  return apiClient.delete<IResponse<ICompanyModel>>(`/api/companies/${id}`);
};

export const saveCompanyByIdRequest = (id: string, data: ISaveCompanyModel) => {
  return apiClient.put<IResponse<ICompanyModel>>(`/api/companies/${id}`, data);
};

export const saveNewCompanyRequest = (data: ISaveCompanyModel) => {
  return apiClient.post<IResponse<ICompanyModel>>(`/api/companies/`, data);
};
