import { message } from 'antd';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import { getCompaniesRequest, saveNewCompanyRequest } from '@api/companies';
import { ISaveNewCompanyFailureAction, ISaveNewCompanyRequestAction } from '@type/companies';
import { NewCompanyTypes } from './newCompany.constants';
import { saveNewCompanyFailureAction, saveNewCompanySuccessAction } from './newCompany.actions';

const { saveCompanySuccessMessage } = messages.alerts.success;
const { saveCompanyErrorMessage } = messages.alerts.error;

function* saveNewCompanySaga(action: ISaveNewCompanyRequestAction) {
  const { payload } = action;
  try {
    yield call(saveNewCompanyRequest, payload);
    yield put(saveNewCompanySuccessAction());
    yield call(getCompaniesRequest);
  } catch (e) {
    yield put(saveNewCompanyFailureAction(e));
  }

  yield;
}

function* saveNewCompanySuccessSaga() {
  yield put(redirectAction('/settings/companies'));
  yield message.success({
    content: saveCompanySuccessMessage,
    key: 'save-new-company-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewOuFailureSaga(action: ISaveNewCompanyFailureAction) {
  message.error({
    content: `${saveCompanyErrorMessage}: ${action.payload.message}`,
    key: 'save-new-company-success-message',
    duration: 3,
  });

  yield;
}

export function* newCompanySaga() {
  yield all([
    takeLatest(NewCompanyTypes.SAVE_NEW_COMPANY_REQUEST, saveNewCompanySaga),
    takeLatest(NewCompanyTypes.SAVE_NEW_COMPANY_SUCCESS, saveNewCompanySuccessSaga),
    takeLatest(NewCompanyTypes.SAVE_NEW_COMPANY_FAILURE, saveNewOuFailureSaga),
  ]);
}
