import { ForgotPasswordActionType, IForgotPasswordState } from '@type/forgotPassword';
import { ForgotPasswordTypes } from './forgotPassword.constants';

const initialState: IForgotPasswordState = {
  pending: false,
  message: '',
  error: null,
};

const ForgotPasswordReducer = (
  state = initialState,
  action: ForgotPasswordActionType,
): IForgotPasswordState => {
  switch (action.type) {
    case ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST:
      return { ...state, pending: true, error: null };
    case ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS:
      return { ...state, pending: false, message: action.payload, error: null };
    case ForgotPasswordTypes.FORGOT_PASSWORD_FAILURE:
      return { ...state, pending: false, error: action.payload, message: '' };
    default:
      return { ...state };
  }
};

export default ForgotPasswordReducer;
