import { AxiosError } from 'axios';
import {
  ISaveIdpModel,
  ISaveNewIdpRequestAction,
  ISaveNewIdpSuccessAction,
  ISaveNewIdpFailureAction,
} from '@type/idps';
import { NewIdpTypes } from './newIdp.constants';

export const saveNewIdpAction = (payload: ISaveIdpModel): ISaveNewIdpRequestAction => {
  return {
    payload,
    type: NewIdpTypes.SAVE_NEW_IDP_REQUEST,
  };
};

export const saveNewIdpSuccessAction = (): ISaveNewIdpSuccessAction => {
  return {
    type: NewIdpTypes.SAVE_NEW_IDP_SUCCESS,
  };
};

export const saveNewIdpFailureAction = (payload: AxiosError): ISaveNewIdpFailureAction => {
  return {
    payload,
    type: NewIdpTypes.SAVE_NEW_IDP_FAILURE,
  };
};
