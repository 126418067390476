export enum EditBehaviourTypes {
  FETCH_BEHAVIOUR_BY_ID_REQUEST = 'FETCH_BEHAVIOUR_BY_ID_REQUEST',
  FETCH_BEHAVIOUR_BY_ID_SUCCESS = 'FETCH_BEHAVIOUR_BY_ID_SUCCESS',
  FETCH_BEHAVIOUR_BY_ID_FAILURE = 'FETCH_BEHAVIOUR_BY_ID_FAILURE',

  REMOVE_BEHAVIOUR_BY_ID_REQUEST = 'REMOVE_BEHAVIOUR_BY_ID_REQUEST',
  REMOVE_BEHAVIOUR_BY_ID_SUCCESS = 'REMOVE_BEHAVIOUR_BY_ID_SUCCESS',
  REMOVE_BEHAVIOUR_BY_ID_FAILURE = 'REMOVE_BEHAVIOUR_BY_ID_FAILURE',

  SAVE_BEHAVIOUR_REQUEST = 'SAVE_BEHAVIOUR_REQUEST',
  SAVE_BEHAVIOUR_SUCCESS = 'SAVE_BEHAVIOUR_SUCCESS',
  SAVE_BEHAVIOUR_FAILURE = 'SAVE_BEHAVIOUR_FAILURE',
}
