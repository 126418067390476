import { IOption, messages } from '@utils';
import { OptionHashTableType } from '@type/debounceSelect';
import { StateType } from '@type/general';
import { IOptionType } from '@type/users';
import { USER_PASSWORD_LENGTH } from './configs';

export const getOptionsHashTable = (options: Array<IOption>): OptionHashTableType => {
  return options.reduce((acc, option) => {
    return {
      ...acc,
      [option.value]: option,
    };
  }, {});
};

export const getInputPlaceholder = (fieldName: string): string => {
  return `Please input ${fieldName}`;
};

export const getInputCorrectPlaceholder = (fieldName: string): string => {
  return `Please input correct ${fieldName}`;
};

export const getSearchPlaceholder = (fieldName: string): string => {
  return `Start typing to search for ${fieldName}... `;
};

export const getOptions = <T>(
  array: Array<T>,
  getters: { getName: (obj: T) => string; getValue: (obj: T) => string },
): OptionHashTableType => {
  const { getName, getValue } = getters;

  const options = array.map((item) => {
    return { label: getName(item), value: getValue(item) };
  });

  return getOptionsHashTable(options);
};

export const validatePassword = (password: string) => {
  return password.length >= USER_PASSWORD_LENGTH;
};

export const isError = (error: unknown): error is Error => {
  if (typeof error !== 'object') {
    return false;
  }

  if (error === null) {
    return false;
  }

  return hasAllProperties(error, ['name', 'message']);
};

export const hasOwnProperty = <T extends object>(obj: T, property: string) => {
  return Object.prototype.hasOwnProperty.call(obj, property);
};

export const hasAllProperties = <T extends object>(obj: T, properties: string[]) => {
  return properties.every((property) => {
    return hasOwnProperty(obj, property);
  });
};

export const hexToRgbA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const getCompanyIdSelectedOptionState = (state: StateType): IOptionType => {
  const { companySelectName } = messages?.components?.companySelect;

  return state.components.debounceSelect[companySelectName]?.selectedOption;
};
