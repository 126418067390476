import { IEditBehaviourAction, IEditBehaviourState } from '@type/behaviours';
import { EditBehaviourTypes } from './editBehaviour.constants';

const initialState: IEditBehaviourState = {
  pending: false,
  behaviour: null,
  error: null,
};

const EditBehaviourReducer = (
  state = initialState,
  action: IEditBehaviourAction,
): IEditBehaviourState => {
  switch (action.type) {
    case EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, behaviour: action.behaviour };
    case EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, behaviour: null };

    case EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, behaviour: null };
    case EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditBehaviourTypes.SAVE_BEHAVIOUR_REQUEST:
      return { ...state, pending: true, error: null };
    case EditBehaviourTypes.SAVE_BEHAVIOUR_SUCCESS:
      return { ...state, pending: false, error: null, behaviour: null };
    case EditBehaviourTypes.SAVE_BEHAVIOUR_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditBehaviourReducer;
