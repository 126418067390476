import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IPaginatedResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { isError } from '@utils/global/helpers';
import { getIdpsRequest } from '@api/idps';
import { IIdpModel, IFetchIdpsRequestAction } from '@type/idps';
import { fetchIdpsErrorAction, fetchIdpsSuccessAction } from '@store/actions';
import { IdpsTypes } from './idps.constants';

const { errorMessage } = messages.alerts.error;

function* fetchIdpsSaga({ payload }: IFetchIdpsRequestAction) {
  try {
    const response: AxiosResponse<IPaginatedResponse<IIdpModel>> = yield call(
      getIdpsRequest,
      payload,
    );
    yield put(
      fetchIdpsSuccessAction({ data: response?.data?.data, total: response?.data?.meta?.total }),
    );
  } catch (e) {
    if (isError(e)) {
      yield put(fetchIdpsErrorAction(e));
    }
  }

  yield;
}

function* fetchIdpsFailureSaga() {
  yield antdMessage.error({
    content: errorMessage,
    key: 'settings-idps-message',
    duration: 3,
  });

  yield;
}

export function* idpsSaga() {
  yield all([
    takeLatest(IdpsTypes.FETCH_IDPS_REQUEST, fetchIdpsSaga),
    takeLatest(IdpsTypes.FETCH_IDPS_FAILURE, fetchIdpsFailureSaga),
  ]);
}
