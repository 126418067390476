import { IScoreConfig } from '@utils';
import { TablePaginationConfig } from 'antd';

export const MAX_USERS_LENGTH = 50;
export const USER_PASSWORD_LENGTH = 8;
export const MIN_PAGINATION_ITEMS_COUNT = 4;
export const ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE = -1;
export const COMMITMENT_MAX_VALUE = 2;
export const COMPETENCE_MAX_VALUE = 3;
export const TOTAL_MAX_VALUE = 5;
export const COMMITMENT_CONFIG: IScoreConfig = {
  maxValue: COMMITMENT_MAX_VALUE,
  step: 0.5,
};
export const COMPETENCE_CONFIG: IScoreConfig = {
  maxValue: COMPETENCE_MAX_VALUE,
  step: 0.5,
};

export const defaultPaginationConfig: TablePaginationConfig = {
  defaultPageSize: MAX_USERS_LENGTH,
  showSizeChanger: false,
  pageSize: MAX_USERS_LENGTH,
  defaultCurrent: 1,
};

export const baseLineChartConfig = {
  fill: true,
  pointStyle: 'circle',
  pointRadius: 8,
  pointBorderWidth: 2,
  pointBorderColor: '#a4a1fb',
};
