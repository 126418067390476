import { getAnalysisPeopleRequest } from '@api/analysisPeople';
import {
  AnalysisPeopleOuScoresListType,
  IGetAnalysisPeopleOuAverageScoresRequestAction,
  IGetAnalysisPeopleOuAverageScoresSuccessAction,
} from '@type/analysisPeople';
import { messages } from '@utils';
import { IResponse } from '@utils/interfaces/response';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getAnalysisPeopleOuAverageScoresErrorAction,
  getAnalysisPeopleOuAverageScoresSuccessAction,
} from './people.actions';
import { AnalysisPeopleTypes } from './people.constants';

const { getScatterPlotDataErrorMsg } = messages.alerts.error;

export function* analysisPeopleSaga() {
  function* getAnalysisPeopleRequestSaga({
    payload,
  }: IGetAnalysisPeopleOuAverageScoresRequestAction) {
    try {
      const response: AxiosResponse<IResponse<AnalysisPeopleOuScoresListType>> = yield call(
        getAnalysisPeopleRequest,
        payload,
      );

      yield put(getAnalysisPeopleOuAverageScoresSuccessAction(response.data.data));
    } catch (e) {
      yield put(getAnalysisPeopleOuAverageScoresErrorAction());
    }
    yield;
  }

  function* getAnalysisPeopleSuccessSaga({
    payload,
  }: IGetAnalysisPeopleOuAverageScoresSuccessAction) {
    yield;
  }

  function* getAnalysisPeopleErrorSaga() {
    message.error({
      content: getScatterPlotDataErrorMsg,
      key: 'user-details-error-message',
      duration: 3,
    });

    yield;
  }

  yield all([
    takeLatest(
      AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_REQUEST,
      getAnalysisPeopleRequestSaga,
    ),
    takeLatest(
      AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_SUCCESS,
      getAnalysisPeopleSuccessSaga,
    ),
    takeLatest(
      AnalysisPeopleTypes.GET_ANALYSIS_PEOPLE_OU_AVERAGE_SCORES_ERROR,
      getAnalysisPeopleErrorSaga,
    ),
  ]);
}
