export enum EditUserTypes {
  FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST',
  FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS',
  FETCH_USER_BY_ID_FAILURE = 'FETCH_USER_BY_ID_FAILURE',

  REMOVE_USER_BY_ID_REQUEST = 'REMOVE_USER_BY_ID_REQUEST',
  REMOVE_USER_BY_ID_SUCCESS = 'REMOVE_USER_BY_ID_SUCCESS',
  REMOVE_USER_BY_ID_FAILURE = 'REMOVE_USER_BY_ID_FAILURE',

  SAVE_USER_REQUEST = 'SAVE_USER',
  SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS',
  SAVE_USER_FAILURE = 'SAVE_USER_FAILURE',
}
