import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Layout as AntdLayout, Menu } from 'antd';
import {
  HomeOutlined,
  FundOutlined,
  BarChartOutlined,
  UserOutlined,
  EditOutlined,
  FormOutlined,
  SnippetsOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FileAddOutlined,
  SolutionOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import './Sidebar.scss';

interface ISidebarLinksChild {
  nameId: string;
  icon: JSX.Element;
  path: string;
}

export interface ISidebarLinks {
  nameId: string;
  icon: JSX.Element;
  path?: string;
  children?: ISidebarLinksChild[];
}

const { Sider } = AntdLayout;

export const HomeNameId = 'Home';

export const sidebarLinks: ISidebarLinks[] = [
  {
    nameId: HomeNameId,
    icon: <HomeOutlined />,
    path: '/',
  },
  {
    nameId: 'Analysis',
    icon: <FundOutlined />,
    children: [
      {
        nameId: 'Teams',
        icon: <BarChartOutlined />,
        path: '/analysis/behaviours',
      },
      {
        nameId: 'Behaviours',
        icon: <UserOutlined />,
        path: '/analysis/people',
      },
      {
        nameId: 'Users Overview',
        icon: <SolutionOutlined />,
        path: '/analysis/users-overview',
      },
      {
        nameId: 'New Assesment',
        icon: <EditOutlined />,
        path: '/analysis/new-assesment',
      },
    ],
  },
  {
    nameId: 'Plans',
    icon: <CalendarOutlined />,
    children: [
      {
        nameId: 'Individual Development Plans',
        icon: <SnippetsOutlined />,
        path: '/plans/individual-development-plans',
      },
      {
        nameId: 'New Individual Development Plan',
        icon: <FormOutlined />,
        path: '/plans/new-individual-development-plan',
      },
      {
        nameId: 'Coaching Notes',
        icon: <FileTextOutlined />,
        path: '/plans/coaching-notes',
      },
      {
        nameId: 'New Coaching Note',
        icon: <FileAddOutlined />,
        path: '/plans/new-coaching-note',
      },
    ],
  },
];

export const sidebarBottomLinks: ISidebarLinks[] = [
  {
    nameId: 'Help Center',
    icon: <QuestionCircleOutlined />,
    path: '/help-center',
  },
  {
    nameId: 'Settings',
    icon: <SettingOutlined />,
    path: '/settings',
  },
];

const renderLinks = (): React.ReactNode => {
  return sidebarLinks.map(({ nameId, icon, children, path }) => {
    return children ? (
      <Menu.SubMenu key={nameId} icon={icon} title={nameId}>
        {children.map((child: ISidebarLinksChild) => {
          return (
            <Menu.Item key={child.path}>
              <NavLink to={child.path}>
                {child.icon} <div className="subitem-text">{child.nameId}</div>
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={path} icon={icon}>
        <NavLink to={path}>{nameId}</NavLink>
      </Menu.Item>
    );
  });
};

const renderBottomLinks = (): React.ReactNode => {
  return sidebarBottomLinks.map(({ nameId, icon, path }) => {
    return (
      <Menu.Item key={path} icon={icon}>
        <NavLink to={path}>{nameId}</NavLink>
      </Menu.Item>
    );
  });
};

const Sidebar: FC = () => {
  const location = useLocation();

  const defaultOpenSubmenu = sidebarLinks.find((link) => {
    return link.children?.find((child) => {
      return child.path === location.pathname;
    });
  });

  return (
    <Sider trigger={null} collapsed={false} width="260px">
      <div className="sidebar-logo-content">
        <NavLink to="/">
          <span>OPTMAN COACH</span>
        </NavLink>
      </div>
      <div className="menu-container">
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          defaultOpenKeys={[defaultOpenSubmenu?.nameId || '']}
        >
          {renderLinks()}
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          defaultOpenKeys={[defaultOpenSubmenu?.nameId || '']}
          className="bottom-menu"
        >
          {renderBottomLinks()}
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
