/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionMatchingPattern } from '@redux-saga/types';
import { IObjectLiteral } from '@utils';
import { Action } from 'redux';
import {
  take,
  fork,
  cancel,
  ActionPattern,
  HelperWorkerParameters,
  TakeEffect,
  CancelEffect,
  call,
  delay,
} from 'redux-saga/effects';

const debounceDeep = <
  A extends Action,
  Fn extends (...args: any[]) => any,
  P extends ActionPattern = ActionPattern,
>(
  pattern: P,
  identifier: (action: A) => any,
  fn: Fn,
  ...args: HelperWorkerParameters<ActionMatchingPattern<P>, Fn>
) =>
  fork(function* internalFn(): Generator<TakeEffect | CancelEffect | ReturnType<typeof fork>> {
    function* delayedSaga(action: A) {
      yield delay(500);
      yield call<Fn>(fn, ...([action, ...args] as Parameters<Fn>));
    }

    const tasks: IObjectLiteral = {};

    while (true) {
      const action = yield take(pattern);
      const id = identifier(action as A);

      if (tasks[id]) {
        yield cancel(tasks[id]);
      }

      tasks[id] = yield fork<typeof delayedSaga>(delayedSaga, action as A);
    }
  });

export default debounceDeep;
