import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { isError } from '@utils/global/helpers';
import { messages } from '@utils';
import {
  checkIdpForUserRequest,
  getIdpByIdRequest,
  removeIdpByIdRequest,
  saveIdpByIdRequest,
  submitNewIdpRequest,
} from '@api/idps';
import {
  checkIdpForUserFailure,
  checkIdpForUserSuccess,
  fetchIdpByIdFailure,
  fetchIdpByIdSuccess,
  redirectAction,
  removeIdpByIdFailure,
  removeIdpByIdSuccess,
  saveIdpFailureAction,
  saveIdpSuccessAction,
} from '@store/actions';
import {
  IFetchIdpByIdRequestAction,
  IIdpModel,
  ISaveIdpAction,
  IFetchIdpByIdAction,
  ICheckIdpForUserAction,
} from '@type/idps';
import { EditIdpTypes } from './editIdp.constants';

const { removeErrorMessage, saveErrorMessage } = messages.alerts.error;
const { removeSuccessMessage, editSuccessMessage } = messages.alerts.success;

function* removeIdpByIdSaga(action: IFetchIdpByIdAction) {
  const { payload } = action;

  try {
    yield call(removeIdpByIdRequest, payload);

    yield put(removeIdpByIdSuccess());
  } catch (e) {
    if (isError(e)) {
      yield put(removeIdpByIdFailure(e));
    }
  }

  yield;
}

function* removeIdpByIdSuccessSaga() {
  yield put(redirectAction('/plans/individual-development-plans'));

  yield message.success({
    content: removeSuccessMessage,
    key: 'remove-idp-success-message',
    duration: 3,
  });
}

function* removeIdpByIdFailureSaga() {
  message.error({
    content: removeErrorMessage,
    key: 'remove-idp-error-message',
    duration: 3,
  });

  yield;
}

function* fetchIdpByIdSaga(action: IFetchIdpByIdRequestAction) {
  const { payload } = action;

  try {
    const response: AxiosResponse<IResponse<IIdpModel>> = yield call(getIdpByIdRequest, payload);

    yield put(fetchIdpByIdSuccess(response.data.data));
  } catch (e) {
    yield put(fetchIdpByIdFailure(e as Error));
  }
}

function* checkIdpForUserSaga({ payload }: ICheckIdpForUserAction) {
  try {
    const response: AxiosResponse<IResponse<IIdpModel>> = yield call(
      checkIdpForUserRequest,
      payload,
    );

    yield put(checkIdpForUserSuccess(response.data.data));
  } catch (e) {
    yield put(checkIdpForUserFailure());
  }
}

function* saveIdpSaga(action: ISaveIdpAction) {
  try {
    yield call(saveIdpByIdRequest, action.payload);

    yield put(saveIdpSuccessAction());
  } catch (e) {
    if (isError(e)) {
      yield put(saveIdpFailureAction(e));
    }
  }

  yield;
}

function* saveIdpSuccessSaga() {
  yield put(redirectAction('/plans/individual-development-plans'));
  yield message.success({
    content: editSuccessMessage,
    key: 'submit-idp-success-message',
    duration: 3,
  });

  yield;
}

function* saveIdpFailureSaga() {
  message.error({
    content: saveErrorMessage,
    key: 'save-idp-error-message',
    duration: 3,
  });
  yield;
}

function* submitIdpSaga(action: ISaveIdpAction) {
  try {
    yield call(submitNewIdpRequest, action.payload);

    yield put(saveIdpSuccessAction());
  } catch (e) {
    if (isError(e)) {
      yield put(saveIdpFailureAction(e));
    }
  }

  yield;
}

function* submitIdpSuccessSaga() {
  yield put(redirectAction('/plans/individual-development-plans'));

  yield message.success({
    content: editSuccessMessage,
    key: 'submit-idp-success-message',
    duration: 3,
  });

  yield;
}

function* submitIdpFailureSaga() {
  message.error({
    content: saveErrorMessage,
    key: 'submit-idp-error-message',
    duration: 3,
  });

  yield;
}

export function* editIdpSaga() {
  yield all([
    takeLatest(EditIdpTypes.FETCH_IDP_BY_ID_REQUEST, fetchIdpByIdSaga),
    takeLatest(EditIdpTypes.REMOVE_IDP_BY_ID_REQUEST, removeIdpByIdSaga),
    takeLatest(EditIdpTypes.REMOVE_IDP_BY_ID_SUCCESS, removeIdpByIdSuccessSaga),
    takeLatest(EditIdpTypes.REMOVE_IDP_BY_ID_FAILURE, removeIdpByIdFailureSaga),
    takeLatest(EditIdpTypes.SAVE_IDP_REQUEST, saveIdpSaga),
    takeLatest(EditIdpTypes.SAVE_IDP_SUCCESS, saveIdpSuccessSaga),
    takeLatest(EditIdpTypes.SAVE_IDP_FAILURE, saveIdpFailureSaga),
    takeLatest(EditIdpTypes.SUBMIT_IDP_REQUEST, submitIdpSaga),
    takeLatest(EditIdpTypes.SUBMIT_IDP_SUCCESS, submitIdpSuccessSaga),
    takeLatest(EditIdpTypes.SUBMIT_IDP_FAILURE, submitIdpFailureSaga),
    takeLatest(EditIdpTypes.CHECK_IDP_FOR_USER_REQUEST, checkIdpForUserSaga),
  ]);
}
