import React, { FC } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { messages } from '@utils';
import './EditButton.scss';

interface IProps {
  className?: string;
  path: string;
}

const EditButton: FC<IProps> = ({ className, path }) => {
  return (
    <Button className={className} type="primary">
      <Link to={path}>{messages.buttons.editButton}</Link>
    </Button>
  );
};

export default EditButton;
