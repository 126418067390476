import { message } from 'antd';
import { helpCenterMessageError, helpCenterMessageSuccess } from '@store/actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { sendSupportMessage } from '@api/help-center';
import { messages } from '@utils';
import { HelpCenterTypes, IHelpCenterAction } from './help-center.constants';

function* sendHelpCenterMessageSagas({ payload }: IHelpCenterAction) {
  try {
    yield call(sendSupportMessage, payload);

    yield put(helpCenterMessageSuccess());
  } catch (e) {
    yield put(helpCenterMessageError());
  }

  yield;
}

function* helpCenterMessageSuccessSaga() {
  yield message.success({
    content: messages.alerts.success.sendSuccessMessage,
    key: 'send-support-success-message',
    duration: 3,
  });

  yield;
}

function* helpCenterMessageErrorSaga() {
  yield message.success({
    content: messages.alerts.error.errorMessage,
    key: 'send-support-error-message',
    duration: 3,
  });

  yield;
}

export function* HelpCenterSaga() {
  yield all([takeLatest(HelpCenterTypes.HELP_CENTER_MESSAGE_ACTION, sendHelpCenterMessageSagas)]);
  yield all([
    takeLatest(HelpCenterTypes.HELP_CENTER_MESSAGE_SUCCESS, helpCenterMessageSuccessSaga),
  ]);
  yield all([takeLatest(HelpCenterTypes.HELP_CENTER_MESSAGE_ERROR, helpCenterMessageErrorSaga)]);
}
