export enum EditOuTypes {
  FETCH_OU_BY_ID_REQUEST = 'FETCH_OU_BY_ID_REQUEST',
  FETCH_OU_BY_ID_SUCCESS = 'FETCH_OU_BY_ID_SUCCESS',
  FETCH_OU_BY_ID_FAILURE = 'FETCH_OU_BY_ID_FAILURE',

  REMOVE_OU_BY_ID_REQUEST = 'REMOVE_OU_BY_ID_REQUEST',
  REMOVE_OU_BY_ID_SUCCESS = 'REMOVE_OU_BY_ID_SUCCESS',
  REMOVE_OU_BY_ID_FAILURE = 'REMOVE_OU_BY_ID_FAILURE',

  SAVE_OU_REQUEST = 'SAVE_OU_REQUEST',
  SAVE_OU_SUCCESS = 'SAVE_OU_SUCCESS',
  SAVE_OU_FAILURE = 'SAVE_OU_FAILURE',
}
