import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { IFaqModel } from '@type/faq';
import { getFaqRequest } from '@api/faq';
import { fetchFaqSuccess, fetchFaqError } from './faq.actions';
import { FaqTypes } from './faq.constants';

const { errorMessage } = messages.alerts.error;

function* fetchFaqSaga() {
  try {
    const response: AxiosResponse<IResponse<IFaqModel[]>> = yield call(getFaqRequest);
    yield put(fetchFaqSuccess(response.data.data));
  } catch (e) {
    yield put(fetchFaqError(e));
  }

  yield;
}

function* fetchFaqFailureSaga() {
  yield antdMessage.error({
    content: errorMessage,
    key: 'settings-faq-message',
    duration: 3,
  });

  yield;
}

export function* faqSaga() {
  yield all([
    takeLatest(FaqTypes.FETCH_FAQ_REQUEST, fetchFaqSaga),
    takeLatest(FaqTypes.FETCH_FAQ_FAILURE, fetchFaqFailureSaga),
  ]);
}
