import { message } from 'antd';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { redirectAction } from '@store/redirect/redirect.actions';
import { messages } from '@utils';
import { saveNewFaqRequest } from '@api/faq';
import { ISaveFaqFailureAction, ISaveNewFaqRequestAction } from '@type/faq';
import isErrorType from '@utils/type-guards/error.type-guard';
import { NewFaqTypes } from './newFaq.constants';
import { saveNewFaqFailureAction, saveNewFaqSuccessAction } from './newFaq.actions';

const { saveFaqSuccessMessage } = messages.alerts.success;
const { saveFaqErrorMessage } = messages.alerts.error;

function* saveNewFaqSaga(action: ISaveNewFaqRequestAction) {
  const { payload } = action;
  try {
    yield call(saveNewFaqRequest, payload);
    yield put(saveNewFaqSuccessAction());
  } catch (e) {
    if (isErrorType(e)) {
      yield put(saveNewFaqFailureAction(e));
    }
  }

  yield;
}

function* saveNewFaqSuccessSaga() {
  yield put(redirectAction('/settings/help-center'));
  yield message.success({
    content: saveFaqSuccessMessage,
    key: 'save-new-faq-success-message',
    duration: 3,
  });

  yield;
}

function* saveNewFaqFailureSaga(action: ISaveFaqFailureAction) {
  message.error({
    content: `${saveFaqErrorMessage}: ${action.payload.message}`,
    key: 'save-new-faq-success-message',
    duration: 3,
  });

  yield;
}

export function* newFaqSaga() {
  yield all([
    takeLatest(NewFaqTypes.SAVE_NEW_FAQ_REQUEST, saveNewFaqSaga),
    takeLatest(NewFaqTypes.SAVE_NEW_FAQ_SUCCESS, saveNewFaqSuccessSaga),
    takeLatest(NewFaqTypes.SAVE_NEW_FAQ_FAILURE, saveNewFaqFailureSaga),
  ]);
}
