import { INewOuState, INewOuAction } from '@type/ous';
import { NewOuTypes } from './newOu.constants';

const initialState: INewOuState = {
  pending: false,
  ou: null,
  error: null,
};

const NewOuReducer = (state = initialState, action: INewOuAction): INewOuState => {
  switch (action.type) {
    case NewOuTypes.SAVE_NEW_OU_REQUEST:
      return { ...state, pending: true, error: null };
    case NewOuTypes.SAVE_NEW_OU_SUCCESS:
      return { ...state, pending: false, error: null, ou: null };
    case NewOuTypes.SAVE_NEW_OU_FAILURE:
      return { ...state, pending: false, error: action.payload.error };
    default:
      return { ...state };
  }
};

export default NewOuReducer;
