import {
  ISaveOuModel,
  ISaveNewOuAction,
  ISaveNewOuFailureAction,
  ISaveNewOuSuccessAction,
} from '@type/ous';

import { NewOuTypes } from './newOu.constants';

export const saveNewOuAction = (payload: ISaveOuModel): ISaveNewOuAction => {
  return {
    payload,
    type: NewOuTypes.SAVE_NEW_OU_REQUEST,
  };
};

export const saveNewOuSuccessAction = (): ISaveNewOuSuccessAction => {
  return {
    type: NewOuTypes.SAVE_NEW_OU_SUCCESS,
  };
};

export const saveNewOuFailureAction = (payload: Error): ISaveNewOuFailureAction => {
  return {
    payload,
    type: NewOuTypes.SAVE_NEW_OU_FAILURE,
  };
};
