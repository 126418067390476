import { IFaqState, IFaqAction } from '@type/faq';
import { FaqTypes } from './faq.constants';

const initialState: IFaqState = {
  pending: false,
  faqs: [],
  error: null,
};

const FaqReducer = (state = initialState, action: IFaqAction) => {
  switch (action.type) {
    case FaqTypes.FETCH_FAQ_REQUEST:
      return { ...state, pending: true, error: null };
    case FaqTypes.FETCH_FAQ_SUCCESS:
      return { ...state, pending: false, faqs: action.payload, error: null };
    case FaqTypes.FETCH_FAQ_FAILURE:
      return { ...state, pending: false, error: action.payload.error, faqs: [] };
    default:
      return { ...state };
  }
};

export default FaqReducer;
