import {
  ISaveCompanyModel,
  ISaveNewCompanyRequestAction,
  ISaveNewCompanySuccessAction,
  ISaveNewCompanyFailureAction,
} from '@type/companies';
import { NewCompanyTypes } from './newCompany.constants';

export const saveNewCompanyAction = (payload: ISaveCompanyModel): ISaveNewCompanyRequestAction => {
  return {
    payload,
    type: NewCompanyTypes.SAVE_NEW_COMPANY_REQUEST,
  };
};

export const saveNewCompanySuccessAction = (): ISaveNewCompanySuccessAction => {
  return {
    type: NewCompanyTypes.SAVE_NEW_COMPANY_SUCCESS,
  };
};

export const saveNewCompanyFailureAction = (payload: Error): ISaveNewCompanyFailureAction => {
  return {
    payload,
    type: NewCompanyTypes.SAVE_NEW_COMPANY_FAILURE,
  };
};
