import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StateType } from '@type/general';
import { redirectAction } from '@store/actions';

const Redirect: FC = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectPath = useSelector<StateType, string>((state) => {
    return state.redirect.path;
  });

  useEffect(() => {
    if (redirectPath) {
      history.push(redirectPath);
      dispatch(redirectAction(''));
    }
  }, [redirectPath]);

  return <>{children}</>;
};

export default Redirect;
