import {
  IResetPasswordRequestAction,
  IResetPasswordSuccessAction,
  IResetPasswordFailureAction,
  IResetPasswordRequestData,
} from '@type/forgotPassword';
import { ErrorNullType } from '@type/general';
import { ResetPasswordTypes } from './resetPassword.constants';

export const resetPasswordRequestAction = (
  payload: IResetPasswordRequestData,
): IResetPasswordRequestAction => {
  return {
    payload,
    type: ResetPasswordTypes.RESET_PASSWORD_REQUEST,
  };
};

export const resetPasswordSuccessAction = (payload: string): IResetPasswordSuccessAction => {
  return {
    payload,
    type: ResetPasswordTypes.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordErrorAction = (payload: ErrorNullType): IResetPasswordFailureAction => {
  return {
    payload,
    type: ResetPasswordTypes.RESET_PASSWORD_FAILURE,
  };
};
