import {
  IGetCurrentQuarterAction,
  IGetCurrentQuarterSuccessAction,
  IGetCurrentQuarterFailedAction,
  IGetBehavioursListAction,
  IGetBehavioursListFailedAction,
  IGetBehavioursListSuccessAction,
} from '@type/analysis';
import { IOusBehaviours } from '@type/ous';
import { ITimeFrameModel } from '@type/timeframes';
import { AnalysisTypes } from './analysis.constants';

export const getCurrentQuarterAction = (): IGetCurrentQuarterAction => {
  return {
    type: AnalysisTypes.GET_CURRENT_QUARTER_REQUEST,
  };
};

export const getCurrentQuarterSuccessAction = (
  payload: ITimeFrameModel,
): IGetCurrentQuarterSuccessAction => {
  return {
    payload,
    type: AnalysisTypes.GET_CURRENT_QUARTER_SUCCESS,
  };
};

export const getCurrentQuarterFailedAction = (): IGetCurrentQuarterFailedAction => {
  return {
    type: AnalysisTypes.GET_CURRENT_QUARTER_FAILED,
  };
};

export const getBehavioursListAction = (ouId: number): IGetBehavioursListAction => {
  return {
    type: AnalysisTypes.GET_BEHAVIOURS_LIST_REQUEST,
    payload: ouId,
  };
};

export const getBehavioursListSuccessAction = (
  payload: IOusBehaviours[],
): IGetBehavioursListSuccessAction => {
  return {
    payload,
    type: AnalysisTypes.GET_BEHAVIOURS_LIST_SUCCESS,
  };
};

export const getBehavioursListFailedAction = (): IGetBehavioursListFailedAction => {
  return {
    type: AnalysisTypes.GET_BEHAVIOURS_LIST_FAILED,
  };
};
