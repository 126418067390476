import {
  FetchCoachingNotesRequestPayloadType,
  IFetchCoachingNotesFailureAction,
  IFetchCoachingNotesRequestAction,
  IFetchCoachingNotesSuccessAction,
  IFetchCoachingNotesSuccessPayload,
} from '@type/coachingNotes';
import { ErrorNullType } from '@type/general';
import { CoachingNotesTypes } from './coachingNotes.constants';

export const fetchCoachingNotesRequest = (
  payload: FetchCoachingNotesRequestPayloadType,
): IFetchCoachingNotesRequestAction => {
  return {
    payload,
    type: CoachingNotesTypes.FETCH_COACHING_NOTES_REQUEST,
  };
};

export const fetchCoachingNotesSuccess = (
  payload: IFetchCoachingNotesSuccessPayload,
): IFetchCoachingNotesSuccessAction => {
  return {
    payload,
    type: CoachingNotesTypes.FETCH_COACHING_NOTES_SUCCESS,
  };
};

export const fetchCoachingNotesError = (
  payload: ErrorNullType,
): IFetchCoachingNotesFailureAction => {
  return {
    payload,
    type: CoachingNotesTypes.FETCH_COACHING_NOTES_FAILURE,
  };
};
