import {
  IFetchOuByIdFailureAction,
  IFetchOuByIdRequestAction,
  IFetchOuByIdSuccessAction,
  OusModelType,
  ISaveOuModel,
  IRemoveOuByIdFailureAction,
  IRemoveOuByIdRequestAction,
  IRemoveOuByIdSuccessAction,
  ISaveOuAction,
  ISaveOuFailureAction,
  ISaveOuSuccessAction,
} from '@type/ous';

import { EditOuTypes } from './editOu.constants';

export const removeOuByIdRequest = (id: string): IRemoveOuByIdRequestAction => {
  return {
    type: EditOuTypes.REMOVE_OU_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeOuByIdSuccess = (): IRemoveOuByIdSuccessAction => {
  return {
    type: EditOuTypes.REMOVE_OU_BY_ID_SUCCESS,
  };
};

export const removeOuByIdFailure = (error: Error): IRemoveOuByIdFailureAction => {
  return {
    type: EditOuTypes.REMOVE_OU_BY_ID_FAILURE,
    error,
  };
};

export const fetchOuByIdRequest = (id: string): IFetchOuByIdRequestAction => {
  return {
    type: EditOuTypes.FETCH_OU_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchOuByIdSuccess = (payload: OusModelType): IFetchOuByIdSuccessAction => {
  return {
    type: EditOuTypes.FETCH_OU_BY_ID_SUCCESS,
    ou: payload,
  };
};

export const fetchOuByIdFailure = (error: Error): IFetchOuByIdFailureAction => {
  return {
    type: EditOuTypes.FETCH_OU_BY_ID_FAILURE,
    error,
  };
};

export const saveOuAction = (id: string, data: ISaveOuModel): ISaveOuAction => {
  return {
    type: EditOuTypes.SAVE_OU_REQUEST,
    payload: {
      id,
      data,
    },
  };
};

export const saveOuSuccessAction = (): ISaveOuSuccessAction => {
  return {
    type: EditOuTypes.SAVE_OU_SUCCESS,
  };
};

export const saveOuFailureAction = (error: Error): ISaveOuFailureAction => {
  return {
    type: EditOuTypes.SAVE_OU_FAILURE,
    error,
  };
};
