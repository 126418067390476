const isErrorType = (error: unknown): error is Error => {
  if (typeof error !== 'object') {
    return false;
  }

  if (error === null) {
    return false;
  }

  if ('name' in error && 'message' in error) {
    return true;
  }

  return false;
};

export default isErrorType;
