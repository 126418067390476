import {
  IRemoveCoachingNoteByIdRequestAction,
  IRemoveCoachingNoteByIdSuccessAction,
  IRemoveCoachingNoteByIdFailureAction,
  IFetchCoachingNoteByIdRequestAction,
  CoachingNoteModelType,
  IFetchCoachingNoteByIdSuccessAction,
  IFetchCoachingNoteByIdFailureAction,
  IUpdateCoachingNoteAction,
  IUpdateCoachingNoteSuccessAction,
  IUpdateCoachingNoteFailureAction,
  IFetchCoachingNoteByIdRequestPayload,
  IUpdateCoachingNotePayload,
} from '@type/coachingNotes';
import { EditCoachingNoteTypes } from './editCoachingNote.constants';

export const removeCoachingNoteByIdRequest = (
  payload: IFetchCoachingNoteByIdRequestPayload,
): IRemoveCoachingNoteByIdRequestAction => {
  return {
    payload,
    type: EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_REQUEST,
  };
};

export const removeCoachingNoteByIdSuccess = (): IRemoveCoachingNoteByIdSuccessAction => {
  return {
    type: EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_SUCCESS,
  };
};

export const removeCoachingNoteByIdFailure = (
  error: Error,
): IRemoveCoachingNoteByIdFailureAction => {
  return {
    error,
    type: EditCoachingNoteTypes.REMOVE_COACHING_NOTE_BY_ID_FAILURE,
  };
};

export const fetchCoachingNoteByIdRequest = (
  payload: IFetchCoachingNoteByIdRequestPayload,
): IFetchCoachingNoteByIdRequestAction => {
  return {
    payload,
    type: EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_REQUEST,
  };
};

export const fetchCoachingNoteByIdSuccess = (
  payload: CoachingNoteModelType,
): IFetchCoachingNoteByIdSuccessAction => {
  return {
    type: EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_SUCCESS,
    coachingNote: payload,
  };
};

export const fetchCoachingNoteByIdFailure = (error: Error): IFetchCoachingNoteByIdFailureAction => {
  return {
    error,
    type: EditCoachingNoteTypes.FETCH_COACHING_NOTE_BY_ID_FAILURE,
  };
};

export const updateCoachingNoteAction = (
  payload: IUpdateCoachingNotePayload,
): IUpdateCoachingNoteAction => {
  return {
    payload,
    type: EditCoachingNoteTypes.UPDATE_COACHING_NOTE_REQUEST,
  };
};

export const updateCoachingNoteSuccessAction = (): IUpdateCoachingNoteSuccessAction => {
  return {
    type: EditCoachingNoteTypes.UPDATE_COACHING_NOTE_SUCCESS,
  };
};

export const updateCoachingNoteFailureAction = (error: Error): IUpdateCoachingNoteFailureAction => {
  return {
    error,
    type: EditCoachingNoteTypes.UPDATE_COACHING_NOTE_FAILURE,
  };
};
