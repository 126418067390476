import { ICompaniesAction, ICompanyState } from '@type/companies';
import { CompaniesTypes } from './companies.constants';

const initialState: ICompanyState = {
  pending: false,
  companies: [],
  error: null,
};

const CompaniesReducer = (state = initialState, action: ICompaniesAction): ICompanyState => {
  switch (action.type) {
    case CompaniesTypes.FETCH_COMPANIES_REQUEST:
      return { ...state, pending: true, error: null };
    case CompaniesTypes.FETCH_COMPANIES_SUCCESS:
      return { ...state, pending: false, companies: action.payload.companies, error: null };
    case CompaniesTypes.FETCH_COMPANIES_FAILURE:
      return { ...state, pending: false, error: action.payload.error || null, companies: [] };
    default:
      return { ...state };
  }
};

export default CompaniesReducer;
