import {
  INewAssessmentGetCurrentAction,
  NewAssessmentGetCurrentActionPayloadType,
  INewAssessmentGetCurrentFinishAction,
  INewAssessmentGetCurrentResponse,
  INewAssessmentSetCurrentAction,
  INewAssessmentSetCurrentPayload,
  INewAssessmentSubmitCurrentAction,
} from '@type/newAssessment';
import { NewAssessmentTypes } from './newAssessment.constants';

export const newAssessmentGetCurrentAction = (
  payload: NewAssessmentGetCurrentActionPayloadType,
): INewAssessmentGetCurrentAction => {
  return {
    payload,
    type: NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_REQUEST,
  };
};

export const newAssessmentGetCurrentSaveAction = (
  payload: INewAssessmentGetCurrentResponse['data'],
) => {
  return {
    payload,
    type: NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_SAVE,
  };
};

export const newAssessmentGetCurrentFinishAction = (): INewAssessmentGetCurrentFinishAction => {
  return {
    type: NewAssessmentTypes.NEW_ASSESSMENT_GET_CURRENT_FINISH,
  };
};

export const newAssessmentSetCurrentAction = (
  payload: INewAssessmentSetCurrentPayload,
): INewAssessmentSetCurrentAction => {
  return {
    payload,
    type: NewAssessmentTypes.NEW_ASSESSMENT_SET_CURRENT,
  };
};

export const newAssessmentSubmitCurrentAction = (
  payload: INewAssessmentSetCurrentPayload,
): INewAssessmentSubmitCurrentAction => {
  return {
    payload,
    type: NewAssessmentTypes.NEW_ASSESSMENT_SUBMIT_CURRENT,
  };
};
