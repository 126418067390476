import {
  IRemoveBehaviourByIdRequestAction,
  IRemoveBehaviourByIdSuccessAction,
  IRemoveBehaviourByIdFailureAction,
  IFetchBehaviourByIdRequestAction,
  IFetchBehaviourByIdSuccessAction,
  IFetchBehaviourByIdFailureAction,
  ISaveBehaviourModel,
  ISaveBehaviourAction,
  ISaveBehaviourSuccessAction,
  ISaveBehaviourFailureAction,
  BehaviourModelType,
} from '@type/behaviours';
import { EditBehaviourTypes } from './editBehaviour.constants';

export const removeBehaviourByIdRequest = (id: string): IRemoveBehaviourByIdRequestAction => {
  return {
    type: EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeBehaviourByIdSuccess = (): IRemoveBehaviourByIdSuccessAction => {
  return {
    type: EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_SUCCESS,
  };
};

export const removeBehaviourByIdFailure = (error: Error): IRemoveBehaviourByIdFailureAction => {
  return {
    type: EditBehaviourTypes.REMOVE_BEHAVIOUR_BY_ID_FAILURE,
    error,
  };
};

export const fetchBehaviourByIdRequest = (id: string): IFetchBehaviourByIdRequestAction => {
  return {
    type: EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchBehaviourByIdSuccess = (
  payload: BehaviourModelType,
): IFetchBehaviourByIdSuccessAction => {
  return {
    type: EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_SUCCESS,
    behaviour: payload,
  };
};

export const fetchBehaviourByIdFailure = (error: Error): IFetchBehaviourByIdFailureAction => {
  return {
    type: EditBehaviourTypes.FETCH_BEHAVIOUR_BY_ID_FAILURE,
    error,
  };
};

export const saveBehaviourAction = (
  id: string,
  data: ISaveBehaviourModel,
): ISaveBehaviourAction => {
  return {
    type: EditBehaviourTypes.SAVE_BEHAVIOUR_REQUEST,
    payload: {
      id,
      data,
    },
  };
};

export const saveBehaviourSuccessAction = (): ISaveBehaviourSuccessAction => {
  return {
    type: EditBehaviourTypes.SAVE_BEHAVIOUR_SUCCESS,
  };
};

export const saveBehaviourFailureAction = (error: Error): ISaveBehaviourFailureAction => {
  return {
    type: EditBehaviourTypes.SAVE_BEHAVIOUR_FAILURE,
    error,
  };
};
