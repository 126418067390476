export enum EditIdpTypes {
  FETCH_IDP_BY_ID_REQUEST = 'FETCH_IDP_BY_ID_REQUEST',
  FETCH_IDP_BY_ID_SUCCESS = 'FETCH_IDP_BY_ID_SUCCESS',
  FETCH_IDP_BY_ID_FAILURE = 'FETCH_IDP_BY_ID_FAILURE',

  REMOVE_IDP_BY_ID_REQUEST = 'REMOVE_IDP_BY_ID_REQUEST',
  REMOVE_IDP_BY_ID_SUCCESS = 'REMOVE_IDP_BY_ID_SUCCESS',
  REMOVE_IDP_BY_ID_FAILURE = 'REMOVE_IDP_BY_ID_FAILURE',

  SAVE_IDP_REQUEST = 'SAVE_IDP_REQUEST',
  SAVE_IDP_SUCCESS = 'SAVE_IDP_SUCCESS',
  SAVE_IDP_FAILURE = 'SAVE_IDP_FAILURE',

  SUBMIT_IDP_REQUEST = 'SUBMIT_IDP_REQUEST',
  SUBMIT_IDP_SUCCESS = 'SUBMIT_IDP_SUCCESS',
  SUBMIT_IDP_FAILURE = 'SUBMIT_IDP_FAILURE',

  CHECK_IDP_FOR_USER_REQUEST = 'CHECK_IDP_FOR_USER_REQUEST',
  CHECK_IDP_FOR_USER_SUCCESS = 'CHECK_IDP_FOR_USER_SUCCESS',
  CHECK_IDP_FOR_USER_FAILURE = 'CHECK_IDP_FOR_USER_FAILURE',
}
