import {
  IFetchFaqRequestAction,
  IFaqModel,
  IFetchFaqSuccessAction,
  IFetchFaqFailureAction,
} from '@type/faq';
import { FaqTypes } from './faq.constants';

export const fetchFaqRequest = (): IFetchFaqRequestAction => {
  return {
    type: FaqTypes.FETCH_FAQ_REQUEST,
  };
};

export const fetchFaqSuccess = (payload: Array<IFaqModel>): IFetchFaqSuccessAction => {
  return {
    type: FaqTypes.FETCH_FAQ_SUCCESS,
    payload,
  };
};

export const fetchFaqError = (payload: Error): IFetchFaqFailureAction => {
  return {
    type: FaqTypes.FETCH_FAQ_FAILURE,
  };
};
