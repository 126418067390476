import { INewCompanyState, INewCompanyAction } from '@type/companies';
import { NewCompanyTypes } from './newCompany.constants';

const initialState: INewCompanyState = {
  pending: false,
  error: null,
};

const NewCompanyReducer = (state = initialState, action: INewCompanyAction): INewCompanyState => {
  switch (action.type) {
    case NewCompanyTypes.SAVE_NEW_COMPANY_REQUEST:
      return { ...state, pending: true, error: null };
    case NewCompanyTypes.SAVE_NEW_COMPANY_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewCompanyTypes.SAVE_NEW_COMPANY_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error };
    default:
      return { ...state };
  }
};

export default NewCompanyReducer;
