import {
  AnalysisUserDetailMiniGraphsType,
  AnalysisUserDetailScatterPlotDataType,
  IGetLatestIdpFailedAction,
  IGetLatestIdpRequestAction,
  IGetLatestIdpSuccessAction,
  IGetMiniGraphsFailedAction,
  IGetMiniGraphsRequestAction,
  IGetMiniGraphsSuccessAction,
  IGetScatterPlotDataFailedAction,
  IGetScatterPlotDataRequestAction,
  IGetScatterPlotDataRequestPayload,
  IGetScatterPlotDataSuccessAction,
  IUserIdPayload,
} from '@type/analysisUserDetail';
import { IIdpModel } from '@type/idps';
import { UserDetailsActionTypes } from './userDetail.constants';

export const getLatestIdpRequestAction = (payload: IUserIdPayload): IGetLatestIdpRequestAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_LATEST_IDP_REQUEST,
  };
};

export const getLatestIdpSuccessAction = (payload: IIdpModel): IGetLatestIdpSuccessAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_LATEST_IDP_SUCCESS,
  };
};

export const getLatestIdpFailedAction = (): IGetLatestIdpFailedAction => {
  return {
    type: UserDetailsActionTypes.GET_LATEST_IDP_ERROR,
  };
};

export const getScatterDataRequestAction = (
  payload: IGetScatterPlotDataRequestPayload,
): IGetScatterPlotDataRequestAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_REQUEST,
  };
};

export const getScatterDataSuccessAction = (
  payload: AnalysisUserDetailScatterPlotDataType,
): IGetScatterPlotDataSuccessAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_SUCCESS,
  };
};

export const getScatterDataFailedAction = (): IGetScatterPlotDataFailedAction => {
  return {
    type: UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_ERROR,
  };
};

export const getMiniGraphsRequestAction = (
  payload: IUserIdPayload,
): IGetMiniGraphsRequestAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_REQUEST,
  };
};

export const getMiniGraphsSuccessAction = (
  payload: AnalysisUserDetailMiniGraphsType,
): IGetMiniGraphsSuccessAction => {
  return {
    payload,
    type: UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_SUCCESS,
  };
};

export const getMiniGraphsFailedAction = (): IGetMiniGraphsFailedAction => {
  return {
    type: UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_ERROR,
  };
};
