import { INewCoachingNoteState, INewCoachingNoteAction } from '@type/coachingNotes';
import { NewCoachingNoteTypes } from './newCoachingNote.constants';

const initialState: INewCoachingNoteState = {
  pending: false,
  error: null,
};

const NewCoachingNoteReducer = (
  state = initialState,
  action: INewCoachingNoteAction,
): INewCoachingNoteState => {
  switch (action.type) {
    case NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_REQUEST:
      return { ...state, pending: true, error: null };
    case NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewCoachingNoteTypes.SAVE_NEW_COACHING_NOTE_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error };
    default:
      return { ...state };
  }
};

export default NewCoachingNoteReducer;
