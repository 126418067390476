import React, { FC } from 'react';
import { messages } from '@utils';
import { Select, SelectProps, Spin } from 'antd';
import { OptionHashTableType } from '@type/debounceSelect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IDebounceSelectProps<T = any> extends Omit<SelectProps<T>, 'options' | 'children'> {
  name: string;
  loading: boolean;
  options: OptionHashTableType;
  className?: string;
  id?: string;
  onSearch?: (query: string) => void;
}

const { Option } = Select;

const DebounceSelect: FC<IDebounceSelectProps> = ({
  name,
  className,
  id,
  loading,
  onSearch,
  options,
  onChange,
  showSearch = false,
  ...props
}) => {
  const { emptyOptionListMessage } = messages?.components?.customDebounceSelect;
  const optionsIsEmpty = !!Object.keys(options || {}).length;
  const showEmptyOptionListMessage = !optionsIsEmpty ? emptyOptionListMessage : null;

  return (
    <div className={`dumb-debounce-select ${className}`} id={id}>
      <Select
        labelInValue
        filterOption={false}
        showSearch={showSearch}
        onSearch={onSearch}
        onChange={onChange}
        notFoundContent={loading ? <Spin size="small" /> : showEmptyOptionListMessage}
        {...props}
      >
        {Object.entries(options || {}).map(([key, option]) => {
          return (
            <Option key={key} value={key}>
              {option.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default DebounceSelect;
