import apiClient from '@utils/global/apiClient';
import { IResponse } from '@utils/interfaces/response';
import { IBehavioursModel, ISaveBehaviourModel } from '@type/behaviours';

export const getBehavioursRequest = () => {
  return apiClient.get<IResponse<IBehavioursModel[]>>(`/api/behaviours`);
};

export const getBehavioursByIdRequest = (id: string) => {
  return apiClient.get<IResponse<IBehavioursModel>>(`/api/behaviours/${id}/show`);
};

export const removeBehaviourByIdRequest = (id: string) => {
  return apiClient.delete<IResponse<IBehavioursModel>>(`/api/behaviours/${id}`);
};

export const saveBehaviourByIdRequest = (id: string, data: ISaveBehaviourModel) => {
  return apiClient.put<IResponse<IBehavioursModel>>(`/api/behaviours/${id}`, data);
};

export const saveNewBehaviourRequest = (data: ISaveBehaviourModel) => {
  return apiClient.post<IResponse<IBehavioursModel>>(`/api/behaviours/`, data);
};
