export enum DebounceSearchTypes {
  DEBOUNCE_SEARCH_REQUEST = 'DEBOUNCE_SEARCH_REQUEST',
  DEBOUNCE_SEARCH_SUCCESS = 'DEBOUNCE_SEARCH_SUCCESS',
  DEBOUNCE_SEARCH_FAILURE = 'DEBOUNCE_SEARCH_FAILURE',

  SELECT_ITEM = 'SELECT_ITEM',
  PUSH_ITEM_REQUEST = 'PUSH_ITEM_REQUEST',
  PUSH_ITEM = 'PUSH_ITEM',
  POP_ITEM_REQUEST = 'POP_ITEM_REQUEST',
  POP_ITEM = 'POP_ITEM',
  SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS',

  CLEAR_DEBOUNCE_CUSTOM_SELECT = 'CLEAR_DEBOUNCE_CUSTOM_SELECT',
}
