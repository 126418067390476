export enum NewAssessmentTypes {
  NEW_ASSESSMENT_GET_CURRENT_REQUEST = 'NEW_ASSESSMENT_GET_CURRENT_REQUEST',
  NEW_ASSESSMENT_GET_CURRENT_SAVE = 'NEW_ASSESSMENT_GET_CURRENT_SAVE',
  NEW_ASSESSMENT_GET_CURRENT_FINISH = 'NEW_ASSESSMENT_GET_CURRENT_FINISH',

  NEW_ASSESSMENT_SET_CURRENT = 'NEW_ASSESSMENT_SET_CURRENT',
  NEW_ASSESSMENT_SUBMIT_CURRENT = 'NEW_ASSESSMENT_SUBMIT_CURRENT',
}

export enum NewAssessmentMessages {
  loadingMessageKey = 'new-assessment-loading-message',
}
