import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { messages } from '@utils';
import { redirectAction } from '@store/redirect/redirect.actions';
import { ILoginRequestPayload, ILoginSuccessAction, ILoginSuccessPayloadType } from 'types/auth';
import { loginRequest } from '@api/auth';
import { loginErrorAction, loginSuccessAction } from './auth.actions';
import { AuthTypes } from './auth.constants';

const { loadingMessage } = messages.alerts.loading;
const { loginUserSuccessMessage, logoutUserSuccessMessage } = messages.alerts.success;
const { errorMessage } = messages.alerts.error;

interface IAction {
  type: string;
  payload: ILoginRequestPayload;
}

function* loginSaga(action: IAction) {
  message.loading({ content: loadingMessage, key: 'login-loading-message', duration: 3 });
  const { payload } = action;

  try {
    const response: AxiosResponse<ILoginSuccessPayloadType> = yield call(loginRequest, payload);

    yield put(loginSuccessAction(response?.data));
  } catch (e) {
    yield put(loginErrorAction());
  }
}

export function* loginSuccessSaga(action: ILoginSuccessAction) {
  const { token, firstName, lastName, jobDescription, companyName, id } = action.payload;

  yield localStorage.setItem('token', token);
  yield localStorage.setItem('firstName', firstName);
  yield localStorage.setItem('lastName', lastName);
  yield localStorage.setItem('jobDescription', jobDescription || '');
  yield localStorage.setItem('companyName', companyName || '');
  yield localStorage.setItem('id', `${id || ''}`);

  message.success({ content: loginUserSuccessMessage, key: 'login-loading-message', duration: 3 });

  yield put(redirectAction('/'));
}

function* loginErrorSaga() {
  message.error({
    content: errorMessage,
    key: 'login-loading-message',
    duration: 3,
  });

  yield;
}

function* logoutSaga() {
  message.success({ content: logoutUserSuccessMessage, key: 'login-loading-message', duration: 3 });

  yield localStorage.clear();
}

export function* authSaga() {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, loginSaga),
    takeLatest(AuthTypes.LOGIN_SUCCESS, loginSuccessSaga),
    takeLatest(AuthTypes.LOGIN_ERROR, loginErrorSaga),
    takeLatest(AuthTypes.LOGOUT, logoutSaga),
  ]);
}
