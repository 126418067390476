import axios from 'axios';

// FIXME: Temporary decision
export const baseURL = 'https://tnac.webstaging.be';

export const companyId = () => localStorage.getItem('companyId');

const apiClient = axios.create({
  baseURL,
});

apiClient.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  if (request.method === 'post' || request.method === 'put') {
    request.data = {
      ...request.data,
      companyId: companyId(),
    };
  }

  if (request.method === 'get' || request.method === 'delete') {
    request.params = {
      ...request.params,
      companyId: companyId(),
    };
  }

  return request;
});

export default apiClient;
