import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';

import { messages } from '@utils';
import {
  IGetLatestIdpRequestAction,
  IGetScatterPlotDataRequestAction,
  IGetMiniGraphsRequestAction,
  AnalysisUserDetailScatterPlotDataType,
  AnalysisUserDetailMiniGraphsType,
} from '@type/analysisUserDetail';

import {
  getLatestIdpRequest,
  getMiniGraphDataRequest,
  getScatterPlotDataRequest,
} from '@api/analysisUserDetail';
import { IResponse } from '@utils/interfaces/response';
import { IIdpModel } from '@type/idps';
import { getMiniGraphsSuccessAction } from '@store/actions';
import {
  getLatestIdpFailedAction,
  getLatestIdpSuccessAction,
  getMiniGraphsFailedAction,
  getScatterDataFailedAction,
  getScatterDataSuccessAction,
} from './userDetail.actions';
import { UserDetailsActionTypes } from './userDetail.constants';

const { getLatestIdpErrorMsg, getScatterPlotDataErrorMsg, getMiniGraphsDataErrorMsg } =
  messages.alerts.error;

function* getLatestIdpRequestSaga(action: IGetLatestIdpRequestAction) {
  const { payload } = action;
  try {
    const response: AxiosResponse<IResponse<IIdpModel>> = yield call(getLatestIdpRequest, payload);
    const { data } = response.data;

    yield put(getLatestIdpSuccessAction(data));
  } catch (e) {
    yield put(getLatestIdpFailedAction());
  }
}

function* getScatterPlotDataRequestSaga(action: IGetScatterPlotDataRequestAction) {
  const { payload } = action;
  try {
    const response: AxiosResponse<IResponse<AnalysisUserDetailScatterPlotDataType>> = yield call(
      getScatterPlotDataRequest,
      payload,
    );
    const { data } = response.data;

    yield put(getScatterDataSuccessAction(data));
  } catch (e) {
    yield put(getScatterDataFailedAction());
  }
}

function* getMiniGraphsDataRequestSaga(action: IGetMiniGraphsRequestAction) {
  const { payload } = action;
  try {
    const response: AxiosResponse<IResponse<AnalysisUserDetailMiniGraphsType>> = yield call(
      getMiniGraphDataRequest,
      payload,
    );
    const { data } = response.data;

    yield put(getMiniGraphsSuccessAction(data));
  } catch (e) {
    yield put(getMiniGraphsFailedAction());
  }
}

function* getLatestIdpErrorSaga() {
  message.error({
    content: getLatestIdpErrorMsg,
    key: 'user-details-error-message',
    duration: 3,
  });

  yield;
}

function* getScatterPlotDataErrorSaga() {
  message.error({
    content: getScatterPlotDataErrorMsg,
    key: 'user-details-error-message',
    duration: 3,
  });

  yield;
}

function* getMiniGraphsDataErrorSaga() {
  message.error({
    content: getMiniGraphsDataErrorMsg,
    key: 'user-details-error-message',
    duration: 3,
  });

  yield;
}

export function* analysisUserDetailSaga() {
  yield all([
    takeLatest(UserDetailsActionTypes.GET_LATEST_IDP_REQUEST, getLatestIdpRequestSaga),
    takeLatest(UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_REQUEST, getScatterPlotDataRequestSaga),
    takeLatest(UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_REQUEST, getMiniGraphsDataRequestSaga),
    takeLatest(UserDetailsActionTypes.GET_LATEST_IDP_ERROR, getLatestIdpErrorSaga),
    takeLatest(UserDetailsActionTypes.GET_SCATTER_PLOT_DATA_ERROR, getScatterPlotDataErrorSaga),
    takeLatest(UserDetailsActionTypes.GET_MINI_GRAPHS_DATA_ERROR, getMiniGraphsDataErrorSaga),
  ]);
}
