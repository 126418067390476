import { UploadOutlined, BarChartOutlined } from '@ant-design/icons';
import { UsersColumnType } from '@pages/Settings/Users/UsersPage/helpers';
import { EditButton, UserImage } from '@components';
import { IUserModel } from 'types/users';
import { IOusBehaviours, OusModelType } from 'types/ous';
import { ICompanyModel } from 'types/companies';
import { ISaveIdpModel } from '@type/idps';
import { IQuestionsColumn } from '@pages/Settings/HelpCenter/HelpCenterPage/helpers';
import { IBehavioursColumn } from '@pages/Analysis/Teams/helpers';

export const mockIdp: ISaveIdpModel = {
  ouId: 31,
  userId: 284,
  behaviourId: 20,
  timeframeId: 2,
  diagnosis: 'Test',
  smartGoal: 'Test',
  coachingActions: 'Test',
};

export const rolesOptions = {
  leader: {
    label: 'leader',
    value: '2',
  },
  subordinate: {
    label: 'subordinate',
    value: '3',
  },
};

const mockOuRoles: Array<{ ouId: string; roleId: string }> = [
  {
    ouId: '21',
    roleId: '3',
  },
];

const mockPassword = 'password';

export const mockLeaderOptions = [
  {
    value: 1,
    label: 'Leader1',
  },
  {
    value: 2,
    label: 'Leader2',
  },
  {
    value: 3,
    label: 'Leader3',
  },
];

const mockBehavioursData = {
  firstName: 'John',
  lastName: 'Doe',
  cmScore: 1,
  cpScore: 2,
  total: 3,
  userColor: '#333',
};

const mockUserData: IUserModel[] = [
  {
    id: 1,
    firstName: 'Anthony',
    lastName: 'Mayert',
    jobDescription: 'player',
    email: 'hahn.keara@example.com',
    ous: [
      {
        id: 4,
        name: 'McDermott-Crist',
        slug: 'mcdermott-crist',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 1,
          ou_id: 4,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 2,
    firstName: 'Efrain',
    lastName: 'Veum',
    jobDescription: 'player',
    email: 'reta59@example.net',
    ous: [
      {
        id: 3,
        name: 'Lehner, Brown and Waelchi',
        slug: 'lehner-brown-and-waelchi',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 2,
          ou_id: 3,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 3,
    firstName: 'Dariana',
    lastName: 'Dooley',
    jobDescription: 'player',
    email: 'vonrueden.mariana@example.org',
    ous: [
      {
        id: 4,
        name: 'McDermott-Crist',
        slug: 'mcdermott-crist',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 3,
          ou_id: 4,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 4,
    firstName: 'Jorge',
    lastName: 'Lueilwitz',
    jobDescription: 'player',
    email: 'rahul95@example.com',
    ous: [
      {
        id: 3,
        name: 'Lehner, Brown and Waelchi',
        slug: 'lehner-brown-and-waelchi',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 4,
          ou_id: 3,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 5,
    firstName: 'Otilia',
    lastName: 'Gutkowski',
    jobDescription: 'player',
    email: 'enrique.hills@example.net',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 5,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 9,
        name: 'OU3',
        slug: 'ou3',
        created_at: '2021-07-12T14:29:45.000000Z',
        updated_at: '2021-07-12T14:29:45.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 5,
          ou_id: 9,
          role_id: 2,
        },
      },
    ],
  },
  {
    id: 6,
    firstName: 'Rollin',
    lastName: 'Wiegand',
    jobDescription: 'player',
    email: 'oren67@example.net',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 6,
          ou_id: 1,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 7,
    firstName: 'Vincenza',
    lastName: 'Vandervort',
    jobDescription: 'player',
    email: 'julian.schaefer@example.net',
    ous: [
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 7,
          ou_id: 2,
          role_id: 3,
        },
      },
      {
        id: 9,
        name: 'OU3',
        slug: 'ou3',
        created_at: '2021-07-12T14:29:45.000000Z',
        updated_at: '2021-07-12T14:29:45.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 7,
          ou_id: 9,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 8,
    firstName: 'Larissa',
    lastName: 'Torp',
    jobDescription: 'player',
    email: 'ethan.jast@example.com',
    ous: [
      {
        id: 4,
        name: 'McDermott-Crist',
        slug: 'mcdermott-crist',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 8,
          ou_id: 4,
          role_id: 2,
        },
      },
      {
        id: 9,
        name: 'OU3',
        slug: 'ou3',
        created_at: '2021-07-12T14:29:45.000000Z',
        updated_at: '2021-07-12T14:29:45.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 8,
          ou_id: 9,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 9,
    firstName: 'Linnie',
    lastName: 'Ruecker',
    jobDescription: 'admin',
    email: 'amosciski@example.com',
    ous: [
      {
        id: 3,
        name: 'Lehner, Brown and Waelchi',
        slug: 'lehner-brown-and-waelchi',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 9,
          ou_id: 3,
          role_id: 2,
        },
      },
    ],
  },
  {
    id: 10,
    firstName: 'Paula',
    lastName: 'Crona',
    jobDescription: 'player',
    email: 'eunice09@example.org',
    ous: [
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 10,
          ou_id: 2,
          role_id: 2,
        },
      },
    ],
  },
  {
    id: 14,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'jojobaaii@lala.com',
    ous: [],
  },
  {
    id: 15,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'jojobaai@lala.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 15,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 15,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 16,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'jojobaai@lalo.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 16,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 16,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 17,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'juuj@lalo.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 17,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 17,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 18,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'juuj@aaaa.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 18,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 18,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 19,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'juuj@loo.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 19,
          ou_id: 1,
          role_id: 3,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 19,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
  {
    id: 20,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'juuj@loolo.com',
    ous: [],
  },
  {
    id: 21,
    firstName: 'Lolo',
    lastName: 'Lala',
    jobDescription: 'manager',
    email: 'juuj@laalo.com',
    ous: [
      {
        id: 1,
        name: 'Windler, Rogahn and Ondricka',
        slug: 'windler-rogahn-and-ondricka',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 21,
          ou_id: 1,
          role_id: 2,
        },
      },
      {
        id: 2,
        name: 'Keeling, Reinger and VonRueden',
        slug: 'keeling-reinger-and-vonrueden',
        created_at: '2021-06-02T12:39:03.000000Z',
        updated_at: '2021-06-02T12:39:03.000000Z',
        roleName: 'Subordinate',
        pivot: {
          user_id: 21,
          ou_id: 2,
          role_id: 3,
        },
      },
    ],
  },
];

const mockOusData: OusModelType = {
  id: 1,
  name: 'test team',
  behaviours: [
    {
      id: 2,
      name: 'sed',
      slug: 'sed',
      created_at: '2021-06-02T12:39:03.000000Z',
      updated_at: '2021-06-02T12:39:03.000000Z',
      pivot: {
        ou_id: 1,
        behaviour_id: 2,
      },
    },
  ],
  subordinates: [
    {
      id: 5,
      email: 'enrique.hills@example.net',
      first_name: 'Otilia',
      last_name: 'Gutkowski',
      email_verified_at: '2021-06-02T12:39:01.000000Z',
      created_at: ' 2021-06-02T12:39:02.000000Z',
      updated_at: '2021-06-02T12:39:02.000000Z',
      job_description: 'player',
      pivot: {
        ou_id: 1,
        user_id: 5,
      },
    },
  ],
  leader: {
    id: 21,
    email: 'juuj@laalo.com',
    first_name: 'Florian',
    last_name: 'Smeyers',
    email_verified_at: 'null',
    created_at: '2021-07-26T09:04:10.000000Z',
    updated_at: '2021-07-26T09:04:10.000000Z',
    job_description: 'manager',
    pivot: {
      ou_id: 1,
      user_id: 21,
    },
  },
};

const mockQuestions: IQuestionsColumn = {
  key: 1,
  question: 'Test Question',
  answer: 'Test Answer',
};

const mockCompanies: ICompanyModel = {
  id: 1,
  name: 'Northern Italy',
};

export const getMockOuRoles = () => {
  return mockOuRoles;
};

export const getMockPassword = () => {
  return mockPassword;
};

export const getMockCompaniesData = (count: number): ICompanyModel[] => {
  const arr: ICompanyModel[] = Array(count)
    .fill(null)
    .map((_, i) => {
      return {
        ...mockCompanies,
        name: `${mockCompanies.name} ${i + 1}`,
      };
    });

  return arr;
};

export const getMockQuestionsData = (count: number): IQuestionsColumn[] => {
  const arr: IQuestionsColumn[] = Array(count)
    .fill(null)
    .map((_, i) => {
      return {
        ...mockQuestions,
        question: `${mockQuestions.question} ${i + 1}`,
        answer: `${mockQuestions.answer}${i + 1}, ${mockQuestions.answer}${i + 2}`,
      };
    });

  return arr;
};

export const getMockOusData = (count: number): OusModelType[] => {
  const arr: OusModelType[] = Array(count)
    .fill(null)
    .map((_, i) => {
      return { ...mockOusData, name: `${mockOusData.name} ${i + 1}` };
    });

  return arr;
};

const getTemporaryBehavioursData = (): IBehavioursColumn[] => {
  const { firstName, lastName, cmScore, cpScore, total, userColor } = mockBehavioursData;
  const tempData: IBehavioursColumn[] = Array(10)
    .fill(null)
    .map((_, index) => {
      return {
        firstName,
        lastName,
        cmScore,
        cpScore,
        total,
        userColor,
        key: index,
        picture: <UserImage firstName={firstName} lastName={lastName} />,
        assess: <BarChartOutlined />,
        view: <UploadOutlined />,
      };
    });

  return tempData;
};

const getTemporaryUsersData = () => {
  return mockUserData.map((el) => {
    const { firstName, lastName } = el;
    const ousStr: string = el.ous
      .map((ou) => {
        return ou.name;
      })
      .join();

    return {
      ...el,
      ousStr,
      picture: <UserImage firstName={firstName} lastName={lastName} />,
      edit: (
        <EditButton className="edit-button" path="/settings/users/edit-user">
          Edit
        </EditButton>
      ),
    };
  });
};

export const mockData: IBehavioursColumn[] = getTemporaryBehavioursData();
export const mockUsers: UsersColumnType[] = getTemporaryUsersData();

export const mockSelectOptions2 = [
  { value: 'test', label: 'Test' },
  { value: 'test2', label: 'Test2' },
];
export const mockSelectOptions: string[] = ['Sales Team #1', 'Sales Team #2', 'Sales Team #3'];
export const mockSelectPlaceholder = 'Sales Team #1';
export const mockBehavioursPageTitle = 'Analysis - Teams - Overall';
export const mockBhvrsBtns = ['ALL', 'BHVR#1', 'BHVR#2', 'BHVR#3', 'BHVR#4', 'BHVR#5'];

export const coachingNotePlanCard = {
  title: 'Coaching Note',
  subtitle: 'Created at 26/02/21',
  body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat facilis esse rem reiciendis ipsum perspiciatis quibusdam quas explicabo. Et praesentium mollitia maxime beatae cum neque culpa voluptatum. Aliquam, dicta iusto!',
  total: 6,
};

export const mockOuBehaviours: IOusBehaviours[] = [
  {
    id: 21,
    name: 'doloremque',
    slug: 'doloremque',
    company_id: 10,
    created_at: '2021-09-08T12:56:18.000000Z',
    updated_at: '2021-09-08T12:56:18.000000Z',
    pivot: {
      ou_id: 29,
      behaviour_id: 21,
    },
  },
  {
    id: 22,
    name: 'quia',
    slug: 'quia',
    company_id: 10,
    created_at: '2021-09-08T12:56:18.000000Z',
    updated_at: '2021-09-08T12:56:18.000000Z',
    pivot: {
      ou_id: 29,
      behaviour_id: 22,
    },
  },
];
