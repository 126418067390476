import { IOusModel } from '@type/ous';
import apiClient from '@utils/global/apiClient';
import { getOptions } from '@utils/global/helpers';
import { IResponse } from '@utils/interfaces/response';

export const getSubOusRequest = (query: string) => {
  return apiClient.get<IResponse<IOusModel[]>>(`/api/sub-ous`, {
    params: {
      search: query,
    },
  });
};

export const onSubOusSearch = async (query: string) => {
  const response = await getSubOusRequest(query);

  return getOptions<IOusModel>(response.data.data, {
    getName: (model) => model.name,
    getValue: (model) => model.id.toString(),
  });
};
