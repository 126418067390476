import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { ICompanyModel } from '@type/companies';
import { getCompaniesRequest } from '@api/companies';
import { fetchCompaniesSuccess, fetchCompaniesError } from './companies.actions';
import { CompaniesTypes } from './companies.constants';

const { errorMessage } = messages.alerts.error;

function* fetchCompaniesSaga() {
  try {
    const response: AxiosResponse<IResponse<ICompanyModel[]>> = yield call(getCompaniesRequest);

    yield put(fetchCompaniesSuccess(response.data.data));
  } catch (e) {
    yield put(fetchCompaniesError(e));
  }

  yield;
}

function* fetchCompaniesFailureSaga() {
  yield antdMessage.error({
    content: errorMessage,
    key: 'settings-companies-message',
    duration: 3,
  });

  yield;
}

export function* companiesSaga() {
  yield all([
    takeLatest(CompaniesTypes.FETCH_COMPANIES_REQUEST, fetchCompaniesSaga),
    takeLatest(CompaniesTypes.FETCH_COMPANIES_FAILURE, fetchCompaniesFailureSaga),
  ]);
}
