import { AxiosResponse } from 'axios';
import { message as antdMessage } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '@utils/interfaces/response';
import { messages } from '@utils';
import { getBehavioursRequest } from '@api/behaviours';
import { IBehavioursModel } from '@type/behaviours';
import { fetchBehavioursSuccess, fetchBehavioursError } from './behaviours.actions';
import { BehavioursTypes } from './behaviours.constants';

const { errorMessage } = messages.alerts.error;

function* fetchBehavioursSaga() {
  try {
    const response: AxiosResponse<IResponse<IBehavioursModel[]>> = yield call(getBehavioursRequest);
    yield put(fetchBehavioursSuccess(response.data.data));
  } catch (e) {
    yield put(fetchBehavioursError());
  }
}

function* fetchBehavioursFailureSaga() {
  antdMessage.error({
    content: errorMessage,
    key: 'settings-behaviours-message',
    duration: 3,
  });

  yield;
}

export function* behavioursSaga() {
  yield all([
    takeLatest(BehavioursTypes.FETCH_BEHAVIOURS_REQUEST, fetchBehavioursSaga),
    takeLatest(BehavioursTypes.FETCH_BEHAVIOURS_FAILURE, fetchBehavioursFailureSaga),
  ]);
}
