import {
  IFetchBehavioursFailureAction,
  IFetchBehavioursRequestAction,
  IFetchBehavioursSuccessAction,
} from '@type/behaviours';
import { BehavioursModelListType } from '@type/general';
import { BehavioursTypes } from './behaviours.constants';

export const fetchBehavioursRequest = (): IFetchBehavioursRequestAction => {
  return {
    type: BehavioursTypes.FETCH_BEHAVIOURS_REQUEST,
  };
};

export const fetchBehavioursSuccess = (
  payload: BehavioursModelListType,
): IFetchBehavioursSuccessAction => {
  return {
    type: BehavioursTypes.FETCH_BEHAVIOURS_SUCCESS,
    payload,
  };
};

export const fetchBehavioursError = (): IFetchBehavioursFailureAction => {
  return {
    type: BehavioursTypes.FETCH_BEHAVIOURS_FAILURE,
  };
};
