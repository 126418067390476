import { IEditOuAction, IEditOuState } from '@type/ous';
import { EditOuTypes } from './editOu.constants';

const initialState: IEditOuState = {
  pending: false,
  ou: null,
  error: null,
};

const EditOuReducer = (state = initialState, action: IEditOuAction): IEditOuState => {
  switch (action.type) {
    case EditOuTypes.FETCH_OU_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditOuTypes.FETCH_OU_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, ou: action.ou };
    case EditOuTypes.FETCH_OU_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, ou: null };

    case EditOuTypes.REMOVE_OU_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditOuTypes.REMOVE_OU_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, ou: null };
    case EditOuTypes.REMOVE_OU_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditOuTypes.SAVE_OU_REQUEST:
      return { ...state, pending: true, error: null };
    case EditOuTypes.SAVE_OU_SUCCESS:
      return { ...state, pending: false, error: null, ou: null };
    case EditOuTypes.SAVE_OU_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditOuReducer;
