import {
  IRemoveIdpByIdRequestAction,
  IRemoveIdpByIdSuccessAction,
  IRemoveIdpByIdFailureAction,
  IFetchIdpByIdRequestAction,
  IFetchIdpByIdFailureAction,
  ISaveIdpModel,
  ISaveIdpAction,
  ISaveIdpSuccessAction,
  ISaveIdpFailureAction,
  IFetchIdpByIdRequestPayload,
  ISubmitIdpAction,
  ISubmitIdpFailureAction,
  ISubmitIdpSuccessAction,
  IIdpModel,
  IFetchIdpForUser,
  ICheckIdpForUserAction,
  ICheckIdpForUserSuccessAction,
  ICheckIdpForUserFailureAction,
  IFetchIdpByIdSuccessAction,
} from '@type/idps';
import { EditIdpTypes } from './editIdp.constants';

export const removeIdpByIdRequest = (id: string): IRemoveIdpByIdRequestAction => {
  return {
    type: EditIdpTypes.REMOVE_IDP_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeIdpByIdSuccess = (): IRemoveIdpByIdSuccessAction => {
  return {
    type: EditIdpTypes.REMOVE_IDP_BY_ID_SUCCESS,
  };
};

export const removeIdpByIdFailure = (error: Error): IRemoveIdpByIdFailureAction => {
  return {
    error,
    type: EditIdpTypes.REMOVE_IDP_BY_ID_FAILURE,
  };
};

export const fetchIdpByIdRequest = (
  payload: IFetchIdpByIdRequestPayload,
): IFetchIdpByIdRequestAction => {
  return {
    payload,
    type: EditIdpTypes.FETCH_IDP_BY_ID_REQUEST,
  };
};

export const checkIdpForUserRequest = (payload: IFetchIdpForUser): ICheckIdpForUserAction => {
  return {
    payload,
    type: EditIdpTypes.CHECK_IDP_FOR_USER_REQUEST,
  };
};

export const checkIdpForUserSuccess = (idp: IIdpModel): ICheckIdpForUserSuccessAction => {
  return {
    idp,
    type: EditIdpTypes.CHECK_IDP_FOR_USER_SUCCESS,
  };
};

export const checkIdpForUserFailure = (): ICheckIdpForUserFailureAction => {
  return {
    type: EditIdpTypes.CHECK_IDP_FOR_USER_FAILURE,
  };
};

export const fetchIdpByIdSuccess = (payload: IIdpModel): IFetchIdpByIdSuccessAction => {
  return {
    type: EditIdpTypes.FETCH_IDP_BY_ID_SUCCESS,
    idp: payload,
  };
};

export const fetchIdpByIdFailure = (error: Error): IFetchIdpByIdFailureAction => {
  return {
    error,
    type: EditIdpTypes.FETCH_IDP_BY_ID_FAILURE,
  };
};

export const saveIdpAction = (payload: ISaveIdpModel): ISaveIdpAction => {
  return {
    payload,
    type: EditIdpTypes.SAVE_IDP_REQUEST,
  };
};

export const saveIdpSuccessAction = (): ISaveIdpSuccessAction => {
  return {
    type: EditIdpTypes.SAVE_IDP_SUCCESS,
  };
};

export const saveIdpFailureAction = (error: Error): ISaveIdpFailureAction => {
  return {
    error,
    type: EditIdpTypes.SAVE_IDP_FAILURE,
  };
};

export const submitIdpAction = (payload: ISaveIdpModel): ISubmitIdpAction => {
  return {
    payload,
    type: EditIdpTypes.SUBMIT_IDP_REQUEST,
  };
};

export const submitIdpSuccessAction = (): ISubmitIdpSuccessAction => {
  return {
    type: EditIdpTypes.SUBMIT_IDP_SUCCESS,
  };
};

export const submitIdpFailureAction = (error: Error): ISubmitIdpFailureAction => {
  return {
    error,
    type: EditIdpTypes.SUBMIT_IDP_FAILURE,
  };
};
