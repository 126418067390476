import { AnalysisBehavioursActionType, IAnalysisBehavioursState } from '@type/analysisBehaviours';
import { IOption } from '@utils';
import { ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE } from '@utils/global/configs';
import { AnalysisBehavioursTypes } from './behaviours.constants';

const allBehavioursOption: IOption = {
  value: ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE.toString(),
  label: 'All',
};

const initialState: IAnalysisBehavioursState = {
  loading: true,
  error: false,
  users: [],
  filteredUsers: [],
  lineChartData: [],
  filteredLineChartData: {},
  behavioursList: [allBehavioursOption],
  behaviourSelectedOption: ANALYSIS_BEHAVIOURS_ALL_BEHAVIOUR_VALUE,
  aspectList: [
    { label: 'Overall', value: '0' },
    { label: 'Commitment', value: '1' },
    { label: 'Competence', value: '2' },
  ],
  aspectSelectedOption: 0,
};

const AnalysisBehavioursReducer = (
  state = initialState,
  action: AnalysisBehavioursActionType,
): IAnalysisBehavioursState => {
  switch (action.type) {
    case AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_REQUEST:
      return { ...state, loading: true, error: false };
    case AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload };
    case AnalysisBehavioursTypes.GET_ANALYSIS_BEHAVIOURS_USERS_ERROR:
      return { ...state, loading: false, error: true };

    case AnalysisBehavioursTypes.GET_LINE_CHART_DATA_REQUEST:
      return { ...state, loading: true, error: false };
    case AnalysisBehavioursTypes.GET_LINE_CHART_DATA_SUCCESS:
      return { ...state, loading: false, lineChartData: action.payload };
    case AnalysisBehavioursTypes.GET_LINE_CHART_DATA_ERROR:
      return { ...state, loading: false, error: true };

    case AnalysisBehavioursTypes.SET_BEHAVIOURS_LIST:
      return { ...state, behavioursList: [allBehavioursOption, ...action.payload] };
    case AnalysisBehavioursTypes.SET_BEHAVIOURS_SELECTED_OPTION:
      return { ...state, behaviourSelectedOption: action.payload };
    case AnalysisBehavioursTypes.SET_ASPECT_SELECTED_OPTION:
      return { ...state, aspectSelectedOption: action.payload };
    case AnalysisBehavioursTypes.SET_FILTERED_USERS:
      return { ...state, filteredUsers: action.payload };
    case AnalysisBehavioursTypes.SET_FILTERED_LINE_CHART_DATA_SUCCESS:
      return { ...state, filteredLineChartData: action.payload };
    default:
      return { ...state };
  }
};

export default AnalysisBehavioursReducer;
