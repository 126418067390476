import { IEditUsersState, IEditUsersAction } from 'types/users';
import { EditUserTypes } from './editUser.constants';

const initialState: IEditUsersState = {
  pending: false,
  user: null,
  error: null,
};

const EditUserReducer = (state = initialState, action: IEditUsersAction): IEditUsersState => {
  switch (action.type) {
    case EditUserTypes.FETCH_USER_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditUserTypes.FETCH_USER_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, user: action.user };
    case EditUserTypes.FETCH_USER_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error, user: null };

    case EditUserTypes.REMOVE_USER_BY_ID_REQUEST:
      return { ...state, pending: true, error: null };
    case EditUserTypes.REMOVE_USER_BY_ID_SUCCESS:
      return { ...state, pending: false, error: null, user: null };
    case EditUserTypes.REMOVE_USER_BY_ID_FAILURE:
      return { ...state, pending: false, error: action.error };

    case EditUserTypes.SAVE_USER_REQUEST:
      return { ...state, pending: true, error: null };
    case EditUserTypes.SAVE_USER_SUCCESS:
      return { ...state, pending: false, error: null, user: null };
    case EditUserTypes.SAVE_USER_FAILURE:
      return { ...state, pending: false, error: action.error };

    default:
      return { ...state };
  }
};

export default EditUserReducer;
