import { RedirectActionType, RedirectActionTypes } from './redirect.actions';

const initialState = {
  path: '',
};

const RedirectReducer = (state = initialState, action: RedirectActionType) => {
  switch (action.type) {
    case RedirectActionTypes.REDIRECT_ACTION:
      return { ...state, path: action.path };
    default:
      return { ...state };
  }
};

export default RedirectReducer;
