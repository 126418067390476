import {
  IRemoveCompanyByIdRequestAction,
  IRemoveCompanyByIdSuccessAction,
  IRemoveCompanyByIdFailureAction,
  IFetchCompanyByIdRequestAction,
  CompanyModelType,
  IFetchCompanyByIdSuccessAction,
  IFetchCompanyByIdFailureAction,
  ISaveCompanyModel,
  ISaveCompanyAction,
  ISaveCompanySuccessAction,
  ISaveCompanyFailureAction,
} from '@type/companies';
import { EditCompanyTypes } from './editCompany.constants';

export const removeCompanyByIdRequest = (id: string): IRemoveCompanyByIdRequestAction => {
  return {
    type: EditCompanyTypes.REMOVE_COMPANY_BY_ID_REQUEST,
    payload: id,
  };
};

export const removeCompanyByIdSuccess = (): IRemoveCompanyByIdSuccessAction => {
  return {
    type: EditCompanyTypes.REMOVE_COMPANY_BY_ID_SUCCESS,
  };
};

export const removeCompanyByIdFailure = (error: Error): IRemoveCompanyByIdFailureAction => {
  return {
    error,
    type: EditCompanyTypes.REMOVE_COMPANY_BY_ID_FAILURE,
  };
};

export const fetchCompanyByIdRequest = (id: string): IFetchCompanyByIdRequestAction => {
  return {
    type: EditCompanyTypes.FETCH_COMPANY_BY_ID_REQUEST,
    payload: id,
  };
};

export const fetchCompanyByIdSuccess = (
  payload: CompanyModelType,
): IFetchCompanyByIdSuccessAction => {
  return {
    type: EditCompanyTypes.FETCH_COMPANY_BY_ID_SUCCESS,
    company: payload,
  };
};

export const fetchCompanyByIdFailure = (error: Error): IFetchCompanyByIdFailureAction => {
  return {
    error,
    type: EditCompanyTypes.FETCH_COMPANY_BY_ID_FAILURE,
  };
};

export const saveCompanyAction = (id: string, data: ISaveCompanyModel): ISaveCompanyAction => {
  return {
    type: EditCompanyTypes.SAVE_COMPANY_REQUEST,
    payload: {
      id,
      data,
    },
  };
};

export const saveCompanySuccessAction = (): ISaveCompanySuccessAction => {
  return {
    type: EditCompanyTypes.SAVE_COMPANY_SUCCESS,
  };
};

export const saveCompanyFailureAction = (error: Error): ISaveCompanyFailureAction => {
  return {
    error,
    type: EditCompanyTypes.SAVE_COMPANY_FAILURE,
  };
};
