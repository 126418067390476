import { ISaveNewUsersState, NewUserActionType } from 'types/users';
import { NewUserTypes } from './newUser.constants';

const initialState: ISaveNewUsersState = {
  pending: false,
  error: null,
};

const NewUserReducer = (state = initialState, action: NewUserActionType): ISaveNewUsersState => {
  switch (action.type) {
    case NewUserTypes.SAVE_NEW_USER_REQUEST:
      return { ...state, pending: true, error: null };
    case NewUserTypes.SAVE_NEW_USER_SUCCESS:
      return { ...state, pending: false, error: null };
    case NewUserTypes.SAVE_NEW_USER_FAILURE:
      return { ...state, pending: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default NewUserReducer;
