import { IIdpsState, IIdpsAction } from '@type/idps';
import { IdpsTypes } from './idps.constants';

const initialState: IIdpsState = {
  pending: false,
  idps: [],
  error: null,
  total: 0,
};

const IdpsReducer = (state = initialState, action: IIdpsAction): IIdpsState => {
  switch (action.type) {
    case IdpsTypes.FETCH_IDPS_REQUEST:
      return { ...state, pending: true, error: null };
    case IdpsTypes.FETCH_IDPS_SUCCESS:
      return {
        ...state,
        pending: false,
        idps: action?.payload?.data || [],
        total: action?.payload?.total || 0,
        error: null,
      };
    case IdpsTypes.FETCH_IDPS_FAILURE:
      return { ...state, pending: false, error: action?.payload?.error || null, idps: [] };
    default:
      return { ...state };
  }
};

export default IdpsReducer;
