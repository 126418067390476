import { IFetchSubmitForm, ISupportForm } from '@type/help-center';
import {
  HelpCenterTypes,
  IHelpCenterMessageErrorAction,
  IHelpCenterMessageSuccessAction,
} from './help-center.constants';

export const helpCenterMessageAction = (body: ISupportForm): IFetchSubmitForm => {
  return {
    type: HelpCenterTypes.HELP_CENTER_MESSAGE_ACTION,
    payload: body,
  };
};

export const helpCenterMessageSuccess = (): IHelpCenterMessageSuccessAction => {
  return {
    type: HelpCenterTypes.HELP_CENTER_MESSAGE_SUCCESS,
  };
};

export const helpCenterMessageError = (): IHelpCenterMessageErrorAction => {
  return {
    type: HelpCenterTypes.HELP_CENTER_MESSAGE_ERROR,
  };
};
